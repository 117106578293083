import React, { Component } from 'react'
import NavbarHome from '../Components/NavbarHome'
import {Form,Button,Card, Alert,Modal,Spinner} from 'react-bootstrap';
import { RiCalendarCheckFill, RiAccountCircleLine, RiChat3Line, RiCloseCircleLine, RiCheckboxCircleLine, RiCheckboxLine, RiWechatLine, RiEyeLine } from "react-icons/ri";
import Footer from '../Components/Footer';
import { withRouter, Link } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
//images
import trip_bg from '../Assets/Images/trip-bg.jpg';
import moment from 'moment';
import { api_url } from "../Variables";
class MyTrips extends Component {
  constructor(props){
    super(props);
    this.state = {
      place:'',
      placeError:'',
      startDate: '',
      startDateError: '',
      endDate: '',
      endDateError: '',
      peopleNum: '',
      peopleNumError: '',
      price: '',
      priceError: '',
      booked: false,
      bookedMsg:'',
      error:false,
      errorMsg:'',
      currentDate: '',
      todayDate: '',
      userBookings:[],
      // serviceID: this.props.location.state.service_id ? this.props.location.state.service_id : '',
      providerID: this.props.location.state.provider_id ? this.props.location.state.provider_id : '',
      servicePrice: this.props.location.state.price ? this.props.location.state.price : '',
      user:'',
      token:null,
      noToken:false,
      userType:'',
      booking_id:'',
      modalComplete:false,
      modalCancelBooking:false,
      modalContactAdmin:false,
      modalContact:false,
      modalApproveBooking:false,
      cancelSucces:false,
        cancelSuccesMsg:'',
        cancelError:false,
        cancelErrorMsg:'',
        completed: '',
        toUSer:'',
        msgText:'',
        approvedBooking:'',
        cancelBooking:'',
        isloading:false,
        seeker_provider: '',
        isloader:false
        // fbloggedIn:false,
        // googleloggedIn:false,
    }
  };
  componentDidMount = async()=>{
    window.addEventListener("storage",(e) => {
      console.log('storage changed')
      const role = JSON.parse(localStorage.getItem("user-info"));
      this.setState({seeker_provider: role.profile.role_id},()=>{
        if(this.state.seeker_provider == '2'){
          this.getProviderBookings();
          console.log('providers boking will get')
        }else{
          this.getUSerBookings();
          console.log('seekers boking will get')
        }
      })
      // const role = (localStorage.getItem("seekerORprovider"));
      // this.setState({seeker_provider: role},()=>{
      //   if(this.state.seeker_provider == 'provider'){
      //     this.getProviderBookings();
      //   }else{
      //     this.getUSerBookings();
      //   }
      // })
     });
    const token = JSON.parse(localStorage.getItem("token"));
    // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
    // const googleLogIn = (JSON.parse(localStorage.getItem('googleLogIn')));
    console.log('token', token)
    if(token){
      this.setState({token: token.token, noToken: false});
    }
    // else if(fbLogIn){
    //   this.setState({fbloggedIn:true})
    // }else if(googleLogIn){
    //   this.setState({googleloggedIn:true})
    // }
    else{
      this.setState({token:''})
    }
    const user = JSON.parse(localStorage.getItem("user-info"));
    if(user){
      const userRole = user.profile.role_id;
      console.log(userRole)
      this.setState({userType: userRole},()=>{
        console.log('user role:', this.state.userType)
        if(this.state.userType == '4'){
              if(this.state.seeker_provider == 'provider'){
                this.getProviderBookings();
                 console.log('user is provider')
              }else{
                this.getUSerBookings();
                console.log('user is seeker')
              }
        }
        else if((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))){
          this.getProviderBookings();
           console.log('user is provider')
        }
        else if((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))){
          this.getUSerBookings();
          console.log('user is seeker')
        }else{
          console.log('nothing happens')
        }
        /////////
        // if((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))){
        //   this.getProviderBookings();
        //   console.log('user is provider')
        // }else if((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))){
        //   this.getUSerBookings();
        //   console.log('user is seeker')
        // }else{
        //   console.log('nothing happens')
        //      }
      });
    }
    console.log('service id is: ', this.state.serviceID, ' provider id is: ', this.state.providerID);
    // const userInfo = JSON.parse(localStorage.getItem("user-info"));
    // const userType = userInfo.profile.role_id;
       //getting current date
       var today = new Date(), 
       date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
       this.setState({currentDate: date});
    await this.setState({todayDate: today.toISOString().split('T')[0]},()=>{
      console.log('today date', this.state.todayDate, today)
    })
   
  }
  bookService= async () =>{
    //////////
    this.setState({isloading:true});
    const gettoken = JSON.parse(localStorage.getItem("token"));
    if(gettoken){
    const token = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

var formdata = new FormData();
formdata.append("place", this.state.place);
formdata.append("service_id", this.state.serviceID);
formdata.append("provider_id", this.state.providerID);
formdata.append("no_of_persons", this.state.peopleNum);
formdata.append("price", this.state.price);
formdata.append("start_date", this.state.startDate);
formdata.append("end_date", this.state.endDate);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/seeker/book_service", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.error){
      if(result.error.place){
        this.setState({placeError: result.error.place[0],isloading:false})
      }else{
        this.setState({placeError: '',isloading:false})
      }
      if(result.error.no_of_persons){
        this.setState({peopleNumError: result.error.no_of_persons[0],isloading:false})
      }else{
        this.setState({peopleNumError: '',isloading:false})
      }
      if(result.error.price){
        this.setState({priceError: result.error.price[0],isloading:false})
      }else{
        this.setState({priceError: '',isloading:false})
      }
      if(result.error.start_date){
        this.setState({startDateError: result.error.start_date[0],isloading:false})
      }else{
        this.setState({startDateError: '',isloading:false})
      }
      if(result.error.end_date){
        this.setState({endDateError: result.error.end_date[0],isloading:false})
      }else{
        this.setState({endDateError: '',isloading:false})
      }
    }
    else if(result.success == false){
      
      this.setState({error:true,isloading:false});
      setTimeout(() => {
        this.setState({error: false});
      }, 2000);
    }
    else{
      this.setState({booked: true, bookedMsg: result.Result,isloading:false});
      setTimeout(() => {
        this.setState({booked: false});
      }, 2000);
    }
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    const userType = userInfo.profile.role_id;
    this.setState({userType: userType})
    if((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))){
      this.getProviderBookings();
    }else if((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))){
      this.getUSerBookings();
    }
    else{    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({isloading:false})
  });
}
else{
  this.setState({cancelError:true, cancelErrorMsg:'Login First to Book a service'});
  setTimeout(() => {
    this.setState({cancelError: false});
  }, 3000);
}
    /////////
    // const place = this.state.place;
    // const no_of_persons = this.state.peopleNum;
    // const price = this.state.price;
    // const start_date = this.state.startDate;
    // const end_date = this.state.endDate;
    // const provider_id = this.state.providerID;
    // const service_id = this.state.serviceID;
    // let item = {place, no_of_persons, price, start_date, end_date, provider_id, service_id}
    // console.log(item);
    // const gettoken = JSON.parse(localStorage.getItem("token"));
    // if(gettoken){
    //   const token = gettoken.token;
    //   console.log('user logged in')
    //   let result = await fetch(api_url+"api/seeker/book_service", {
    //     method: "POST",
    //     body:JSON.stringify(item),
    //     headers : { 
    //       "Content-Type": "multipart/form-data;application/json",
    //       'Accept': 'application/json',
    //       "Authorization": `Bearer ${token}`,

    //      },
    //   });
    //   result = await result.json();
    //   if(result.success == false){
    //     this.setState({error:true})
    //   }
    //   else{
    //     this.setState({booked: true, bookedMsg: result.Result});
    //     setTimeout(() => {
    //       this.setState({booked: false});
    //     }, 2000);
    //   }
    //   console.warn('result', result)
    //   const userInfo = JSON.parse(localStorage.getItem("user-info"));
    //   const userType = userInfo.profile.role_id;
    //   this.setState({userType: userType})
    //   if(userType == '2'){
    //     this.getProviderBookings();
    //   }else if(userType == '3'){
    //     this.getUSerBookings();
    //   }
    //   else{    }
    // }
    // else{
    //   this.setState({noToken: true});
    // }
  }
  getUSerBookings = async()=>{
    this.setState({isloader:true})
    try{
    const gettoken = JSON.parse(localStorage.getItem("token"));
      const userToken = gettoken.token;
    let userBooking = await fetch(api_url+"api/seeker/bookings_list",{
    method: 'get',
    headers : {
      'Accept': 'application/json',
      "Authorization": `Bearer ${userToken}`
  },
    });
      userBooking = await userBooking.json();
      const reversedbookings = userBooking.map(item => item).reverse();
      this.setState({userBookings: reversedbookings,isloader:false});
      console.warn('result is',userBooking);
    }
    catch (err){
      console.log('Error')
    }
  }
  getProviderBookings = async()=>{
    this.setState({isloader:true})
    try{
    const gettoken = JSON.parse(localStorage.getItem("token"));
      const userToken = gettoken.token;
    let providerBooking = await fetch(api_url+"api/provider/service_orders_list",{
    method: 'get',
    headers : {
      'Accept': 'application/json',
      "Authorization": `Bearer ${userToken}`
  },
    });
      providerBooking = await providerBooking.json();
      const reversedbookings = providerBooking.map(item => item).reverse();
      this.setState({userBookings: reversedbookings, isloader:false});
      console.warn(this.state.userBookings);
    }
    catch (err){
      console.log('Error')
    }
  }
  modalOpen =()=>{
    this.setState(prevState => ({
      modalComplete: !prevState.modalComplete
    }));
  }
  modalCancel =()=>{
    this.setState(prevState => ({
      modalCancelBooking: !prevState.modalCancelBooking
    }));
  }
  modalContactAdmn =()=>{
    this.setState(prevState => ({
      modalContactAdmin: !prevState.modalContactAdmin
    }));
  }
  modalContct =()=>{
    this.setState(prevState => ({
      modalContact: !prevState.modalContact
    }));
  }
  modalApprove =()=>{
    this.setState(prevState => ({
      modalApproveBooking: !prevState.modalApproveBooking
    }));
  }
  contactSeeker = async()=>{
    this.setState({isloading:true});
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("to_user", this.state.toUSer);
formdata.append("booking_id", this.state.booking_id);
formdata.append("message", this.state.msgText);
formdata.append("created_at", this.state.currentDate);
formdata.append("updated_at", this.state.currentDate);
formdata.append("conversation_id", "");

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/provider/contact_seeker", requestOptions)
  .then(response => response.json())
  .then(contactSeekerResult => {
    console.log(contactSeekerResult);
    if(contactSeekerResult.error){
      this.setState({cancelError: true, cancelErrorMsg: contactSeekerResult.message,isloading:false});
    }else{
    this.setState({cancelBooking: contactSeekerResult,isloading:false});
        console.warn(this.state.cancelBooking)
        this.setState({
          modalContact: false,
         cancelSucces: true,
         cancelSuccesMsg: contactSeekerResult.Result
       });
       setTimeout(() => { //hide success msg after 2 seconds
        this.setState({cancelSucces: false});
      }, 2000);
       this.getProviderBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
});
    ////////////
    // const to_user = this.state.toUSer;
    // const booking_id = this.state.booking_id;
    // const message = this.state.msgText;
    // const created_at = this.state.currentDate;
    // const updated_at = this.state.currentDate;
    // let item ={to_user, booking_id, message, created_at, updated_at}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let contactSeekerResult = await fetch(api_url+"api/provider/contact_seeker",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!contactSeekerResult.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: contactSeekerResult.message});
    //   }else{
    //     contactSeekerResult = await contactSeekerResult.json();
    //     this.setState({cancelBooking: contactSeekerResult});
    //     console.warn(this.state.cancelBooking)
    //     this.setState({
    //       modalContact: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: contactSeekerResult.Result
    //    });
    //    setTimeout(() => { //hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getProviderBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  contactAdminP = async()=>{
    this.setState({isloading:true});
    console.log('provider is contacting admin')
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("booking_id", this.state.booking_id);
formdata.append("message", this.state.msgText);
formdata.append("created_at", this.state.currentDate);
formdata.append("updated_at", this.state.currentDate);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/provider/contact_admin", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result);
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
      this.setState({cancelBooking: result,isloading:false});
      console.warn(this.state.cancelBooking)
      this.setState({
       modalContactAdmin: false,
       cancelSucces: true,
       cancelSuccesMsg: result.Result
     });
     setTimeout(() => {//hide success msg after 2 seconds
      this.setState({cancelSucces: false});
    }, 2000);
     this.getProviderBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
});
    ///////////
    // const booking_id = this.state.booking_id;
    // const message = this.state.msgText;
    // const created_at = this.state.currentDate;
    // const updated_at = this.state.currentDate;
    // let item ={booking_id, message, created_at, updated_at}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let contactAdminResult = await fetch(api_url+"api/provider/contact_admin",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!contactAdminResult.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: contactAdminResult.message});
    //   }else{
    //     contactAdminResult = await contactAdminResult.json();
    //     this.setState({cancelBooking: contactAdminResult});
    //     console.warn(this.state.cancelBooking)
    //     this.setState({
    //      modalContactAdmin: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: contactAdminResult.Result
    //    });
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getProviderBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  contactAdminS = async()=>{
    this.setState({isloading:true});
    console.log('Seeker is contacting admin')
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("booking_id", this.state.booking_id);
formdata.append("message", this.state.msgText);
formdata.append("created_at", this.state.currentDate);
formdata.append("updated_at", this.state.currentDate);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/seeker/contact_admin", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result);
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
      this.setState({cancelBooking: result,isloading:false});
      console.warn(this.state.cancelBooking)
      this.setState({
        modalContactAdmin: false,
       cancelSucces: true,
       cancelSuccesMsg: result.Result
     });
     setTimeout(() => {//hide success msg after 2 seconds
      this.setState({cancelSucces: false});
    }, 2000);
     this.getUSerBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
});
    ///////
    // const booking_id = this.state.booking_id;
    // const message = this.state.msgText;
    // const created_at = this.state.currentDate;
    // const updated_at = this.state.currentDate;
    // let item ={booking_id, message, created_at, updated_at}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let contactAdminResult = await fetch(api_url+"api/seeker/contact_admin",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!contactAdminResult.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: contactAdminResult.message});
    //   }else{
    //     contactAdminResult = await contactAdminResult.json();
    //     this.setState({cancelBooking: contactAdminResult});
    //     console.warn(this.state.cancelBooking)
    //     this.setState({
    //       modalContactAdmin: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: contactAdminResult.Result
    //    });
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getUSerBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  contactProvider = async()=>{
    this.setState({isloading:true});
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("to_user", this.state.toUSer);
formdata.append("booking_id", this.state.booking_id);
formdata.append("message", this.state.msgText);
formdata.append("created_at", this.state.currentDate);
formdata.append("updated_at", this.state.currentDate);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/seeker/contact_provider", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result);
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
        this.setState({cancelBooking: result,isloading:false});
        console.warn(this.state.cancelBooking)
        this.setState({
          modalContact: false,
         cancelSucces: true,
         cancelSuccesMsg: result.Result
       });
       setTimeout(() => {//hide success msg after 2 seconds
        this.setState({cancelSucces: false});
      }, 2000);
       this.getUSerBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
});
    //////
    // const to_user = this.state.toUSer;
    // const booking_id = this.state.booking_id;
    // const message = this.state.msgText;
    // const created_at = this.state.currentDate;
    // const updated_at = this.state.currentDate;
    // let item ={to_user, booking_id, message, created_at, updated_at}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let contactProviderResult = await fetch(api_url+"api/seeker/contact_provider",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!contactProviderResult.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: contactProviderResult.message});
    //   }else{
    //     contactProviderResult = await contactProviderResult.json();
    //     this.setState({cancelBooking: contactProviderResult});
    //     console.warn(this.state.cancelBooking)
    //     this.setState({
    //       modalContact: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: contactProviderResult.Result
    //    });
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getUSerBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  cancelBooking = async()=>{
    this.setState({isloading:true});
    console.log('seeker is canceling booking');
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("booking_id", this.state.booking_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/seeker/cancel_booking", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
        this.setState({cancelBooking: result,isloading:false});
        console.warn(this.state.cancelBooking)
        this.setState({
          modalCancelBooking: false,
         cancelSucces: true,
         cancelSuccesMsg: result.message
       });
       setTimeout(() => {//hide success msg after 2 seconds
        this.setState({cancelSucces: false});
      }, 2000);
       this.getUSerBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
  });
    //////
    // const booking_id = this.state.booking_id;
    // let item ={booking_id}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let cancelBookingResult = await fetch(api_url+"api/seeker/cancel_booking",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!cancelBookingResult.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: cancelBookingResult.message});
    //   }else{
    //     cancelBookingResult = await cancelBookingResult.json();
    //     this.setState({cancelBooking: cancelBookingResult});
    //     console.warn(this.state.cancelBooking)
    //     this.setState({
    //       modalCancelBooking: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: cancelBookingResult.message
    //    });
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getUSerBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  cancelProvBooking = async()=>{
    this.setState({isloading:true});
    console.log('provider is canceling booking', this.state.booking_id);
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("booking_id", this.state.booking_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/provider/cancel_order", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
      this.setState({cancelBooking: result,isloading:false});
      console.warn(this.state.cancelBooking)
      this.setState({
        modalCancelBooking: false,
       cancelSucces: true,
       cancelSuccesMsg: result.message
     });
     setTimeout(() => {//hide success msg after 2 seconds
      this.setState({cancelSucces: false});
    }, 2000);
     this.getProviderBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
  });
    /////////
    // const booking_id = this.state.booking_id;
    // let item ={booking_id}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let cancelBookingResult = await fetch(api_url+"api/provider/cancel_order",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!cancelBookingResult.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: cancelBookingResult.message});
    //   }else{
    //     cancelBookingResult = await cancelBookingResult.json();
    //     this.setState({cancelBooking: cancelBookingResult});
    //     console.warn(this.state.cancelBooking)
    //     this.setState({
    //       modalCancelBooking: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: cancelBookingResult.message
    //    });
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getProviderBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  approveBooking = async()=>{
    this.setState({isloading:true});
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("booking_id", this.state.booking_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/provider/approve_order", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
      this.setState({approvedBooking: result,isloading:false});
      console.warn(this.state.approvedBooking)
      this.setState({
        modalApproveBooking: false,
       cancelSucces: true,
       cancelSuccesMsg: result.message
     });
     setTimeout(() => {//hide success msg after 2 seconds
      this.setState({cancelSucces: false});
    }, 2000);
     this.getProviderBookings();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
  });
    ///////////
    // const booking_id = this.state.approveID;
    // let item ={booking_id}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let proveBooking = await fetch(api_url+"api/provider/approve_order",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!proveBooking.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: proveBooking.message});
    //   }else{
    //     proveBooking = await proveBooking.json();
    //     this.setState({approvedBooking: proveBooking});
    //     console.warn(this.state.approvedBooking)
    //     this.setState({
    //       modalApproveBooking: false,
    //      cancelSucces: true,
    //      cancelSuccesMsg: proveBooking.message
    //    });
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //    this.getProviderBookings();
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  markComplete = async()=>{
    this.setState({isloading:true});
    console.log('mark complete', this.state.booking_id)
    const gettoken = JSON.parse(localStorage.getItem("token"));
    const userToken = gettoken.token;
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("booking_id", this.state.booking_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/mark_as_completed", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.error){
      this.setState({cancelError: true, cancelErrorMsg: result.message,isloading:false});
    }else{
      this.setState({
        completed: result,
        cancelSucces: true,
        cancelSuccesMsg: result.message,
        modalComplete: false,isloading:false
      });
      console.warn('mark as complete', this.state.completed, this.state.cancelSuccesMsg)
     setTimeout(() => {//hide success msg after 2 seconds
      this.setState({cancelSucces: false});
    }, 2000);
    if((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))){
      this.getProviderBookings();
    }
    else if((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))){
      this.getUSerBookings();
    }else{

    }
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({cancelError: true,isloading:false});
  });
    ////////
    // const booking_id = this.state.booking_id;
    // let item ={booking_id}
    // try{
    //   const gettoken = JSON.parse(localStorage.getItem("token"));
    //   const userToken = gettoken.token;
    //   let markAsComplete = await fetch(api_url+"api/mark_as_completed",{
    //   method: 'post',
    //   headers : {
    //     "Content-Type": "multipart/form-data;application/json",
    //     'Accept': 'application/json',
    //     "Authorization": `Bearer ${userToken}`
    // },
    // body: JSON.stringify(item)
    //   });
    //   if(!markAsComplete.ok){
    //     this.setState({cancelError: true, cancelErrorMsg: markAsComplete.message});
    //   }else{
    //     markAsComplete = await markAsComplete.json();
    //     this.setState({
    //       completed: markAsComplete,
    //       cancelSucces: true,
    //       cancelSuccesMsg: markAsComplete.message,
    //       modalComplete: false
    //     });
    //     console.warn('mark as complete', this.state.completed, this.state.cancelSuccesMsg)
    //    setTimeout(() => {//hide success msg after 2 seconds
    //     this.setState({cancelSucces: false});
    //   }, 2000);
    //   if(this.state.userType == 2){
    //     this.getProviderBookings();
    //   }
    //   else{
    //     this.getUSerBookings();
    //   }
    //   }
    //   }catch (err){
    //     this.setState({cancelError: true});
    //                }
  }
  cancel = async()=>{
    console.log('cancel')
  }
  onToken =(token)=>{
    console.log(token)
  }
  render() {
    return (
      <>
      <NavbarHome/>
      <div className='trip-banner'>
        <div className='trip-content'>
        <h2>MY BOOKINGS</h2>
        <p>Save time by planning your Booking and get offers from Locals</p>
        {/* <center>
        <div className='create-trip'>
            <h5>Book a Service</h5>
            <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className='float-left'>Place <span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Where Next?" onChange={(e) => this.setState({place: e.target.value})}/>
                  {this.state.placeError ? <span className='text-danger'>{this.state.placeError}</span> : ''}
                </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Start Date<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="date" max="9999-12-31" min={this.state.todayDate} onChange={(e) => this.setState({startDate: e.target.value})}/>
                  {this.state.startDateError ? <span className='text-danger'>{this.state.startDateError}</span> : ''}
                </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>End Date<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="date" max="9999-12-31" min={this.state.todayDate} onChange={(e) => this.setState({endDate: e.target.value})} />
                  {this.state.endDateError ? <span className='text-danger'>{this.state.endDateError}</span> : ''}
                </Form.Group>
                <hr/>
                    <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Number of people<span className='text-danger'>*</span></Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => this.setState({peopleNum: e.target.value})}>
                      <option>Just me</option>
                      <option value="1">Just me</option>
                      <option value="2">Two People</option>
                      <option value="3">Three People</option>
                      <option value="4">Four People</option>
                  </Form.Select>
                  {this.state.peopleNumError ? <span className='text-danger'>{this.state.peopleNumError}</span> : ''}
                </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Price<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="number" placeholder="1200" onChange={(e) => this.setState({price: e.target.value})} />
                  {this.state.priceError ? <span className='text-danger'>{this.state.priceError}</span> : ''}
                </Form.Group>
                <Button variant="primary" id="button-addon2" className='mt-3' onClick={this.bookService} disabled={this.state.isloading ? true : false}>
                    {this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'BOOK NEW SERVICE'}
                </Button>
      //            stripe payment method 
      //             <StripeCheckout
      //            className='mt-3'
      //            name="Service Price"
      //            currency='pkr'
      //            amount={this.state.servicePrice * 100}
      //   token={this.onToken}
      //   stripeKey="pk_test_51N4I0JI8Lg26wtAaA72JwsdGpNIGIwufhfuoCPPv2SrbiXcHpCuEL9HZF7GkrFXfCLZZMtHAhlNCC7GQEEk7JdoR00Q2JebyJZ"
      // >
      //   <Button variant="primary" className='mt-3' disabled={this.state.place && this.state.startDate && this.state.endDate && this.state.peopleNum && this.state.price ? false : true}>Pay with stripe</Button>
      // </StripeCheckout> 
        </div>
        </center> */}
        </div>
      </div>
      {this.state.isloader ? 
        <center>
        <div class="lds-facebook"><div></div><div></div><div></div></div>
      </center>
      :
      <>
      <div className='current-trip'>
      {this.state.userBookings.length > 0 ? 
      <>
        <center><h4>Current Bookings</h4></center>
      {this.state.userBookings.map((n)=>(
        <center>
        <Card className='trip-card'>
        <Card.Img variant="top" src={trip_bg} />
        <Card.Title className='trip-place'>Trip to {n.place}</Card.Title>
        <Card.Body>
          <Card.Text>
           <div className='d-flex justify-content-between'>
            <div className='d-flex'>
                <RiCalendarCheckFill className='calender-icon'/> <span>{moment(n.time_from, "HH:mm").format("hh:mm A")} <span className='text-secondary'> to </span> {moment(n.time_to, "HH:mm").format("hh:mm A")}</span>
            </div>
            {((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))) ? 
            //provider actions
            <div className='d-flex justify-content-between'>
                {/* <div className='text-secondary'><strong>Delete | Deactivate</strong></div> */}
                {n.status_text == 'Completed By Seeker' || n.status_text == 'Completed By Provider' ?
                <button className='action-booking-btn' title='Contact Admin' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalContactAdmn)} id={n.id}><RiAccountCircleLine className='text-primary action-icon'/></button>
                :
                n.status_text == 'Rejected By Provider' || n.status_text == 'Cancelled By Seeker' ?
                <>
                {/* <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link> */}
                <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat/?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link>
                {/* <RiEyeLine className='text-warning action-icon'/> */}
                <button className='action-booking-btn' title='Contact Admin' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalContactAdmn)} id={n.id}><RiAccountCircleLine className='text-primary action-icon'/></button>
                <button className='action-booking-btn' title='Contact Seeker' onClick={(event) => this.setState({ booking_id: event.currentTarget.id, toUSer: n.seeker_id},this.modalContct)} id={n.id}><RiChat3Line className='text-warning action-icon' title="Contact Seeker"/></button>
                </>
                :
                <>
                {n.status_text == 'Approved' ? '' :
                <button className='action-booking-btn' title='Approve' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalApprove)} id={n.id}><RiCheckboxCircleLine className='text-primary action-icon'/></button>
      }
                {/* <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link> */}
                <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat/?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link>
                {/* <RiEyeLine className='text-warning action-icon'/> */}
                <button className='action-booking-btn' title='Contact Admin' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalContactAdmn)} id={n.id}><RiAccountCircleLine className='text-primary action-icon'/></button>
                <button className='action-booking-btn' title='Contact Seeker' onClick={(event) => this.setState({ booking_id: event.currentTarget.id, toUSer: n.seeker_id},this.modalContct)} id={n.id}><RiChat3Line className='text-warning action-icon' title="Contact Seeker"/></button>
                <button className='action-booking-btn' title='Mark as Complete' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalOpen)} id={n.id}><RiCheckboxLine className='text-primary action-icon'/></button>
                <button className='action-booking-btn' title='Cancel' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalCancel)} id={n.id}><RiCloseCircleLine className='text-danger action-icon'/></button>
                
                </>
              }
              
                
            </div>
            :
            ((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))) ?
            //seeker action
            <div className='d-flex justify-content-between'>
                {/* <div className='text-secondary'><strong>Delete | Deactivate</strong></div> */}
                {n.status_text == 'Completed By Seeker' || n.status_text == 'Completed By Provider' ?
                <button className='action-booking-btn' title='Contact Admin' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalContactAdmn)} id={n.id}><RiAccountCircleLine className='text-primary action-icon'/></button>
                :
                n.status_text == 'Rejected By Provider' || n.status_text == 'Cancelled By Seeker' ?
                <>
                {/* <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat/?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link> */}
                <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat/?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link>
                {/* <RiEyeLine className='text-warning action-icon'/> */}
                <button className='action-booking-btn' title='Contact Admin' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalContactAdmn)} id={n.id}><RiAccountCircleLine className='text-primary action-icon'/></button>
                <button className='action-booking-btn' title='Contact Provider' onClick={(event) => this.setState({ booking_id: event.currentTarget.id, toUSer: n.person_no},this.modalContct)} id={n.id}><RiChat3Line className='text-warning action-icon'/></button>
                </>
                :
                <>
                {/* <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat/?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link> */}
                <Link to={{ pathname: "http://dashboard.dinfoo.com/apps-chat/?refresh=true&&token="+this.state.token}} target="_blank"><RiWechatLine className='text-primary action-icon'/></Link>
                {/* <RiEyeLine className='text-warning action-icon'/> */}
                <button className='action-booking-btn' title='Contact Admin' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalContactAdmn)} id={n.id}><RiAccountCircleLine className='text-primary action-icon'/></button>
                <button className='action-booking-btn' title='Contact Provider' onClick={(event) => this.setState({ booking_id: event.currentTarget.id, toUSer: n.person_no},this.modalContct)} id={n.id}><RiChat3Line className='text-warning action-icon'/></button>
                <button className='action-booking-btn' title='Mark as Complete' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalOpen)} id={n.id}><RiCheckboxLine className='text-primary action-icon'/></button>
                <button className='action-booking-btn' title='Cancel' onClick={(event) => this.setState({ booking_id: event.currentTarget.id},this.modalCancel)} id={n.id}><RiCloseCircleLine className='text-danger action-icon'/></button>
                
                </>
              }
              
                
            </div>
            :
            ''
  }
           </div>
           <hr/>
           <div className='d-flex justify-content-between'>
           <div><strong> <span className='text-secondary'>Number of people: </span>{n.no_of_persons} </strong></div>
           <div className='text-secondary'><strong>Booking Status:  </strong>{n.status_text} </div>
           </div>
          </Card.Text>
        </Card.Body>
      </Card>
      <br/>
            </center>
      ))}
      </>
       :
      //  this.state.token == null || this.state.fbloggedIn == false || this.state.googleloggedIn == false ? 
       this.state.token == null ? 

       <center className='text-secondary mb-5'>
        Login First<br/>To see your Current Bookings.
       </center>
       :
       <center className='text-secondary mb-5'>
        You haven't booked any Service yet.<br/>
        Book your first service so available providers can send you offers.
       </center>
  }
      </div>
      </>
      }
      <Footer/>
      {
      this.state.noToken ?
      <Alert key='danger' variant='danger' className='log-alert' dismissible onClose={()=>this.setState({noToken: false})}>
      Please Login/Signup First to Book a Service.
       </Alert>
       :
      this.state.booked ? 
    <Alert key='success' variant='success' className='log-alert'>
   {this.state.bookedMsg}
  </Alert>
  :
  this.state.error ?
  <Alert key='danger' variant='danger' className='log-alert' onClose={() => this.setState({error: false})} dismissible>
  {this.state.bookedMsg ?  this.state.bookedMsg : 'Something Wrong'}
  </Alert>
  :
  this.state.cancelSucces ? 
            <Alert key='success' variant='success' className='log-alert'>
           {this.state.cancelSuccesMsg}
            </Alert>
            :
            this.state.cancelError ? 
            <Alert key='danger' variant='danger' className='log-alert' dismissible onClose={()=>this.setState({cancelError: false})}>
           {this.state.cancelErrorMsg}
            </Alert>
            :
            
            ''
    }
    {/*mark complete model */}
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.modalComplete} onHide={()=>this.setState({modalComplete: false})}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Mark as Complete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> <p>  Are you sure?</p></Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.markComplete} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Yes'}</Button>
        <Button variant="danger" onClick={()=>this.setState({modalComplete: false})}>No</Button>
      </Modal.Footer>
    </Modal>
    {/*cancel model */}
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.modalCancelBooking} onHide={()=>this.setState({modalCancelBooking: false})}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Cancel Booking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> <p>  Are you sure you want to cancel this booking?</p></Modal.Body>
      <Modal.Footer>
        {((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))) ? 
        <Button variant="primary" onClick={this.cancelProvBooking} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Yes'}</Button>:
        ((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))) ?
        <Button variant="primary" onClick={this.cancelBooking} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Yes'}</Button>
      :''}
        <Button variant="danger" onClick={()=>this.setState({modalCancelBooking: false})}>No</Button>
      </Modal.Footer>
    </Modal>
    {/*Approve model */}
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.modalApproveBooking} onHide={()=>this.setState({modalApproveBooking: false})}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Approve Booking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> <p>  Are you sure you want to Approve this booking?</p></Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.approveBooking} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Yes'}</Button>
        <Button variant="danger" onClick={()=>this.setState({modalApproveBooking: false})}>No</Button>
      </Modal.Footer>
    </Modal>
    {/*contact Admin model */}
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.modalContactAdmin} onHide={()=>this.setState({modalContactAdmin: false})}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Contact Admin
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Your Message</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Enter Your Message" onChange={(e) => this.setState({msgText: e.target.value})}/>
      </Form.Group>
    </Form>
      </Modal.Body>
      <Modal.Footer>
        {((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))) ?
        <Button variant="primary" onClick={this.contactAdminP} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Send'}</Button>
        :
        ((this.state.userType == '3') || ((this.state.userType == '4') && (this.state.seeker_provider == 'seeker'))) ?
        <Button variant="primary" onClick={this.contactAdminS} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Send'}</Button>
      :''}
      </Modal.Footer>
    </Modal>
    {/*contact model */}
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.modalContact} onHide={()=>this.setState({modalContact: false})}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Contact {((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))) ? 'Seeker' : 'Provider'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Your Message</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Enter Your Message" onChange={(e) => this.setState({msgText: e.target.value})}/>
      </Form.Group>
    </Form>
      </Modal.Body>
      <Modal.Footer>
        {((this.state.userType == '2') || ((this.state.userType == '4') && (this.state.seeker_provider == 'provider'))) ?
        <Button variant="primary" onClick={this.contactSeeker} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Send'}</Button>:
        <Button variant="primary" onClick={this.contactProvider} disabled={this.state.isloading ? true : false}>{this.state.isloading ? <Spinner animation="border" variant="light" size="sm"/> : 'Send'}</Button>}
      </Modal.Footer>
    </Modal>
      </>
    )
  }
}
export default withRouter(MyTrips);