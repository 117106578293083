import React, { Component } from 'react'
import Navbar from "../Components/NavbarHome";
import {Row, Col, Card, Spinner, Modal, Form, Alert,Badge,Button} from "react-bootstrap";
import Footer from "../Components/Footer";
import "../Assets/Styles/style.css";
import {withRouter} from 'react-router-dom'
import {Link, Redirect} from 'react-router-dom'
import { DynamicStar } from 'react-dynamic-star';
import Pagination from 'react-bootstrap/Pagination';
import { RiSendPlane2Fill,RiBookmarkLine } from "react-icons/ri";
import { BsPaypal } from "react-icons/bs";
import { SiStripe } from "react-icons/si";
import moment from 'moment';
import Calendar from 'react-calendar';
//images
import review1 from '../Assets/Images/review1.jpg';
import user from '../Assets/Images/user.png';
//slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url } from "../Variables";
import serviceImg from '../Assets/Images/serviceImage.jpg';
import StripeCheckout from 'react-stripe-checkout';
import Paypal from '../Components/Paypal';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
class Provider extends Component {
    constructor(props){
        super(props);
   
        this.state = {
            providerServices:[''],
            providerServicesData:'',
            isloading: true,
            providerID: this.props.location.state,
            provider:'',
            review:'',
            userRole:'',
            redirect: null,
            providerLanguages:[],
            checked5: false,
            checked4: false,
            checked3: false,
            checked2: false,
            checked1: false,
            reviews:[],
            profileImages:[],
            isloader:true,
            currentPage:1,
            token:'',
            contactModal:false,
            contactText:'',
            currentDate:'',
            todayDate:'',
            success:false,
            successMsg:'',
            errorMsg:false,
            errorMsgTxt:'',
            slotModal:false,
            place:'',
            placeError:'',
            startDate: '',
            startDateError: '',
            endDate: '',
            endDateError: '',
            peopleNum: '',
            peopleNumError: '',
            price: '',
            priceError: '',
            service_id:'',
            service_idError:'',
            time:'',
            timeError:'',
            servicesDropdown:[],
            contactTextInvalid:false,
            seeker_provider: localStorage.getItem('seekerORprovider') || 'seeker',
            markasFav:false,
            markFavLoader:false,
            allFavs:[],
            itemID:'',
            noteModal:false,
            noteText:'',
            slotDate: new Date(),
            slotDay:'',
            slotDayDate:new Date(),
            providerSlots:[],
            slotID:'',
            slotError:'',
            paypalModal:false,
            paymet_type:0,
            trx_id:'',
            stripe_loader:false,
            paypal_loader:false,
            provider_rate:0,
            allFieldsError:''
      // fbloggedIn:false,
      // googleloggedIn:false,
        }
    }
    componentDidMount =async()=>{
      this.setState({slotDate: moment(this.state.slotDate).format('YYYY-MM-DD'), slotDay: moment(this.state.slotDate).format('dddd')})
      // this.setState({slotDay:moment(this.state.slotDate).format('dddd'), slotDate:moment(this.state.slotDate).format('')})
      window.addEventListener("storage",(e) => {
      console.log('storage changed')
      const role = (localStorage.getItem("seekerORprovider"));
      this.setState({seeker_provider: role})
     });
      const getToken = JSON.parse(localStorage.getItem("token"));
      // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
      // const googleLogIn = (JSON.parse(localStorage.getItem('googleLogIn')));
      if(getToken){
        const token = getToken.token;
        this.setState({token: token});
        this.getFavoriteList()
      }
      // else if(fbLogIn){
      //   this.setState({fbloggedIn:true})
      // }else if(googleLogIn){
      //   this.setState({googleloggedIn:true})
      // }
      else{
        this.setState({token:''})
      }
      const userDetail = JSON.parse(localStorage.getItem("user-info"));
      if(userDetail){
        this.setState({userRole: userDetail.profile.role_id},()=>{
          console.log('user role is', this.state.userRole)
        })
      }else{
        const userRole = localStorage.getItem("seekerORprovider");
        if(userRole === 'provider'){
          this.setState({userRole:'2'})
        }else{
          this.setState({userRole: '3'})
        }
      }
      this.getProvider(1);
      this.getProviderServices();
             //getting current date
             var today = new Date(), 
             date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
             this.setState({currentDate: date});
             await this.setState({todayDate: today.toISOString().split(':')[0]+":"+today.toISOString().split(':')[1]},()=>{
              console.log('today date', today.toISOString().split(':')[0]+":"+today.toISOString().split(':')[1],'....', today)
            })

    }
    getProviderServices = async()=>{
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(api_url+"api/provider/services/"+this.state.providerID, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.setState({servicesDropdown: result.All_Services_by_Provider},()=>{
            console.log('services are',this.state.servicesDropdown)
          })
        })
        .catch(error => console.log('error', error));
    }
    getProvider = async(pageNo) =>{
      console.log('provider id is: ', this.state.providerID);
      let provider = await fetch(`${api_url}api/provider/detail/${this.state.providerID}?page=${pageNo}`,{
            method: "get",
            headers: {
            'Accept': 'application/json'
            }
          });
          provider = await provider.json();
          if(provider){
            this.setState({providerServices: provider.All_Services_by_Provider.data,isloader: false,
              providerServicesData:provider.All_Services_by_Provider, providerLanguages: provider.User_languages, 
              provider: provider.profile, place:provider.profile.address, price:provider.profile.hourly_rate, 
              provider_rate: provider.profile.hourly_rate, id:provider.profile.id ,
              profileImages: provider.profile_images,reviews: provider.profile.review, isloading: false , 
              providerSlots:provider.provider_booking_slots}, () => {
               console.log('provider details', provider,'services', this.state.providerServicesData);
             });
           }
           {this.state.provider.review ?

            this.state.provider.review[0].average_ratings ? 
    (this.state.provider.review[0].average_ratings <= 5 && this.state.provider.review[0].average_ratings > 4) ?
    this.setState({checked5: true})
    :
    (this.state.provider.review[0].average_ratings <= 4 && this.state.provider.review[0].average_ratings > 3) ?
    this.setState({checked4: true})
    :
    (this.state.provider.review[0].average_ratings <= 3 && this.state.provider.review[0].average_ratings > 2) ?
    this.setState({checked3: true})
    :
    (this.state.provider.review[0].average_ratings <= 2 && this.state.provider.review[0].average_ratings > 1) ?
    this.setState({checked2: true})
    :
    (this.state.provider.review[0].average_ratings <= 1 && this.state.provider.review[0].average_ratings > 0) ?
    this.setState({checked1: true})
    :
    this.setState({checked5: false,checked4: false,checked3: false,checked2: false,checked1: false})
    :
    this.setState({checked5: false,checked4: false,checked3: false,checked2: false,checked1: false})
    :
    this.setState({checked5: false,checked4: false,checked3: false,checked2: false,checked1: false})
    
           }
    }
    getServices = async(e)=>{
      // this.getProvider(e);
      console.log(e);
      this.setState({currentPage:e})
      this.getProvider(e);
    }
    getPrev = async()=>{
      this.setState({currentPage:this.state.currentPage - 1})
      this.getProvider(this.state.currentPage - 1);
    }
    getFirst = async()=>{
      this.setState({currentPage:1})
      this.getProvider(1);
    }
    getNext = async()=>{
      this.setState({currentPage:this.state.currentPage + 1})
      this.getProvider(this.state.currentPage + 1);
    }
    getLast = async()=>{
      this.setState({currentPage:this.state.providerServicesData.last_page})
      this.getProvider(this.state.providerServicesData.last_page);
    }
    contactProvider = async()=>{
      this.setState({isloading: true})
      var myHeaders = new Headers();
      const gettoken = JSON.parse(localStorage.getItem("token"));
      const userToken = gettoken.token;
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("to_user", this.state.provider.id);
formdata.append("message",this.state.contactText);
formdata.append("created_at", this.state.currentDate);
formdata.append("updated_at", this.state.currentDate);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/seeker/contact_provider", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.error){
      this.setState({errorMsg:true, errorMsgTxt:result.error.message ? result.error.message[0] : "Something Wrong", isloading:false});
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({errorMsg: false, errorMsgTxt: ''});
      }, 3000);
    }else{
      this.setState({
        contactModal:false,success:true, successMsg:result.Result,isloading:false
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({success: false, successMsg: ''});
      }, 3000);
    }
    console.log(result)
  })
  .catch(error => {
    console.log('error', error)
    this.setState({isloading:false})
  });
  /////////////////
      // const to_user = this.state.provider.id;
      // const message = this.state.contactText;
      // const created_at = this.state.currentDate;
      // const updated_at = this.state.currentDate;
      // let item ={to_user, message, created_at, updated_at}
      // try{
      //   const gettoken = JSON.parse(localStorage.getItem("token"));
      //   const userToken = gettoken.token;
      //   let contactProviderResult = await fetch(api_url+"api/seeker/contact_provider",{
      //   method: 'post',
      //   headers : {
      //     "Content-Type": "multipart/form-data;application/json",
      //     'Accept': 'application/json',
      //     "Authorization": `Bearer ${userToken}`
      // },
      // body: JSON.stringify(item)
      //   });
      //   if(!contactProviderResult.ok){
      //     this.setState({cancelError: true, cancelErrorMsg: contactProviderResult.message});
      //   }else{
      //     contactProviderResult = await contactProviderResult.json();
      //     this.setState({cancelBooking: contactProviderResult});
      //     console.warn(this.state.cancelBooking)
      //     this.setState({
      //       modalContact: false,
      //      cancelSucces: true,
      //      cancelSuccesMsg: contactProviderResult.Result
      //    });
      //    setTimeout(() => {//hide success msg after 2 seconds
      //     this.setState({cancelSucces: false});
      //   }, 2000);
      //    this.getUSerBookings();
      //   }
      //   }catch (err){
      //     this.setState({cancelError: true});
      //                }
    }
    bookSlot = async()=>{
      console.log('transaction id is', this.state.trx_id, 'payment type is', this.state.paymet_type)
      this.setState({isloading:true})
      var myHeaders = new Headers();
      const gettoken = JSON.parse(localStorage.getItem("token"));
      const userToken = gettoken.token;
myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
if(this.state.price){
  this.setState({priceError:''})
}else{
  this.setState({priceError:'This Price field is required',isloading:false,stripe_loader:false, paypal_loader:false})
}
if(this.state.place){
  this.setState({placeError:''})
}else{
  this.setState({placeError:'This Place field is required',isloading:false,stripe_loader:false, paypal_loader:false})
}
if(this.state.peopleNum){
  this.setState({peopleNumError:''})
}else{
  this.setState({peopleNumError:'This Number of people is required',isloading:false,stripe_loader:false, paypal_loader:false})
}
if(this.state.slotDate && this.state.slotID){
  this.setState({slotError:''})
}else{
  this.setState({slotError:'Please Select one Slot',isloading:false,stripe_loader:false, paypal_loader:false})
}
if(this.state.priceError || this.state.placeError || this.state.slotError || this.state.peopleNumError){
 this.setState({isloading:false,stripe_loader:false, paypal_loader:false})
}
else{
  formdata.append("place", this.state.place);
  formdata.append("provider_id", this.state.providerID);
  formdata.append("no_of_persons", this.state.peopleNum);
  formdata.append("price", this.state.price);
  formdata.append("date", this.state.slotDate);
  formdata.append("slot_id", this.state.slotID);
  formdata.append("trx_id", this.state.trx_id);
  formdata.append("payment_channel", this.state.paymet_type);
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"api/seeker/book_service_slot", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      // this.setState({isloading:false})
      if(result.status == false){
       this.setState({errorAlert:true,errorMsg:result.Result,isloading:false,stripe_loader:false, paypal_loader:false})
      }else if(result.success == false){
        this.setState({isloading:false,stripe_loader:false, paypal_loader:false})
      }
      else{
        this.setState({success: true, successMsg: result.Result,isloading:false,slotModal:false,slotID:'',stripe_loader:false, paypal_loader:false,paymet_type:0});
        setTimeout(() => {
          this.setState({success: false, successMsg:'',redirect: "/mytrips/"+this.state.providerID});
        }, 3000);
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isloading:false,stripe_loader:false, paypal_loader:false})
    });
}
    }
    setConatctText=async(e)=>{
      if(/^[A-Za-z0-9\s.,?/]*$/.test(e.target.value)){
        this.setState({contactText:e.target.value,contactTextInvalid:false},()=>{
          console.log(this.state.contactText)
        })
      }else{
        this.setState({contactTextInvalid:true})
      }
    }
    markFavorite = async()=>{
      console.log('provider id is', this.state.providerID)
      this.setState({markFavLoader:true})
      var myHeaders = new Headers();
      const gettoken = JSON.parse(localStorage.getItem("token"));
      const userToken = gettoken.token;
      myHeaders.append("Authorization", `Bearer ${userToken}`);

var formdata = new FormData();
formdata.append("provider_id", this.state.providerID);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/seeker/save_favourite_provider", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.error){
      this.setState({markFavLoader:false})
    }else{
      this.setState({markFavLoader:false,markasFav:true,success:true,successMsg:result.Result})
      setTimeout(() => {
        this.setState({success:false,successMsg:''})
      }, 3000);
    }
  })
  .catch(error => {
    console.log('error', error)
  });
    }
    getFavoriteList = async()=>{
      var myHeaders = new Headers();
      const gettoken = JSON.parse(localStorage.getItem("token"));
      const userToken = gettoken.token;
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(api_url+"api/seeker/show_all_favourites", requestOptions)
        .then(response => response.json())
        .then(result => {
          result.map((n)=>{
            if(n.provider_id){ // from favorites list of providers and services, fetch only providers
              this.setState(prevState => ({
                allFavs: [...prevState.allFavs, n] 
              }),()=>{
                console.log('fav providers are', this.state.allFavs)
                const isFound = this.state.allFavs.some(element => { 
                  if(element.provider_id == this.state.providerID){
                    this.setState({itemID:element.id},()=>{
                      console.log(this.state.itemID)})
                    return true;
                  }
                  else{
                    return false
                  }
                });
                if(isFound){
                  console.log('matcheddddd')
                  this.setState({markasFav:true})
                }
              })
              // this.setState({allFavs:...this.state.allFavs,n})
            }
          })
        })
        .catch(error => console.log('error', error));
    }
    makeFavNote = async()=>{
      this.setState({isloading:true,errorMsgTxt:''});
      if(this.state.noteText){
        var myHeaders = new Headers();
        const gettoken = JSON.parse(localStorage.getItem("token"));
        const userToken = gettoken.token;
        myHeaders.append("Authorization", `Bearer ${userToken}`);
  
  var formdata = new FormData();
  formdata.append("item_id", this.state.itemID);
  formdata.append("note", this.state.noteText);
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"api/seeker/make_note_on_favourite", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.error){
        this.setState({errorAlert:true, errorMsg:result.error,isloading:false})
      }else{
        this.setState({success:true, successMsg:result.Result, noteModal:false, isloading:false});
        setTimeout(() => {
          this.setState({success:false, successMsg:''});
        }, 3000);
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isloading:false})
    });
      }else{
        this.setState({errorMsgTxt:'The Note field is required',isloading:false})
      }
       
    }
    onToken =(token)=>{
      console.log(token)
      this.setState({trx_id:token.id,paymet_type:2,stripe_loader:true},()=>{
        if (this.state.trx_id){
          this.bookSlot();
        }
      })
    }
    createOrder = (data, actions, err) => {
      return actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {
            description: this.state.place,
            amount: {
              currency_code: "USD",
              value: this.state.price,
            },
          },
        ],
      });
  }
  onApprove = async(data, actions) => {
    const order = await actions.order.capture();
    console.log(order);
    this.setState({trx_id:order.id,paymet_type:1,paypal_loader:true,paypalModal:false,slotModal:true},()=>{
      if (this.state.trx_id){
        this.bookSlot();
      }
    })
      }
      checkAllFields =()=>{
        if(this.state.place && this.state.price && this.state.peopleNum && this.state.slotDate && this.state.slotID){
          this.setState({paypalModal:true, slotModal:false,paymet_type:1})
        }else{
          this.setState({allFieldsError:'Please fill out all required fields first..'})
        }
      }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
let items = [];
for (let number = 1; number <= this.state.providerServicesData.last_page; number++) {
  items.push(
    <Pagination.Item key={number} active={number === this.state.currentPage} onClick={()=>this.getServices(number)}>
      {number}
    </Pagination.Item>,
  );
}
if (this.state.redirect) {
  return <Redirect  to={{
    pathname: this.state.redirect,
    state : {provider_id: this.state.providerID}
  }}/>
}
    return (
     <div className='bg-gray'>
      <Navbar role={this.state.userRole}/>
      <div className='local-banner-img'>
            <div className='local-banner-content'>
              {this.state.profileImages.length >0 ? 
            <Slider {...settings} className="guide-top-carousel">
              {this.state.profileImages.map((n)=>(
                <div>
                    <img src={n} alt="user" className='guide-carousel'/>
                </div>
              ))}
            </Slider>
            :
            <img src={user} alt="user" className='guide-carousel'/>           
            }
            <div className='carousel-side-content'>
            <div className="d-flex justify-content-between name-price">
                      <div>
                        <div className='d-flex justify-content-center align-items-center'>
                        <h5 className="card-title text-capitalize">{this.state.provider.name}</h5>
                        {this.state.provider.seller_type == 'local' || this.state.provider.seller_type == 'Local' ?
                        <Badge bg="info" className='mx-2'>Local</Badge>
                        :
                        this.state.provider.seller_type == 'pro' || this.state.provider.seller_type == 'Pro' ?
                        <Badge bg="primary" className='mx-2'>Pro</Badge>
                        : '' }
                        </div>
                        <p className="card-text d-flex location-txt">
                          {this.state.provider.address ? this.state.provider.address : ''}
                        </p>
                      </div>
                      {this.state.provider.hourly_rate ?
                      <div className="price">
                        <strong>${this.state.provider.hourly_rate}</strong>/h
                      </div>:''}
                    </div>
                    {this.state.provider.about ? 
                    <blockquote>
                    <q>{this.state.provider.about}</q>
                  </blockquote>
                  :
                  ''
                  }
                    
            </div>
            </div>
            <div className='mbl-local-banner-content'>
              {this.state.profileImages.length >0 ?
              <Slider {...settings} className="guide-top-mbl-carousel">
                 {this.state.profileImages.map((n)=>(
               <div className='position-relative'>
               <img src={n} alt="carousel" className='guide-carousel'/>
               <div className="mbl-name-price">
                 <div>
                   <div className='d-flex'>
                   <h2 className="card-title">
                   {this.state.provider.name}</h2>
                   {this.state.reviews.length > 0 ?
                   this.state.provider.review[0].average_ratings ?
                    <DynamicStar rating={this.state.provider.review[0].average_ratings} outlined={true} width='25' height='25' className="justify-content-center"/>
                    :
                    <DynamicStar rating={0} outlined={true} width='25' height='25' className="justify-content-center"/>
       :
       ''}            
       </div>
                   <p className="card-text">{this.state.provider.address ? this.state.provider.address : ''}</p>
                 </div>
                 {/* <h1 className="price"><strong>$25</strong>/h</h1> */}
                 </div>
           </div>
              ))}
              </Slider>
              :
              <>
              <img src={user} alt="user" className='guide-carousel position-relative w-100'/> 
              <div className="mbl-name-price" style={{position:'absolute', bottom:'10px', left:'20px'}}>
                      <div>
                        <div className='d-flex'>
                        <h2 className="card-title">
                        {this.state.provider.name}</h2>
                        {this.state.reviews.length > 0 ?
                        this.state.provider.review[0].average_ratings ?
            <DynamicStar rating={this.state.provider.review[0].average_ratings} outlined={true} width='25' height='25' className="justify-content-center"/>
            :
            <DynamicStar rating={0} outlined={true} width='25' height='25' className="justify-content-center"/>
            :
            ''}            
            </div>
                        <p className="card-text">{this.state.provider.address ? this.state.provider.address : ''}</p>
                      </div>
                      {/* <h1 className="price"><strong>$25</strong>/h</h1> */}
                      </div>
                      </>
  }
            </div>
        </div>
        <div className='mbl-local-content'>
        {this.state.provider.about ? 
                    <blockquote>
                    <q>{this.state.provider.about}</q>
                  </blockquote>
                  :
                  ''
                  }
        <Card className='mbl-trip-card'>
    {this.state.userRole == '2' || (this.state.userRole == '4' && this.state.seeker_provider === 'provider') || this.s  ? 
    ''
    :
          // this.state.token || this.state.fbloggedIn || this.state.googleloggedIn ? 
          this.state.token ? 
     <Card.Body>

      Contact <strong className='text-capitalize'>{this.state.provider.name}</strong> directly
      <button className='contact-button' onClick={()=>this.setState({contactModal:true})}>contact</button>
      <span className='left-right-line'>or</span>
      <button className='contact-button' onClick={()=>this.setState({slotModal:true})}>Book a Slot</button>
      {this.state.markasFav ? 
      <button className='contact-button' onClick={()=>this.setState({noteModal:true})}>Make a Note</button>
      :
      <button className='contact-button' onClick={this.markFavorite} disabled={this.state.markasFav ? true : false}>{this.state.markFavLoader ? <Spinner animation="border" variant="primary" size="sm"/> : <> <RiBookmarkLine/>Mark as Favourite</>}</button>
      }
      </Card.Body>
      :
      <Card.Body>
            <strong>LOGIN</strong> To Contact <strong className='text-capitalize'>{this.state.provider.name}</strong>
           {/* <button className='contact-button' onClick={() => this.setState({ showLogin: true})}>LOGIN</button> */}
      </Card.Body>
  }
    </Card>
        </div>
      <Row className='content'>
        <Col md={8} lg={8} xs={12}>
        <div className='local-content'>
        <h2 className='border-bottom'>Explore {this.state.provider.address ? this.state.provider.address+' ' : ''}  
        with <span className='text-capitalize'>{this.state.provider.name}</span></h2>
        <Row  className='border-bottom'>
            <Col md={4} lg={4} xs={12}>
                <div>
                    <strong>I will show you</strong>
                </div>
            </Col>
            <Col md={8} lg={8} xs={12}>
                <div>
                history and culture is amazing, lahore is a beautiful mix and match of new and old and that's what i give u in tour with me.
                </div>
            </Col>
        </Row>
        {this.state.provider.about ? 
        <Row  className='border-bottom mt-3'>
            <Col md={4} lg={4} xs={12}>
                <div>
                    <strong>About me</strong>
                </div>
            </Col>
            <Col md={8} lg={8} xs={12}>
                <div>
                {this.state.provider.about}
                </div>
            </Col>
        </Row>
        :
        ''
  }
  {this.state.providerLanguages ?
        <Row  className='border-bottom mt-3'>
            <Col md={4} lg={4} xs={12}>
                <div>
                    <strong>Languages</strong>
                </div>
            </Col>
            <Col md={8} lg={8} xs={12}>
                {this.state.providerLanguages ? this.state.providerLanguages.map((n)=>(
                  <span>{n} , </span>
                )):''}
            </Col>
        </Row>
        :
        ''}
        <Row  className='mt-3'>
            <Col md={4} lg={4} xs={12}>
                <div>
                    <strong>Activities</strong>
                </div>
            </Col>
            <Col md={8} lg={8} xs={12}>
                <div> 
                    Translation & Interpretation<br/>
                    Shopping<br/>
                    Exploration & Sightseeing<br/>
                    Pick up & Driving Tours
                
                </div>
            </Col>
        </Row>
        <br/>
      </div>
        </Col>
        <Col md={4} lg={4} xs={12}>
        <div className='review-card'>
      <Card>
      {this.state.reviews.length > 0 ?
      this.state.provider.review[0].average_ratings ?
        <Card.Header className='d-flex flex-column'>
            <DynamicStar rating={this.state.provider.review[0].average_ratings} outlined={true} width='25' height='25' className="justify-content-center"/>
            <div className='mt-3'>Reviews: {this.state.provider.review[0].total_no_of_reviews}</div>
            </Card.Header>
            :
            <Card.Header className='d-flex flex-column'>
            <DynamicStar rating={0} outlined={true} width='25' height='25' className="justify-content-center"/>
            <div className='mt-3'>Reviews: {this.state.provider.review[0].total_no_of_reviews}</div>
            </Card.Header>
             :
             ''
           }
      <Card.Body>
        {/* Create a trip to Lahore and get offers from locals
      <Link to="/"><button className='trip-button'>book this service</button></Link>
      <span className='left-right-line'>or</span> */}
    {/* {this.state.userRole == '2' || (this.state.userRole == '4' && this.state.seeker_provider === 'provider') || (this.state.fbloggedIn && this.state.seeker_provider === 'provider') || (this.state.googleloggedIn && this.state.seeker_provider === 'provider') ?  */}
    {this.state.userRole == '2' || (this.state.userRole == '4' && this.state.seeker_provider === 'provider') ? 
    ''
    :
          // this.state.token || this.state.fbloggedIn || this.state.googleloggedIn ? 
          this.state.token  ? 
     <>

      Contact <strong className='text-capitalize'>{this.state.provider.name}</strong> directly
      <button className='contact-button' onClick={()=>this.setState({contactModal:true,contactTextInvalid:false})}>contact</button>
      <span className='left-right-line'>or</span>
      <button className='contact-button mb-3' onClick={()=>this.setState({slotModal:true})}>Book a Slot</button>
      {this.state.markasFav ? 
      <button className='contact-button' onClick={()=>this.setState({noteModal:true})}>Make a Note</button>
      :
      <button className='contact-button' onClick={this.markFavorite} disabled={this.state.markasFav ? true : false}>{this.state.markFavLoader ? <Spinner animation="border" variant="primary" size="sm"/> : <> <RiBookmarkLine/>Mark as Favourite</>}</button>
      }
      </>
      :
      <>
            <strong>LOGIN</strong> To Contact <strong className='text-capitalize'>{this.state.provider.name}</strong>
           {/* <button className='contact-button' onClick={() => this.setState({ showLogin: true})}>LOGIN</button> */}
      </>
  }
      </Card.Body>
    </Card>
      </div>
        <Card className="more-info-card">
      <Card.Body>
        <strong>More Info</strong>
        <div className='text-primary'><Link to="/about">About Dinfoo</Link></div>
        <div className='text-primary'><Link to="/booking_advice">Booking Advice</Link></div>
        <hr/>
        <strong>Need Assistance?</strong>
        <div> Contact our Customer Support if you need any assistance managing your bookings.</div>
       
      </Card.Body>
    </Card>
        </Col>
      </Row>
      {/* <div className="content">
      { this.state.isloader ?
        <center>
        <div className='d-flex justify-content-center w-100'>
        <Spinner type="grow" className="mr-2" color="success"/> &nbsp; &nbsp; &nbsp;
        <Spinner type="grow" className="mr-2" color="success" /> &nbsp; &nbsp; &nbsp;
        <Spinner type="grow" className="mr-2" color="success" />
        </div>
        </center>
        :
      this.state.providerServices.length > 0 ?
      <Row className="mt-5">
      <h2>Provider's Services</h2>
           {this.state.providerServices.map((n)=>(
            <Col md={4} lg={4} xs={12}>
               <div className="container_foto ">
         <div className="ver_mas text-center">
         <Link to={{pathname: "/service/"+ n.id+"/"+this.state.provider.id, state : {service_id: n.id, provider_id: this.state.provider.id}}}><button>View service</button></Link>
         </div>
         <article className="text-left">
            <h2>{n.name}</h2>
            <h4>{new Intl.NumberFormat().format(n.price)}/<span className="date">{n.service_type}</span></h4>
         </article>
         <img src={n.service_image ? n.service_image : serviceImg} alt=""/>
      </div>
          </Col>
          ))}
        </Row>
         :
         ''
         }
         {this.state.providerServicesData.prev_page_url == null && this.state.providerServicesData.next_page_url == null?
         ''
         :
          <Pagination className='d-flex justify-content-center'>
            <Pagination.First disabled={this.state.currentPage == 1 ? true : false} onClick={this.getFirst}/>
            <Pagination.Prev disabled={this.state.providerServicesData.prev_page_url == null ? true : false} onClick={this.getPrev}/>
            {items}
            <Pagination.Next  disabled={this.state.providerServicesData.next_page_url == null ? true : false} onClick={this.getNext}/>
            <Pagination.Last disabled={this.state.currentPage == this.state.providerServicesData.last_page ? true : false} onClick={this.getLast}/>
            </Pagination>
         }
        </div> */}
        {this.state.reviews.length > 0 ?
         <div className='bg-white'>
         <div className='local-review'>
             <h2>{this.state.provider.review[0].total_no_of_reviews} Reviews</h2>
             {this.state.provider.review_message.map((n)=>(
 <Row className='mt-3'>
 <Col md={5} lg={5} xs={7}>
     <div className='d-flex'>
         <img src={n.user[0] ? n.user[0].profile_image != null ? n.user[0].profile_image : user : user} className='review-avatar' alt='review avatr'/>
         <div className='d-flex flex-column'>
             <div className='text-dark'><strong>{n.user[0] ? n.user[0].name : 'Unknown'}</strong> 
             {/* as a traveller */}
             </div>
             <span className='text-secondary'>{n.reviews}</span>
         </div>
     </div>
 </Col>
 <Col md={7} lg={7} xs={5}>
 <DynamicStar rating={n.no_of_stars} outlined={true} width='25'
        height='25'/>
 </Col>
</Row>
             ))}
         </div>
         </div>
         :
         ''
        }
     <Footer/>
      {/* contact modal */}
         <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.contactModal} onHide={() => this.setState({ contactModal: false})} className="signup-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>Contact Provider</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    {/* <Form.Control type="text" placeholder="Your Message" onChange={(e) => this.setState({contactText: e.target.value})}/> */}
    <Form.Control type="text" placeholder="Your Message" onChange={(e) => this.setConatctText(e)}/>
    {this.state.contactTextInvalid ? <span className='text-danger'>Invalid text entered..</span> : ''}
    {this.state.errorMsg ? <span className='text-danger'>{this.state.errorMsgTxt }</span> : ''}
    <button className={this.state.isloading || this.state.contactTextInvalid ? 'float-right continue-btn opacity-half' : 'float-right continue-btn'} onClick={this.contactProvider} disabled={this.state.isloading || this.state.contactTextInvalid ? true : false}>
      {this.state.isloading ? 
       <>
       &nbsp;&nbsp;
       <Spinner animation="border" variant="light" size="sm"/> 
       &nbsp;&nbsp;
       </> 
       :
        <>&nbsp;&nbsp;&nbsp;<RiSendPlane2Fill/>&nbsp;&nbsp;&nbsp;</>
      }
    </button>
    {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
      </Modal.Body>
    </Modal>
      {/* make a note modal */}
         <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.noteModal} onHide={() => this.setState({ noteModal: false})} className="signup-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>Make a note</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    {/* <Form.Control type="text" placeholder="Your Message" onChange={(e) => this.setState({contactText: e.target.value})}/> */}
    <Form.Control type="text" placeholder="Type your note" onChange={(e) => this.setState({noteText:e.target.value})}/>
    {this.state.errorMsgTxt ? <span className='text-danger'>{this.state.errorMsgTxt }</span> : ''}
    <button className={this.state.isloading ? 'float-right continue-btn w-100 opacity-half' : 'float-right w-100 continue-btn'} onClick={this.makeFavNote} disabled={this.state.isloading ? true : false}>
      {this.state.isloading ? 
       <>
       &nbsp;&nbsp;
       <Spinner animation="border" variant="light" size="sm"/> 
       &nbsp;&nbsp;
       </> 
       :
        <>Save Note</>
      }
    </button>
    {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
      </Modal.Body>
    </Modal>
      {/* book slot modal */}
         <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.slotModal} 
      onHide={() => this.setState({ slotModal: false})} 
      className="signup-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>Book a Slot</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4} lg={4} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Place <span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Where Next?" value={this.state.place} disabled/>
                  {this.state.placeError ? <span className='text-danger'>{this.state.placeError}</span> : ''}
                </Form.Group>
          </Col>
          <Col md={4} lg={4} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Price<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="number" placeholder="1200" value={this.state.price} disabled />
                  {this.state.priceError ? <span className='text-danger'>{this.state.priceError}</span> : ''}
                </Form.Group>
          </Col>
          <Col md={4} lg={4} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Number of people<span className='text-danger'>*</span></Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => this.setState({peopleNum: e.target.value, price:this.state.provider_rate * e.target.value})}>
                      <option selected disabled>Select number of people</option>
                      <option value="1">Just me</option>
                      <option value="2">Two People</option>
                      <option value="3">Three People</option>
                      <option value="4">Four People</option>
                  </Form.Select>
                  {this.state.peopleNumError ? <span className='text-danger'>{this.state.peopleNumError}</span> : ''}
                </Form.Group>
          </Col>
        </Row>
        <Row>
              <Col md={12} lg={12} sm={12}>
                  <Form.Label className='float-left'>Select a Booking Slot<span className='text-danger'>*</span></Form.Label>
              </Col>
        </Row>
        <Row>
              <Col md={8} lg={8} sm={12}>
                  <Calendar onChange={(slotDate) => this.setState({ slotDate: moment(slotDate).format('YYYY-MM-DD'), slotDay: moment(slotDate).format('dddd'), slotDayDate: slotDate })} value={this.state.slotDate} />                
              </Col>
              <Col md={4} lg={4} sm={12}>
                <h6 className='text-center'>{this.state.slotDayDate.toDateString()}</h6>
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  {this.state.slotDay === 'Monday' ? 
                  this.state.providerSlots.Monday ?
                  this.state.providerSlots.Monday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})}/>
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
                  {this.state.slotDay === 'Tuesday' ? 
                  this.state.providerSlots.Tuesday?
                  this.state.providerSlots.Tuesday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})}/>
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
                  {this.state.slotDay === 'Wednesday' ? 
                  this.state.providerSlots.Wednesday?
                  this.state.providerSlots.Wednesday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})}/>
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
                  {this.state.slotDay === 'Thursday' ? 
                  this.state.providerSlots.Thursday?
                  this.state.providerSlots.Thursday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})}/>
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
                  {this.state.slotDay === 'Friday' ? 
                  this.state.providerSlots.Friday ?
                  this.state.providerSlots.Friday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})}/>
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
                  {this.state.slotDay === 'Saturday' ? 
                  this.state.providerSlots.Saturday ?
                  this.state.providerSlots.Saturday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})}/>
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
                  {this.state.slotDay === 'Sunday' ? 
                  this.state.providerSlots.Sunday ? 
                  this.state.providerSlots.Sunday.map((n)=>(
                <div class="slot-button">
  <input type="radio" name="check-substitution-2" id={n.id} onChange={(e)=>this.setState({slotID:e.currentTarget.id})} />
  <label class="btn btn-default" for={n.id}>{moment(n.time_from, "HH:mm").format("hh:mm A")} to {moment(n.time_to, "HH:mm").format("hh:mm A")}</label>
</div> 
                  ))
                  : 'No slot for this Date'
                  :''}
               
                </div>
              </Col>
        </Row>
        {this.state.slotError ? <span className='text-danger'>{this.state.slotError}</span> :''}
        {/* <Row>
          <Col md={6} lg={6} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Start Date<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="date" onChange={(e) => this.setState({startDate: e.target.value})}/>
                  {this.state.startDateError ? <span className='text-danger'>{this.state.startDateError}</span> : ''}
                </Form.Group>
          </Col>
          <Col md={6} lg={6} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>End Date<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="date" onChange={(e) => this.setState({endDate: e.target.value})} />
                  {this.state.endDateError ? <span className='text-danger'>{this.state.endDateError}</span> : ''}
                </Form.Group>
          </Col>
        </Row> */}
         {/*<Row>
          {this.state.servicesDropdown ?
          this.state.servicesDropdown.length > 0 ?
          <Col md={6} lg={6} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Service<span className='text-danger'>*</span></Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => this.setState({service_id: e.target.value})}>
                      <option>Select Service </option>
                      {this.state.servicesDropdown ? 
                      this.state.servicesDropdown.map((n)=>(
                        <option value={n.id}>{n.name}</option>
                      )):''}
                  </Form.Select>
                  {this.state.service_idError ? <span className='text-danger'>{this.state.service_idError}</span> : ''}
                </Form.Group>
          </Col>
          :''
          :''} 
        </Row>*/}
        {/* <Row>
        <Col md={6} lg={6} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1" className='mt-1'>
                    <Form.Label className='float-left'>Choose Date & Time<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="datetime-local" max="9999-12-31T23:59" min={this.state.todayDate} onChange={(e) => this.setState({time: e.target.value})} />
                  {this.state.timeError ? <span className='text-danger'>{this.state.timeError}</span> : ''}
                </Form.Group>
          </Col>
        </Row> */}
        {/* <Row>
        <Col md={12} lg={12} xs={12}>
       <div>
                      <label className='form-label'>Choose Date & Time<span className='text-danger'>*</span></label>
    <div style={{overflowX:'auto', overflowY:'hidden'}}>
  <div class="days">
  <div class="day">
    <div class="datelabel"><strong>Friday</strong><br/>August 23</div>
    <div class="timeslot"><input type="radio" name="example" />&nbsp;9:00am</div>
    <div class="timeslot"><input type="radio" name="example" />&nbsp;9:30am</div>
    <div class="timeslot"><input type="radio" name="example" />&nbsp;10:30am</div>
  </div>
  <div class="day">
    <div class="datelabel"><strong>Saturday</strong><br/>August 24</div>
    <div class="timeslot"><input type="radio" name="example" />&nbsp;10:30am</div>
  </div>  
  <div class="day">
    <div class="datelabel"><strong>Sunday</strong><br/>August 25</div>
    <div class="timeslot"><input type="radio" name="example" />&nbsp;10:30am</div>
  </div>
  <div class="day">
    <div class="datelabel"><strong>Monday</strong><br/>August 26</div>
    <div class="timeslot"><input type="radio" name="example" />&nbsp;10:30am</div>
  </div>
  <div class="day">
    <div class="datelabel"><strong>Tuesday</strong><br/>August 27</div>
    <div class="timeslot"><input type="radio" name="example"/>&nbsp;10:30am</div>
  </div>
  </div>
</div>
  </div>
          </Col>
        </Row> */}
 
                    
          
                    
                   {/* {this.state.servicesDropdown.length > 0 ? '' :
                   <span className='text-danger'>You can't book a slot because Provider is not providing any service yet</span>} */}
    {/* <button className='float-right continue-btn' onClick={this.bookSlot}>
      {this.state.isloading ? 
       <>
       &nbsp;&nbsp;
       <Spinner animation="border" variant="light" size="sm"/> 
       &nbsp;&nbsp;
       </> 
       :
       <> &nbsp;&nbsp; <span>Book a Slot</span> &nbsp;&nbsp;</>
      }
    </button> */}
               {/* payment methods  */}
    {this.state.allFieldsError ? <span className='mt-3 text-danger float-left'>{this.state.allFieldsError}</span>: '' }
    {this.state.errorAlert ? <span className='mt-3 text-danger float-left'>{this.state.errorMsg}</span>: '' }
      </Modal.Body>
      <Modal.Footer className='row d-flex justify-content-between align-items-center'>
      <Col>
      {/* <Paypal/> */}
      {/* <Button onClick={()=>this.setState({paypalModal:true, slotModal:false,paymet_type:1})} className='w-100 text-white paypal-btn'> */}
      <Button onClick={()=>this.setState({allFieldsError:''},this.checkAllFields)} className='w-100 text-white paypal-btn'>
        {this.state.paypal_loader ? <Spinner animation="border" variant="light" size="sm"/> :
        <><BsPaypal/> Pay with Paypal</>}</Button>
      </Col>
      <Col>
<StripeCheckout
              className={(this.state.place && this.state.price && this.state.peopleNum && this.state.slotDate && this.state.slotID) ? 'mt-3 cursor-pointer' : 'mt-3'}
              name="Service Price"
              currency='usd'
              amount={this.state.price * 100}
              token={this.onToken}
              disabled={(this.state.place && this.state.price && this.state.peopleNum && this.state.slotDate && this.state.slotID) ? false : true}
              stripeKey="pk_test_51N4I0JI8Lg26wtAaA72JwsdGpNIGIwufhfuoCPPv2SrbiXcHpCuEL9HZF7GkrFXfCLZZMtHAhlNCC7GQEEk7JdoR00Q2JebyJZ"
            >
              <Button className='w-100 stripe-btn' disabled={(this.state.place && this.state.price && this.state.peopleNum && this.state.slotDate && this.state.slotID) ? false : true}
              >
                {this.state.stripe_loader ? <Spinner animation="border" variant="light" size="sm"/> :
                <>
                <SiStripe/> Pay with Stripe
                </> 
                }
                </Button>
            </StripeCheckout>
            </Col>
    </Modal.Footer>
    </Modal>
     
      {/* paypal modal */}
      <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.paypalModal} onHide={() => this.setState({ paypalModal: false})} className="signup-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>PayPal Payment</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <PayPalScriptProvider>
            <PayPalButtons style={{ layout: "vertical" }} createOrder={this.createOrder}
                onApprove={this.onApprove}/>
        </PayPalScriptProvider>
      </Modal.Body>
    </Modal>
    {
  this.state.success ? 
  <Alert key='success' variant='success' className='log-alert'>
{this.state.successMsg}
</Alert>
:
  ''
    }
     </div>
    )
  }
}
export default withRouter(Provider);
