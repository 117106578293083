import React, { Component } from 'react'
import Banner from '../Components/Banner';
import Navbar from "../Components/NavbarHome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../Components/Footer";
import { Link , Redirect} from "react-router-dom";
import { api_url } from '../Variables';
import "../Assets/Styles/style.css";
//images
import serviceImg from '../Assets/Images/serviceImage.jpg';
export default class Search extends Component {
  constructor(props){
    super(props);
    this.state={
      searchResult:[],
      noResult:'',
      isloading: true,
      redirect: null,
    }
  }
  componentDidMount(){
  //   const getSearchResult = JSON.parse(localStorage.getItem("search-result"));
  //   if(getSearchResult){
  //   this.setState({noResult: getSearchResult.Result, searchResult: getSearchResult, isloading: false}, ()=>{
  //     console.log(this.state.noResult);
  //   });
  // }else{
  //   this.setState({redirect:'/'})
  // }
  this.search()
  }
  search = async()=>{
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var formdata = new FormData();
formdata.append("name", '');
formdata.append("category_id", '');
formdata.append("max_price", '');

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/search_service", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({noResult: result.Result, searchResult: result, isloading: false})
  })
  .catch(error => console.log('error', error));
    // const name= this.state.name;
    // const max_price= this.state.price;
    // const category_id = this.state.category;
    // let item ={name, max_price,  category_id};
    //   let result =  await fetch(api_url+"api/search_service", {
    //     method: "post",
    //     headers : {
    //          'Accept': 'application/json',
    //      },
    //      body: JSON.stringify(item)
    // });
    //   result = await result.json();
    //   console.warn('result is', result);
    //   localStorage.setItem('search-result', JSON.stringify(result));
    //   const queryString = window.location.href; //get url
    //   const search = '/search'
    //   const onSearchPage = queryString.includes(search); // check if user is on search page
    //   if(onSearchPage){ // if yes
    //     this.setState({ redirect: null });  // no redirect 
    //   }else{
    //     this.setState({ redirect: "/search" }); //redirect
    //   }
    
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
        <>
          <Navbar />
          <Banner/>
          <div className="content">
          {/* fetched data */}
          <Row>
            {this.state.isloading ? 
              <center><div class="loader"></div></center>
              :
            this.state.searchResult.length > 0 ?
            this.state.searchResult.map((n)=>(
              <Col md={4} lg={4} xs={12}>
                   <div class="container_foto ">
         <div class="ver_mas text-center">
         {/* <Link to={{pathname: "/mytrips/"+ n.id+"/"+n.provider_id, state : {service_id: n.id, provider_id: n.provider_id}}}><button>Book a service</button></Link> */}
         <Link to={{pathname: "/service/"+ n.id+"/"+n.provider_id, state : {service_id: n.id, provider_id: n.provider_id}}}><button>View service</button></Link>
         </div>
         <article class="text-left">
            <h2>{n.name} 
            {/* <br/>LA IMAGEN */}
            </h2>
            <h4>{new Intl.NumberFormat().format(n.price)}/<span class="date">{n.service_type}</span></h4>
         </article>
         <img src={n.service_image ? n.service_image : serviceImg} alt=""/>
      </div>
            </Col>
            ))
          :
          <center>{this.state.noResult}</center>
          }
            
          </Row>
          </div>
          <Footer/>
        </>
    )
  }
}
