import React, { Component } from 'react';
import NavbarHome from "../Components/NavbarHome";
import Footer from '../Components/Footer';

class BookingAdvice extends Component {
    render() {
        return (
            <div>
                <NavbarHome/>
                <div className="body-bg">
                    <div className="content">
                        <h3>Booking Advice</h3>
                        <p>To make sure you have the best possible experience while traveling, get started by reading these simple tips about setting up your tours and interacting with your future hosts.</p>
                        <h5 className='text-capitalize'>INCREASE YOUR OPTIONS</h5>
                        <p>Increase your chance to choose the best tour for you by sending booking requests to multiple hosts at once. You will have the opportunity to choose an offer that is the best fit and value for your needs. It is also important to realize that not all hosts are available for the selected dates and hours you may want.</p>
                        <h5 className='text-capitalize'>INTRODUCE YOURSELF</h5>
                        <p>Give your local host information about yourself and be open regarding your expectations and the purpose of your visit so they can best respond to your requests</p>
                        <h5 className='text-capitalize'>GET TO KNOW A LOCAL</h5>
                        <p>Learn more about the local and the services he or she provides. Read their profiles and feel free to ask for any additional information.</p>
                        <h5 className='text-capitalize'>PREP EVERYTHING</h5>
                        <p>Make sure to discuss all the details of the tour with your local host in advance. Knowing the tour duration, booking hours, tour routes, places to visit, timing and possible meeting points will save time and make things run more smoothly.</p>
                        <h5 className='text-capitalize'>INDIVIDUAL OR A GROUP</h5>
                        <p>Let your host know if you plan to come by yourself or in a group. There are no extra fees for extra people, but it is up to the local host to decide if he or she is okay with the number of guests that will be on the tour.</p>
                        <h5 className='text-capitalize'>WHAT'S NOT INCLUDED</h5>
                        <p>Bear in mind that any extra expenses such as transportation, entrance fees, or any third party services you decide to acquire have to be paid by you and are not included in the local's hourly rate.</p>

                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default BookingAdvice;