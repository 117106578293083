import React, { Component } from 'react'
import { authentication } from '../firebase-config';
import OtpInput from 'react-otp-input';
import { RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import {Navbar,Nav,NavDropdown,Modal,Form,Alert,Spinner,Button, FormGroup } from 'react-bootstrap';
import { Link , Redirect} from 'react-router-dom';
import '../Assets/Styles/navbar.css';
import  FacebookLogin from 'react-facebook-login';
import {GoogleLogin} from 'react-google-login';
import {gapi} from 'gapi-script';
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';
import { RiEyeLine , RiEyeOffLine } from "react-icons/ri";
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import 'react-phone-number-input/style.css'
import PhoneInput,{ formatPhoneNumber, formatPhoneNumberIntl,withCountryCallingCode, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
//images
import logo from '../Assets/Images/dinfoo logo.png'
import user from '../Assets/Images/user.png'
import { api_url } from "../Variables";
import { Label } from 'reactstrap';
// const navigate = useNavigate();
export default class NavbarHome extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showRegister : false,
      showLogin : false,
      verifyOTP:false,
      resend: false,
      resendMsg:'',
      showResendMsg: false,
      otpValue:'',
      enterOTPMsg:'',
      registerMsg:'',
      loginMsg:'',
      fbloggedin : false,
      fbName:'',
      fbPic:'',
      fbEmail:'',
      googleloggedin : false,
      isloggedIn: false,
      googleName:'',
      googlePic:'',
      googleEmail:'',
      loginAlert: false,
      registerAlert: false,
      errorAlert: false,
      logoutAlert: false,
      errorMsg:'',
      userName:'',
      userNameError:'',
      userEmail: '',
      userPassword: '',
      userTypeError: '',
      profileName: '',
      profileImg: [],
      // userType: [],
      userType: 'seeker',
      userRole:'',
      loginContent: false,
      registerContent: true,
      loginPassword: '',
      token:'',
      notValidEmail:false,
      errorVerifyMsg:'',
      errorVerify:false,
      passwordStrength:'',
      passwordStrengthError:false,
      isloading: false,
      isResend: false,
      showPassword: false,
      forgetModal:false,
      forgetEmailText:true,
      forgetCodeText:false,
      forgetEmail:'',
      forgetEmailError:'',
      forgetEmailCode:'',
      resetPasswordText:false,
      ForgotVerify: false, 
      forgotVerifyMsg:'',
      newPassword:'',
      confirmNewPassword:'',
      showConfirmPassword:false,
      resetPasswordError:'',
      resetSuccess:false,
      resetSuccessMsg:'',
      passwordError:'',
      seeker_provider: localStorage.getItem('seekerORprovider') || 'seeker',
      seller_type:'pro',
      phone_no: 0,
      showmblOTPModal:false,
      mblOTP:'',
      showCaptcha:false,
      showResendCaptcha:false,
      errorVerifymblOTP:false,
      mblVerify:false,
      resendMblOTP:false,
      isResendMbl:false,
      showMblResendMsg:false,
      waitforRegister:false,
      showEmailInput:true,
      showMblInput:false,
      mblToken:'',
      registerMblError:false,
      registerMblErrorMsg:'',
      proModal:false,
      experience:'',
      experienceError:'',
      officialWeb:'',
      officialWebError:'',
      validWebUrl:'',
      license:'',
      licenseError:'',
      successPro:false,
      successProMsg:'',
      isPro:false,
      fbToken:'',
      googleToken:'',
      fbID:'',
      fbResponse:'',
      googleID:'',
      googleLoader:false,
      fbLoader:false,
      googleResponse:'',
      redirect: null,

    };
  }
  componentDidMount = async()=>{
    window.addEventListener("storage",(e) => {
      const role = (localStorage.getItem("seekerORprovider"));
      this.setState({seeker_provider: role},()=>{
        this.getSavedDetails();
      })
      //if logged in from footer through facebook
      // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
      // if(fbLogIn){
      //   this.setState({fbloggedin:true, fbName: fbLogIn.name, fbPic: fbLogIn.picture.data.url, fbEmail:fbLogIn.email})
      // }
      });
     //on component reload 
        //if logged in from footer through facebook
        // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
        // if(fbLogIn){
        //   this.setState({fbloggedin:true, fbName: fbLogIn.name, fbPic: fbLogIn.picture.data.url, fbEmail:fbLogIn.email})
        // }
        //if logged in from footer through Google
        // const googleLogIn = (JSON.parse(localStorage.getItem('googleLogIn')));
        // if(googleLogIn){
        //   this.setState({googleloggedin: true, googleName: googleLogIn.profileObj.name, googlePic: googleLogIn.profileObj.imageUrl, googleEmail: googleLogIn.profileObj.email})
        // }
        const role = (localStorage.getItem("seekerORprovider"));
        this.setState({seeker_provider: role},()=>{
          this.getSavedDetails();
        })
    function start(){
      gapi.client.init({
        clientId: '589443009267-asnlo3f7g9qfjvjhaeoutg27bpt8kobo.apps.googleusercontent.com',
        scope:""
      })
    };
    gapi.load('client:auth2', start);
    setTimeout(() => {
      this.logout();
    }, 3600000); 
  }
  getSavedDetails = async()=>{
    const token = JSON.parse(localStorage.getItem("token"));
    if(token){
    this.setState({token: token.token});
      this.setState({isloggedIn: true});
      const userDetail = JSON.parse(localStorage.getItem("user-info"));
      if(userDetail){
        console.log('logged in details: ', userDetail)
        this.setState({profileName: userDetail.profile.name, 
          profileImg: userDetail.profile_images[0], userRole: userDetail.profile.role_id == 1 ? 'admin' : userDetail.profile.role_id == 2 ? 'provider': userDetail.profile.role_id == 3 ? 'seeker' : userDetail.profile.role_id == 4 ? 'both' : '',
        isPro:userDetail.profile.is_pro === 'Yes' ? false : true},
          ()=>{
            // if(this.state.userRole === 'both'){
            //   const seekerORprovider = localStorage.getItem('seekerORprovider');
            //   if(seekerORprovider){
            //     this.setState({seeker_provider:seekerORprovider},()=>{
            //       console.log('user is',this.state.seeker_provider)
            //     })
            //   }else{
            //     this.setState({seeker_provider:'seeker'},()=>{
            //       console.log('user is',this.state.seeker_provider)
            //     })
            //   }
            // }
          }
          );
      }
    }
    // else if(this.state.fbloggedin){
    //   const seekerORprovider = localStorage.getItem('seekerORprovider');
    //   if(seekerORprovider){
    //     this.setState({seeker_provider:seekerORprovider},()=>{
    //       console.log('user is',this.state.seeker_provider)
    //     })
    //   }
    // }
    // else if(this.state.googleloggedin){
    //   const seekerORprovider = localStorage.getItem('seekerORprovider');
    //   if(seekerORprovider){
    //     this.setState({seeker_provider:seekerORprovider},()=>{
    //       console.log('user is',this.state.seeker_provider)
    //     })
    //   }
    // }
    else{
      console.log('user is not logged in')
    }
  }
  logout =() =>{
    localStorage.removeItem('token');
    localStorage.removeItem('user-info');
    localStorage.removeItem('seekerORprovider');
    window.dispatchEvent(new Event("storage"));
    if(window.location.href === 'http://localhost:3000/' || window.location.href === 'https://dinfoo.com/'){
      this.setState({isloggedIn: false, logoutAlert: true, isPro:false});
    }else{
      this.setState({isloggedIn: false, logoutAlert: true, isPro:false, redirect: "/" });
    }
    setTimeout(() => {
      this.setState({logoutAlert: false});
    }, 2000);
  }
  register = async() => {
    this.setState({errorVerify: false, errorVerifyMsg: '',errorAlert: false, errorMsg: '', passwordStrengthError:false});
    var validRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!this.state.userEmail || validRegex.test(this.state.userEmail) === false) {
      this.setState({notValidEmail: true});
    }else{
      this.setState({notValidEmail: false});
    }
    if(this.state.passwordStrength.score <=1){
      this.setState({passwordStrengthError:true}); //password is weak
    }else{
      this.setState({passwordStrengthError: false}); //password is ok
    }
    if(this.state.userName){
      this.setState({userNameError:''})
    }else{
      this.setState({userNameError: 'Name field is required'})
    }
    // if(this.state.userType.length > 0){
    //   this.setState({userTypeError:''})
    // }else{
    //   this.setState({userTypeError: 'Please select one',isloading:false})
    // }
    // if(this.state.notValidEmail === false && this.state.passwordStrengthError === false && !this.state.userNameError && !this.state.userTypeError){
    if(this.state.notValidEmail === false || this.state.passwordStrength.score >1 || !this.state.userNameError){
      ////
      this.setState({isloading:true})
      var myHeaders = new Headers();
      myHeaders.append("Accept", "*/*");
      var formdata = new FormData();
      formdata.append("name", this.state.userName);
      formdata.append("email", this.state.userEmail);
      formdata.append("password", this.state.userPassword);
      formdata.append("seller_type", this.state.seller_type);
        formdata.append("user_type[]", this.state.userType);
      // const user_type_length = this.state.userType.length;
      // if(user_type_length > 0){
      // for (var i=0; i < user_type_length; i += 1) {
      //   formdata.append("user_type[]", this.state.userType[i]);
      //  }
      // }
      var requestOptions = {
        method: 'POST',
        headers:myHeaders,
        body: formdata
      };

      fetch(api_url+"api/register", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if(result.error || result.status === "Error"){
          this.setState({errorAlert: true, errorMsg: result.message , isloading: false});
        }
        else{
          this.setState({showRegister:false, verifyOTP:true, enterOTPMsg:result.message, isloading: false});
          setTimeout(() => {
            this.setState({resend: true});
          }, 5000);
        }
        this.setState({isloading:false,waitforRegister:false})
      })
      .catch(error => {
        console.log('error', error)
        this.setState({isloading:false})
      });
    }else{
      
      this.setState({isloading:false})
    }
    }
  registerWithMbl = async() => {
    this.setState({isloading:true})
    this.setState({errorVerify: false, errorVerifyMsg: '',errorAlert: false, errorMsg: '',passwordStrengthError:false});
    if(this.state.passwordStrength.score <=1){
      this.setState({passwordStrengthError:true}); //password is weak
    }else{
      this.setState({passwordStrengthError: false}); //password is ok
    }
    // if(this.state.passwordStrengthError === false && this.state.userName && this.state.userType && this.state.phone_no){
    if(this.state.passwordStrength.score >1 && this.state.userName && this.state.phone_no){
      ////
      var myHeaders = new Headers();
      myHeaders.append("Accept", "*/*");
      var formdata = new FormData();
      formdata.append("name", this.state.userName);
      formdata.append("user_contact_number", this.state.phone_no);
      formdata.append("firebase_token", this.state.mblToken);
      formdata.append("password", this.state.userPassword);
      formdata.append("seller_type", this.state.seller_type);
        formdata.append("user_type[]", this.state.userType);
      // const user_type_length = this.state.userType.length;
      // if(user_type_length > 0){
      // for (var i=0; i < user_type_length; i += 1) {
      //   formdata.append("user_type[]", this.state.userType[i]);
      //  }
      // }
      var requestOptions = {
        method: 'POST',
        headers:myHeaders,
        body: formdata
      };

      fetch(api_url+"api/register", requestOptions)
      .then(response => response.json())
      .then( async(result)=> {
        console.log(result)
        if(result.error || result.status === "Error"){
          this.setState({registerMblError: true, registerMblErrorMsg: result.message , isloading: false});
          setTimeout(() => {
            this.setState({registerMblError: false, registerMblErrorMsg: ''});
          }, 2000);
        }
       else{
          if(result.token){
            localStorage.setItem("token",JSON.stringify(result));
            setTimeout(()=>{
              this.logout();
            },3600000)
            this.setState({isloggedIn:true, showRegister:false , registerAlert: true, registerMsg:result.message, isloading: false});
            setTimeout(() => {
              this.setState({registerAlert: false});
            }, 2000);
            const token=result.token;
            let userDetails = await fetch(api_url+"api/dashboard/profile",{
              method: "get",
              headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json'
              }
            });
            userDetails = await userDetails.json();
            console.log('user details', userDetails);
            if(userDetails){
              localStorage.setItem("user-info", JSON.stringify(userDetails));
              this.setState({profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0]});
              this.componentDidMount();
            }
          }
        }
        this.setState({isloading:false,waitforRegister:false})
      })
      .catch(error => {
        console.log('error', error)
        this.setState({isloading:false})
      });
    }
    }
  login = async()=>{
    this.setState({errorVerify: false, errorVerifyMsg: '',errorAlert: false, errorMsg: '', isloading:true});
    //////
    var validRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!this.state.userEmail || validRegex.test(this.state.userEmail) === false) {
      this.setState({notValidEmail: true,isloading:false});
    }else{
      this.setState({notValidEmail: false});
    }
   if(this.state.notValidEmail === false){
    var formdata = new FormData();
    if(this.state.showEmailInput){
      formdata.append("email", this.state.userEmail);
    }
    if(this.state.showMblInput){
      formdata.append("user_contact_number", this.state.phone_no);
    }
    formdata.append("password", this.state.loginPassword);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url+"api/login", requestOptions)
    .then(response => response.json())
    .then(async (result) => {
      console.log(result) 
      this.setState({isloading:false})
      if(result.token){ 
        localStorage.setItem("token",JSON.stringify(result));
        window.dispatchEvent(new Event("storage"));
        setTimeout(()=>{
          this.logout();
        },3600000)
        this.setState({loginAlert: true, isloggedIn:true, showLogin: false, loginMsg:result.message, isloading: false});
        setTimeout(() => {
          this.setState({loginAlert: false});
        }, 2000);
        const token=result.token;
        let userDetails = await fetch(api_url+"api/dashboard/profile",{
          method: "get",
          headers: {
            "Authorization": `Bearer ${token}`,
            'Accept': 'application/json'
          }
        });
        userDetails = await userDetails.json();
        console.log('user details', userDetails);
        if(userDetails){
          localStorage.setItem("user-info", JSON.stringify(userDetails));
          this.setState({profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0]});
          this.componentDidMount();
        }
      }
      else if(result.message == 'Please verify your account first.'){
        this.setState({errorAlert: true, errorMsg: result.message, verifyOTP: true,showLogin:false, isloading: false});
        setTimeout(() => {
          this.setState({resend: true});
        }, 5000);
      }
      else{
        this.setState({errorAlert: true, errorMsg: result.message});
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isloading:false})
    });
   }else{
    this.setState({isloading:false})
   }
    
    //////
  //     const email = this.state.userEmail;
  //     const password = this.state.loginPassword;
  //     let item ={email, password};
  //     console.log(item);
  //     let result = await fetch(api_url+"api/login", {
  //     method: "post",
  //     headers : { 
  //       "Content-Type": "multipart/form-data; application/json",
  //       'Accept': 'application/json'
  //      },
  //  body:JSON.stringify(item)
  //   });
  //   result = await result.json();
  //   console.warn( result);
  //   if(result.token){
  //     localStorage.setItem("token",JSON.stringify(result));
  //     this.setState({loginAlert: true, isloggedIn:true, showLogin: false, loginMsg:result.message, isloading: false});
  //     setTimeout(() => {
  //       this.setState({loginAlert: false});
  //     }, 2000);
  //     const token=result.token;
  //     let userDetails = await fetch(api_url+"api/dashboard/profile",{
  //       method: "get",
  //       headers: {
  //         "Authorization": `Bearer ${token}`,
  //       'Accept': 'application/json'
  //       }
  //     });
  //     userDetails = await userDetails.json();
  //     console.log('user details', userDetails);
  //     if(userDetails){
  //       localStorage.setItem("user-info", JSON.stringify(userDetails));
  //       this.setState({profileName: userDetails.profile.first_name === null ? userDetails.profile.name : userDetails.profile.first_name + ' ' + userDetails.profile.last_name, profileImg: userDetails.profile_images[0]});
  //       this.componentDidMount();
  //     }
  //   }
  //   else if(result.message == 'Please verify your account first.'){
  //     this.setState({errorAlert: true, errorMsg: result.message, verifyOTP: true,showLogin:false, isloading: false});
  //     setTimeout(() => {
  //       this.setState({resend: true});
  //     }, 5000);
  //   }
  //   else{
  //     this.setState({errorAlert: true, errorMsg: result.message});
  //   }
  }
  getUSerDetails = async () =>{
    const getToken = JSON.parse(localStorage.getItem("token"));
    if(getToken){
      const token = getToken.token;
      console.log('user logged in')
      let userDetail = await fetch(api_url+"api/dashboard/profile",{
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      userDetail = await userDetail.json();
      if(userDetail){
        localStorage.setItem("user-info", JSON.stringify(userDetail));
        this.setState({profileName: userDetail.profile.name, profileImg: userDetail.profile_images[0]}, () => {
         console.log('user details', userDetail);
       });
      }
    }
    else{
      console.log('user is not logged in');
    }
  }
  verify = async()=>{
    this.setState({errorVerify: false, errorVerifyMsg: '',errorAlert: false, errorMsg: '',  isloading:true});
    var formdata = new FormData();
    formdata.append("email", this.state.userEmail);
    formdata.append("otp", this.state.otpValue);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    fetch(api_url+"api/verify", requestOptions)
    .then(response => response.json())
    .then(async(result) => {
      console.log(result)
      if(result.status === 'Success'){
        if(result.token){
          localStorage.setItem("token",JSON.stringify(result));
          window.dispatchEvent(new Event("storage"));
          setTimeout(()=>{
            this.logout();
          },3600000)
          this.setState({isloggedIn:true, verifyOTP:false, registerAlert: true, registerMsg:result.message, isloading: false});
          setTimeout(() => {
            this.setState({registerAlert: false});
          }, 2000);
          const token=result.token;
          let userDetails = await fetch(api_url+"api/dashboard/profile",{
            method: "get",
            headers: {
              "Authorization": `Bearer ${token}`,
              'Accept': 'application/json'
            }
          });
          userDetails = await userDetails.json();
          console.log('user details', userDetails);
          if(userDetails){
            localStorage.setItem("user-info", JSON.stringify(userDetails));
            this.setState({profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0]});
            this.componentDidMount();
          }
        }
        // this.setState({verifyOTP:false, registerAlert: true,registerMsg:result.message,showLogin:true, isloading: false});
        // setTimeout(() => {
        //   this.setState({registerAlert: false});
        // }, 2000);
      }else{
        this.setState({errorVerify: true, errorVerifyMsg: result.error, isloading: false});
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isloading:false})
    });
  }
  resendOTP = async()=>{
    this.setState({ isResend:true});
    /*const email = this.state.userEmail;
    let item ={email};
    console.log(item);
    let result = await fetch(api_url+"api/resend/otp", {
      method: "post",
      headers : { 
        "Content-Type": "multipart/form-data; application/json",
        'Accept': 'application/json'
      },
      body:JSON.stringify(item)
    });
    result = await result.json();
    console.warn( result);
    if(result.status === 'Success'){
      this.setState({showResendMsg: true,resendMsg:result.message, isResend: false});
      setTimeout(() => {
        this.setState({showResendMsg: false});
      }, 5000);
    }
    else{
      this.setState({errorVerify: true, errorVerifyMsg: result.error, verifyOTP:false, isResend: false});
    }*/

    var formdata = new FormData();
    formdata.append("email", this.state.userEmail);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    fetch(api_url+"api/resend/otp", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.status === 'Success'){
        this.setState({showResendMsg: true,resendMsg:result.message, isResend: false});
      setTimeout(() => {
        this.setState({showResendMsg: false});
      }, 5000);
      }else{
        this.setState({errorVerify: true, errorVerifyMsg: result.error, verifyOTP:false, isResend: false});
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isloading:false})
    });
  }
  resendOTPforForget = async()=>{
    this.setState({ isResend:true});
    var formdata = new FormData();
    formdata.append("email", this.state.forgetEmail);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    fetch(api_url+"api/resend/otp", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.status === 'Success'){
        this.setState({showResendMsg: true,resendMsg:result.message, isResend: false});
      setTimeout(() => {
        this.setState({showResendMsg: false});
      }, 5000);
      }else{
        this.setState({errorVerify: true, errorVerifyMsg: result.error, verifyOTP:false, isResend: false});
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isResend:false})
    });
  }
  forgetApi = async()=>{
    this.setState({isloading:true})
var formdata = new FormData();
formdata.append("email", this.state.forgetEmail);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/forgetApi", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({isloading:false});
    if(result.status === true){
      this.setState({forgetEmailText:false, forgetCodeText:true})
      setTimeout(() => {
        this.setState({resend:true})
      }, 7000);
    }
      if(result.email){
        this.setState({forgetEmailError:result.email})
      }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({isloading:false});
  });
  }
  verifyNewOTP = async()=>{
    this.setState({isloading:true,passwordStrengthError:false})
    var formdata = new FormData();
    formdata.append("email", this.state.forgetEmail);
    formdata.append("otp", this.state.forgetEmailCode);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    fetch(api_url+"api/verify", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      this.setState({isloading:false})
      if(result.status === 'Success'){
        this.setState({forgetCodeText:false, resetPasswordText:true,forgetEmailCode:''});
      }else{
        this.setState({ForgotVerify: true, forgotVerifyMsg: result.error, isloading: false});
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({isloading:false})
    });
  }
  resetPassword = async()=>{
    this.setState({isloading:true})
    if(this.state.passwordStrength.score <=1){
      this.setState({passwordStrengthError:true,isloading:false}); //password is weak
    }else{
      this.setState({passwordStrengthError: false});  //password is ok
    }
    if(this.state.passwordStrength.score >1){
    var formdata = new FormData();
formdata.append("email", this.state.forgetEmail);
formdata.append("password", this.state.newPassword);
formdata.append("password_confirmation", this.state.confirmNewPassword);

var requestOptions = {
  method: 'POST',
  body: formdata,
};

fetch(api_url+"api/password/reset", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({isloading:false})
    if(result.error){
      if(result.error.password){
        this.setState({passwordStrengthError:true})
      }else{
        this.setState({resetPasswordError: result.error})
      }
    }else{
      this.setState({resetPasswordText:false, forgetModal:false, resetSuccess:true, resetSuccessMsg:result.message })
      setTimeout(() => {
        this.setState({showLogin: true, resetSuccess: false, resetSuccessMsg:''});
      }, 2000);
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({isloading:false})
});
    }
    else{
      this.setState({isloading:false})
    }
  }
  setSeekerProvider =(e)=>{
    if (e.target.checked) {
      //append to array
      this.setState({
        userType: [...this.state.userType, e.target.value]
    });
} else { //remove from array
    this.setState({
      userType: this.state.userType.filter(element => element !== e.target.value)
    });
}
  }
  generateRecaptcha = ()=>{
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      // 'size': 'invisible',
      'callback': (response) => {
        this.setState({showRegister:false, showmblOTPModal:true,isloading:false})
        setTimeout(() => {
          this.setState({resendMblOTP:true})
        }, 5000);
      }
    }, authentication);
  }
  generateResendRecaptcha = ()=>{
    this.setState({isResendMbl:true})
    window.recaptchaVerifierResend = new RecaptchaVerifier('resend-repatcha', {
      // 'size': 'invisible',
      'callback': (response) => {
        this.setState({isResendMbl:false,showMblResendMsg:true,showResendCaptcha:false});
      setTimeout(() => {
        this.setState({showMblResendMsg:false});
      }, 2000);
      }
    }, authentication);
  }
  requestMblOTP = (e) =>{
    this.setState({isloading:true})
    if(this.state.passwordStrength.score <=1){
      this.setState({passwordStrengthError:true}); //password is weak
    }else{
      this.setState({passwordStrengthError: false}); //password is ok
    }
    if(this.state.userName){
      this.setState({userNameError:''})
    }else{
      this.setState({userNameError: 'Name field is required'})
    }
    // if(this.state.userType.length > 0){
    //   this.setState({userTypeError:''})
    // }else{
    //   this.setState({userTypeError: 'Please select one'})
    // }
    // if(this.state.phone_no && isValidPhoneNumber(this.state.phone_no) && this.state.passwordStrengthError === false && !this.state.userNameError &&  !this.state.userTypeError){
    if(this.state.phone_no && isValidPhoneNumber(this.state.phone_no) && this.state.passwordStrength.score >1 && !this.state.userNameError){
      ////
      this.generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, this.state.phone_no, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        this.setState({mblToken:confirmationResult.verificationId})
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        console.log(error)
        this.setState({showRegister:true,errorAlert:true,errorMsg:"Error!!! Please Try again",showmblOTPModal:false})
        setTimeout(() => {
          this.setState({errorAlert:false, errorMsg:''})
        }, 3000);

      });
      }
      else{
        console.log('all fields are required')
      }
  }
  requestAgainforMblOTP = () =>{
    this.generateResendRecaptcha()
    let appVerifierResend = window.recaptchaVerifierResend;
    signInWithPhoneNumber(authentication, this.state.phone_no, appVerifierResend)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      this.setState({mblToken:confirmationResult.verificationId})
      
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      console.log(error)
    });
  }
  verifyMblOTP = (e) =>{
      if(this.state.mblOTP.length === 6){
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(this.state.mblOTP).then((result) => {
          // mbl no verified successfully
          this.setState({mblVerify:true,showmblOTPModal:false});
          setTimeout(() => {
            this.setState({mblVerify:false,waitforRegister:true});
            this.registerWithMbl()
          }, 2000);
          // ...
        }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          this.setState({errorVerifymblOTP:true})
          console.log('Error',error)
        });
      }
  }
  urlPatternValidation = (URL) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL);
  };
  isValidUrl = (event) =>{
    this.setState({officialWeb:event.target.value},()=>{
      if(this.urlPatternValidation(this.state.officialWeb)){
        this.setState({validWebUrl:''})
      }else{
        this.setState({validWebUrl:'Please enter valid URL'})
      }
    })
  }
  // upgradePro =async()=>{
  //   this.setState({isloading:true})
  //   const getToken = JSON.parse(localStorage.getItem("token"));
  //   if(getToken){
  //   const token = getToken.token;
  //   if(this.state.experience){
  //     this.setState({experienceError:''});
  //   }else{
  //     this.setState({experienceError: 'The Experience field is required'});
  //   }
  //   if(this.state.officialWeb){
  //     this.setState({officialWebError:''})
  //   }else{
  //     this.setState({officialWebError: 'The official website field is required'})
  //   }
  //   if(this.state.license){
  //     this.setState({licenseError:''})
  //   }else{
  //     this.setState({licenseError: 'Please Upload license file!!'})
  //   }
  //   if(this.state.experienceError || this.state.officialWebError|| this.state.licenseError || this.state.validWebUrl){
  //     console.log('All field are required')
  //     this.setState({isloading:false})
  //   }else{
  //     ////
  //     var myHeaders = new Headers();
  //     myHeaders.append("Accept", "*/*");
  //     myHeaders.append("Authorization", `Bearer ${token}`);
  //     var formdata = new FormData();
  //     formdata.append("experience", this.state.experience);
  //     formdata.append("official_website", this.state.officialWeb);
  //     formdata.append("license", this.state.license);
  //     var requestOptions = {
  //       method: 'POST',
  //       headers:myHeaders,
  //       body: formdata
  //     };

  //     fetch(api_url+"api/send/proRequest", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log(result)
  //       if(result.error ){
  //         this.setState({errorAlert: true, errorMsg: result.message , isloading: false});
  //       }
  //       else{
  //         this.setState({isloading: false,proModal:false, successPro:true,successProMsg:result.Result});
  //         setTimeout(() => {
  //           this.setState({successPro:false,successProMsg:''})
  //         }, 3000);
  //         this.getUserDetails()
  //       }
  //       this.setState({isloading:false})
  //     })
  //     .catch(error => {
  //       console.log('error', error)
  //       this.setState({isloading:false})
  //     });
  //   }
  // }else{
  //   console.log('user is not logged in')
  // }
  // }
 getUserDetails = async()=>{
  const getToken = JSON.parse(localStorage.getItem("token"));
    if(getToken){
    const token = getToken.token;
  let userDetails = await fetch(api_url+"api/dashboard/profile",{
    method: "get",
    headers: {
      "Authorization": `Bearer ${token}`,
      'Accept': 'application/json'
    }
  });
  userDetails = await userDetails.json();
  console.log('user details', userDetails);
  if(userDetails){
    window.localStorage.setItem("user-info", JSON.stringify(userDetails));
    window.dispatchEvent(new Event("storage"));
    this.setState({profileName: userDetails.profile.name, 
    profileImg: userDetails.profile_images[0], userRole: userDetails.profile.role_id == 1 ? 'admin' : userDetails.profile.role_id == 2 ? 'provider': userDetails.profile.role_id == 3 ? 'seeker' : userDetails.profile.role_id == 4 ? 'both' : '',
  isPro:userDetails.profile.is_pro === 'Yes' ? false : true});
    this.componentDidMount();
  }
}
 }
 getTokenForFB = async() =>{
  var formdata = new FormData();
  formdata.append("name", this.state.fbName);
  formdata.append("access_token", this.state.fbToken);
  formdata.append("facebook_id", this.state.fbID);
  formdata.append("email", this.state.fbEmail);
  formdata.append("profile_image_url", this.state.fbPic);
  formdata.append("userRole",'seeker');
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"api/facebook_login", requestOptions)
    .then(response => response.json())
    .then(async (result) => {
      console.log(result)
      if(result.error){

      }else if(result.token){
        localStorage.setItem("token",JSON.stringify(result));
        // window.localStorage.setItem("seekerORprovider", 'seeker');
        window.dispatchEvent(new Event("storage"));
        // this.setState({fbloggedin:true, showRegister: false,showLogin:false, loginAlert: true,loginMsg:result.message,fbLoader:false,fbName:this.state.fbResponse.name,fbPic: this.state.fbResponse.picture.data.url, fbEmail:this.state.fbResponse.email, });
        this.setState({isloggedIn:true, showRegister: false,showLogin:false, loginAlert: true,loginMsg:result.message,fbLoader:false });
        setTimeout(() => {
          this.setState({loginAlert: false,loginMsg:''});
        }, 3000);
        const token=result.token;
        let userDetails = await fetch(api_url+"api/dashboard/profile",{
          method: "get",
          headers: {
            "Authorization": `Bearer ${token}`,
            'Accept': 'application/json'
          }
        });
        userDetails = await userDetails.json();
        console.log('user details', userDetails);
        if(userDetails){
          localStorage.setItem("user-info", JSON.stringify(userDetails));
          this.setState({profileName: userDetails.profile.name , 
          profileImg: userDetails.profile_images[0], userRole: userDetails.profile.role_id == 1 ? 'admin' : userDetails.profile.role_id == 2 ? 'provider': userDetails.profile.role_id == 3 ? 'seeker' : userDetails.profile.role_id == 4 ? 'both' : '',
        isPro:userDetails.profile.is_pro === 'Yes' ? false : true});
        }
      }else{

      }
    })
    .catch(error => console.log('error', error));
 }
 validateFbToken = async()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("https://graph.facebook.com/me?access_token="+this.state.fbToken, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.error){
        this.setState({errorAlert:true,errorMsg:result.error.message})
      }else{
        this.getTokenForFB();
      }
    })
    .catch(error => console.log('error', error));
 }
 getTokenForGoogle = async()=>{
  var formdata = new FormData();
formdata.append("name", this.state.googleName);
formdata.append("access_token", this.state.googleToken);
formdata.append("google_id", this.state.googleID);
formdata.append("email", this.state.googleEmail);
formdata.append("imageurl", this.state.googlePic);
formdata.append("userRole",'seeker');

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/google_login", requestOptions)
  .then(response => response.json())
  .then(async (result) => {
    console.log(result)
    if(result.error){

    }else if(result.token){
      localStorage.setItem("token",JSON.stringify(result));
      // window.localStorage.setItem("seekerORprovider", 'seeker');
      window.dispatchEvent(new Event("storage"));
      this.setState({isloggedIn: true, showRegister: false, showLogin:false, loginAlert: true,loginMsg:result.message,googleLoader:false}, async ()=>{
        const token=result.token;
        let userDetails = await fetch(api_url+"api/dashboard/profile",{
          method: "get",
          headers: {
            "Authorization": `Bearer ${token}`,
            'Accept': 'application/json'
          }
        });
        userDetails = await userDetails.json();
        console.log('user details', userDetails);
        window.dispatchEvent(new Event("storage"));
        if(userDetails){
          localStorage.setItem("user-info", JSON.stringify(userDetails));
          this.setState({profileName: userDetails.profile.name, 
          profileImg: userDetails.profile_images[0], userRole: userDetails.profile.role_id == 1 ? 'admin' : userDetails.profile.role_id == 2 ? 'provider': userDetails.profile.role_id == 3 ? 'seeker' : userDetails.profile.role_id == 4 ? 'both' : '',
        isPro:userDetails.profile.is_pro === 'Yes' ? false : true});
        }
      });
      setTimeout(() => {
        this.setState({loginAlert: false,loginMsg:''});
      }, 3000);
     
    }else{

    }
  })
  .catch(error => console.log('error', error));
 }
 validateGoogleToken = async()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("https://www.googleapis.com/oauth2/v1/tokeninfo?access_token="+this.state.googleToken, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.error){
        this.setState({errorAlert:true,errorMsg:result.error})
      }else{
        this.getTokenForGoogle();
      }
    })
    .catch(error => console.log('error', error));
 }
  render() {
    const responseFacebook = (response) => {
      this.setState({fbLoader:true});
      if(response.accessToken){
        // this.setState({fbToken:response.accessToken,fbID:response.userID,fbResponse:response,fbName:response.name,},()=>{
        this.setState({fbToken:response.accessToken,fbID:response.userID,fbName:response.name,fbPic: response.picture.data.url, fbEmail:response.email},()=>{
          this.getTokenForFB();
          // window.localStorage.setItem("fbLogIn", JSON.stringify(response));
          
        })
      }
      else{
        this.setState({isloggedIn: false, fbName: '', fbPic: ''})
      }
      console.log(response);
      setTimeout(() => {
        this.setState({loginAlert: false,loginMsg:''});
      }, 3000);
    }
    const responseGoogleSuccess = (response) => {
      this.setState({googleLoader:true});
      if(response.accessToken){
        this.setState({googleToken:response.accessToken,googleID:response.googleId,googleName: response.profileObj.name, googlePic: response.profileObj.imageUrl, googleEmail: response.profileObj.email},()=>{
        // this.setState({googleToken:response.accessToken,googleID:response.googleId,googleName: response.profileObj.name},()=>{
          this.getTokenForGoogle()
        })
        // this.setState({googleloggedin: true, showRegister: false, showLogin:false, loginAlert: true,loginMsg:'Logged In Successfully', googleName: response.profileObj.name, googlePic: response.profileObj.imageUrl, googleEmail: response.profileObj.email});
        // window.localStorage.setItem("googleLogIn", JSON.stringify(response));
        
      }
      else{
        this.setState({isloggedIn: false, googleName: '', googlePic: ''})
      }
      console.log("login succes", response);
      setTimeout(() => {
        this.setState({loginAlert: false,loginMsg:'',});
      }, 3000);
    }
    const responseGoogleFailure = (response) => {
      console.log("Login failed" , response);
      this.setState({googleLoader:false})
    }
    const responseGoogleLogoutSuccess = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user-info');
      localStorage.removeItem('seekerORprovider');
      console.log("Logout success" );
      if(window.location.href === 'http://localhost:3000/' || window.location.href === 'https://dinfoo.com/'){
        this.setState({isloggedIn: false, googleName: '', googlePic: '',logoutAlert: true})
    }else{
      this.setState({isloggedIn: false, googleName: '', googlePic: '',logoutAlert: true,redirect: "/"})
    }
      
    setTimeout(() => {
      this.setState({logoutAlert: false});
    }, 2000);

    }
   
    const fbLogout=()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('fbLogIn');
        localStorage.removeItem('seekerORprovider');
        console.log("logout success");
        if(window.location.href === 'http://localhost:3000/' || window.location.href === 'https://dinfoo.com/'){
          this.setState({fbloggedin: false, fbName: '', fbPic: '',logoutAlert: true})
        }else{
          this.setState({fbloggedin: false, fbName: '', fbPic: '',logoutAlert: true,redirect: "/"})
        }
        setTimeout(() => {
          this.setState({logoutAlert: false});
        }, 2000);
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <>
      <Navbar collapseOnSelect expand="lg">
      <Navbar.Brand><Link to="/"><img src={logo} alt="logo" className='navbar-logo' /></Link></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">

      </Nav>
      <Nav>
      {/* <Nav.Link><Link to="/search"><strong className='text-secondary'></strong>Book a Service</Link></Nav.Link>
      <Nav.Link>Blog</Nav.Link>
      <Nav.Link><Link to="/about">About Us</Link></Nav.Link> */}
      {this.state.isloggedIn ? 
       <>
       {/* {(this.state.userRole === 'provider' || this.state.seeker_provider === 'provider') && this.state.isPro ? 
 
        <Nav.Link className='nav-login-btn btn mr-15' onClick={()=> this.setState({proModal:true})}>Upgrade to PRO</Nav.Link>
       :''} */}
       <div className='user-details'>
                 {/* <img src={this.state.fbloggedin ? this.state.fbPic : this.state.googleloggedin ? this.state.googlePic : this.state.profileImg === null ? user : this.state.profileImg } alt="user avatar" className='user-avatar'/> */}
       <img src={this.state.isloggedIn && this.state.profileImg == null ? user : this.state.profileImg} alt="user" className='user-avatar'/>
       <NavDropdown title={this.state.isloggedIn ? this.state.profileName :'UserName'} id="collasible-nav-dropdown">
       <NavDropdown.Item><Link to="/view-profile">View Profile</Link></NavDropdown.Item>
       <NavDropdown.Item><Link to="/profile-settings">Edit Profile</Link></NavDropdown.Item>
       {this.state.userRole == 'seeker' || this.state.seeker_provider == 'seeker' ? 
       <NavDropdown.Item><Link to="/favorites">Favorites</Link></NavDropdown.Item>:''}
       {this.state.userRole == 'both' ?
       this.state.seeker_provider == 'seeker' ? 
       <NavDropdown.Item onClick={()=>this.setState({seeker_provider: 'provider'},()=>{window.localStorage.setItem("seekerORprovider", this.state.seeker_provider);window.dispatchEvent(new Event("storage"));})}>Switch to Provider</NavDropdown.Item>
       :
       <NavDropdown.Item onClick={()=>this.setState({seeker_provider: 'seeker'},()=>{window.localStorage.setItem("seekerORprovider", this.state.seeker_provider);window.dispatchEvent(new Event("storage"));})}>Switch to Seeker</NavDropdown.Item>
       :
       ''}
       {this.state.userRole == 'provider' || this.state.userRole == 'seeker' || this.state.userRole == 'both' || this.state.fbloggedin ?
       this.state.userRole == 'both' ? 
       <Link to={{ pathname: "http://localhost:3001/?refresh=true&&token="+this.state.token+"&&role="+this.state.seeker_provider}} target="_blank" style={{padding: '0.25rem 1rem'}}>Dashboard</Link>
       :
       <Link to={{ pathname: "http://localhost:3001/?refresh=true&&token="+this.state.token}} target="_blank" style={{padding: '0.25rem 1rem'}}>Dashboard</Link>
       // <Link to={{ pathname: "http://dashboard.dinfoo.com/?refresh=true&&token="+this.state.token}} target="_blank" style={{padding: '0.25rem 1rem'}}>Dashboard</Link>
       :
       ''}
               {/* <NavDropdown.Item><Link to="/mytrips">My Trips</Link></NavDropdown.Item> */}
       <NavDropdown.Divider />
       {
      //  this.state.fbloggedin ? 
      //  <NavDropdown.Item onClick={fbLogout}>Sign Out</NavDropdown.Item>
      //  :
      //  this.state.googleloggedin ?
      //  <NavDropdown.Item onClick={responseGoogleLogoutSuccess}>Sign Out</NavDropdown.Item>
      //  :
       <NavDropdown.Item onClick={this.logout}>Sign Out</NavDropdown.Item>
     }
     </NavDropdown>
     </div>
     </>
      : 
      <>
      <Nav.Link className='nav-login-btn btn' onClick={() => this.setState({ showLogin: true})}>LOGIN/REGISTER</Nav.Link>
      {/* <Nav.Link onClick={() => this.setState({ showLogin: true})}>Login</Nav.Link>
      <Nav.Link onClick={() => this.setState({ showRegister: true})}>Sign Up</Nav.Link> */}
      </>
      }
 
  </Nav>
  </Navbar.Collapse>
  </Navbar>
      {/* signup modal */}
  <Modal
  aria-labelledby="contained-modal-title-vcenter"
  centered
  show={this.state.showRegister} onHide={() => this.setState({ showRegister: false})} className="signup-modal"
  >
  <Modal.Header closeButton>
  <Modal.Title id="contained-modal-title-vcenter">
  <center>Signup</center>
  </Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <h5>Welcome to Dinfoo</h5>
  <Form.Control type="text" placeholder="Full Name" onChange={(e) => this.setState({userName: e.target.value})}/>
  {this.state.userNameError ? <span className='text-danger'>{this.state.userNameError}</span> : ''}
  {this.state.showEmailInput ? 
  <>
  <Form.Control type="email" placeholder="Email or Phone number" autoComplete='off' 
  onChange={(e) => this.setState({userEmail: e.target.value},
  ()=>{
    if(!isNaN(+this.state.userEmail) && this.state.userEmail){
      this.setState({showMblInput:true,showEmailInput:false},()=>{
        console.log('by default phone input value',this.state.phone_no)
      })
    }else{
      this.setState({showMblInput:false,showEmailInput:true})
    }
  })}/>
  {this.state.notValidEmail ? <span className='text-danger'>Please enter valid email address.</span> : ''}
  </>
  :
  this.state.showMblInput ?
  <>
<PhoneInput
    placeholder="Enter phone number"
    // defaultCountry="PK"
    defaultCountry="US"
    international={true}
    value={this.state.phone_no}
    withCountryCallingCode={true}
    countryCallingCodeEditable={false}
    onChange={phone_no => this.setState({ phone_no },()=>{
      console.log(this.state.phone_no)
      if(!isNaN(+this.state.phone_no) && this.state.phone_no){
        this.setState({showMblInput:true,showEmailInput:false})
      }else{
        this.setState({showMblInput:false,showEmailInput:true})
      }
    })}
    className='phone-no-imput'
    error={this.state.phone_no ? (isValidPhoneNumber(this.state.phone_no) ? undefined : 'Invalid phone number') : 'Phone number required'}
    />
    {this.state.phone_no && isValidPhoneNumber(this.state.phone_no) ? '' : <span className='text-danger'>Please enter valid phone number.</span>}
  </>
  :
  ''
}
  <Form.Control className='position-relative' type={this.state.showPassword ? 'text' : 'password'} placeholder="Password" onChange={(e) => this.setState({userPassword: e.target.value, passwordStrength : zxcvbn(e.target.value)})}/>
  {this.state.showPassword ? 
  <RiEyeOffLine className='password-show' onClick={()=> this.setState(prevState => ({showPassword: !prevState.showPassword}))}/>
  :
  <RiEyeLine className='password-show' onClick={()=> this.setState(prevState => ({showPassword: !prevState.showPassword}))}/>
}
<PasswordStrengthBar password={this.state.userPassword} />
{this.state.passwordStrengthError ?
<span className='text-danger text-center mb-3'>Your password should be at least 8 characters long and contain at least one number with alphabets</span>
: ""
}
{/* either user is seeker or provider or both */}
{/* <div class="d-flex justify-content-center align-items-center mb-3">
<Form.Check type='checkbox' className="mr-15" value="seeker" label='Seeker' onChange={(e)=>this.setSeekerProvider(e)}/>
<Form.Check type='checkbox' className="mr-15" value="provider" label='Provider' onChange={(e)=>this.setSeekerProvider(e)}/>
{this.state.userType.includes('provider') ? // if user is provider only then show seller type options
<>
<Form.Check type='radio' className="mr-15" value="pro" checked={this.state.seller_type === 'pro' ? true : false} label='Professional' onChange={(e)=>this.setState({seller_type:e.target.value})}/>
<Form.Check type='radio' className="mr-15" value="local" checked={this.state.seller_type === 'local' ? true : false} label='Local' onChange={(e)=>this.setState({seller_type:e.target.value})}/>
</>:''}
</div>
{this.state.userTypeError ? <span className='text-danger'>{this.state.userTypeError}</span> : ''} */}
<div class="d-flex">
</div>
{/* <Form.Check type='checkbox' id='3' value="seeker" label='Seeker' onChange={(e) => this.setState({userType: this.state.userType.concat(e.target.value)})}/> */}
{/* <Form.Check type='checkbox' id='2' value="provider" label='Provider' onChange={(e) => this.setState({userType: this.state.userType.concat(e.target.value)})}/> */}
{/* <Form.Select aria-label="Default select example" onChange={(e) => this.setState({userType: e.target.value})}>
<option>User Type</option>
<option value="seeker">Seeker</option>
<option value="provider">Provider</option>
</Form.Select> */}
  {this.state.showCaptcha ? 
  <div id="recaptcha-container" className='d-flex justify-content-center align-items-center mb-4'></div>
  :''}
{this.state.showMblInput ?
<button className='w-100 continue-btn' onClick={()=>this.setState({showCaptcha:true},this.requestMblOTP)} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'continue with phone' }
</button>
:
this.state.showEmailInput ?
<button className='w-100 continue-btn mt-3' onClick={this.register} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'continue with email' }
</button>
:
''
  }
{this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
<center>
<p className='mt-3'>Already have an account? <strong  className='cursor-pointer' onClick={()=> this.setState({showRegister:false, showLogin:true})}>Login</strong></p>
</center>
<center><span className='left-right-line'>or</span></center>
<FacebookLogin
appId={process.env.REACT_APP_FB_KEY}
autoLoad={false}
fields="name,email,picture"
callback={responseFacebook}
icon={this.state.fbLoader ? "fa-spinner" : "fa-facebook"}
/>

<GoogleLogin
clientId={process.env.REACT_APP_GOOGLE_KEY}
buttonText={this.state.googleLoader ? <Spinner animation="border" variant="primary" size="sm"/> : "LOGIN WITH GOOGLE"}
onSuccess={responseGoogleSuccess}
onFailure={responseGoogleFailure}
className='continue-with-btn'
/>
</Modal.Body>
</Modal>
      {/* login modal */}
<Modal
aria-labelledby="contained-modal-title-vcenter"
centered
show={this.state.showLogin} onHide={() => this.setState({ showLogin: false})} className="signup-modal"
>
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
<center>Login</center>
</Modal.Title>
</Modal.Header>
<Modal.Body>
<h5>Welcome to Dinfoo</h5>
{/* <Form.Control type="email" placeholder="Email" onChange={(e) => this.setState({userEmail: e.target.value})}/> */}
{this.state.showEmailInput ? 
  <>
  <Form.Control type="email" placeholder="Email or Phone number" autoComplete='off' 
  onChange={(e) => this.setState({userEmail: e.target.value},
  ()=>{
    if(!isNaN(+this.state.userEmail) && this.state.userEmail){
      this.setState({showMblInput:true,showEmailInput:false})
    }else{
      this.setState({showMblInput:false,showEmailInput:true})
    }
  })}/>
  {this.state.notValidEmail ? <span className='text-danger'>Please enter a valid email or phone number</span> : ''}
  </>
  :
  this.state.showMblInput ?
  <>
<PhoneInput
    placeholder="Enter phone number"
    // defaultCountry="PK"
    defaultCountry="US"
    international={true}
    value={this.state.phone_no}
    onChange={phone_no => this.setState({ phone_no },()=>{
      if(!isNaN(+this.state.phone_no) && this.state.phone_no){
        this.setState({showMblInput:true,showEmailInput:false})
      }else{
        this.setState({showMblInput:false,showEmailInput:true})
      }
    })}
    className='phone-no-imput'
    error={this.state.phone_no ? (isValidPhoneNumber(this.state.phone_no) ? undefined : 'Invalid phone number') : 'Phone number required'}
    />
    {this.state.phone_no && isValidPhoneNumber(this.state.phone_no) ? '' : <span className='text-danger'>Please enter valid phone number.</span>}
  </>
  :
  ''
}
<Form.Control type={this.state.showPassword ? 'text' : 'password'} placeholder="Password" onChange={(e) => this.setState({loginPassword: e.target.value})}/>
{this.state.showPassword ? 
<RiEyeOffLine className='password-show' onClick={()=> this.setState(prevState => ({showPassword: !prevState.showPassword}))}/>
:
<RiEyeLine className='password-show' onClick={()=> this.setState(prevState => ({showPassword: !prevState.showPassword}))}/>
}
{/* <p>
We’ll call or text you to confirm your number. Standard message and data rates apply. Privacy Policy
</p> */}
<button className='w-100 continue-btn' onClick={this.login} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Login' }
</button>
{this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
<div className='mt-3 d-flex justify-content-center flex-column'> 
<center>
  <p className='cursor-pointer'><strong onClick={()=>this.setState({forgetModal:true, showLogin: false,errorAlert: false, errorMsg: ''})}>Forgot Password?</strong></p>
</center>
<center>
<h6>Don't have an account? <strong className='cursor-pointer' onClick={()=> this.setState({showLogin:false, showRegister:true})}>SignUp</strong></h6>
</center>
</div>

<center><span className='left-right-line'>or</span></center>
<FacebookLogin
appId={process.env.REACT_APP_FB_KEY}
autoLoad={false}
fields="name,email,picture"
callback={responseFacebook}
icon={this.state.fbLoader ? "fa-spinner" : "fa-facebook"}
/>

<GoogleLogin
clientId={process.env.REACT_APP_GOOGLE_KEY}
buttonText={this.state.googleLoader ? <Spinner animation="border" variant="primary" size="sm"/> : "LOGIN WITH GOOGLE"}
onSuccess={responseGoogleSuccess}
onFailure={responseGoogleFailure}
className='continue-with-btn'
/>
</Modal.Body>
</Modal>
      {/* forget Password modal */}
<Modal
aria-labelledby="contained-modal-title-vcenter"
centered
show={this.state.forgetModal} onHide={() => this.setState({ forgetModal: false})} className="signup-modal"
>
  {/* step 1 */}
  {this.state.forgetEmailText ? 
<>
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
<center>Forgotten your password?</center>
</Modal.Title>
</Modal.Header>
<Modal.Body>
<p>Don't worry, we'll send you a code to help you reset your password.</p>
<Form.Control type="email" placeholder="Email" onChange={(e) => this.setState({forgetEmail: e.target.value})}/>
{this.state.forgetEmailError ? <span className='text-danger'>{this.state.forgetEmailError}</span>:''}
<button className='w-100 continue-btn' onClick={this.forgetApi} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Continue' }
</button>
{this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
</Modal.Body>
</>
:
// step 2
this.state.forgetCodeText ?
<>
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
<center>Let us know it's you</center>
</Modal.Title>
</Modal.Header>
<Modal.Body>
<p>Last step! To secure your account, enter the code we just sent to<br/> {this.state.forgetEmail}.</p>
<Form.Control type="number" placeholder="EnterCode" onChange={(e) => this.setState({forgetEmailCode: e.target.value})}/>
{this.state.ForgotVerify ? <span className='text-danger'>{this.state.forgotVerifyMsg}</span> : ''}
{/* <div className='d-flex justify-content-between'> */}
{this.state.resend ? <button className='resend-btn float-left' onClick={this.resendOTPforForget} disabled={this.state.isResend ? true : false}>{this.state.isResend ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Resend' }</button>: '' }
<button className='verify-btn float-right' onClick={this.verifyNewOTP} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Continue' }
</button>
{/* </div> */}
{this.state.showResendMsg ? <center><h6 className='text-success'>{this.state.resendMsg}</h6></center> : ''}
{/* <button className='w-100 continue-btn' onClick={this.verifyNewOTP} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Continue' }
</button> */}
{this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
</Modal.Body>
</>
:
// step 3
this.state.resetPasswordText ? 
<>
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
<center>Reset your password</center>
</Modal.Title>
</Modal.Header>
<Modal.Body>
<p>Please set a new password for your account.</p>
<Form.Control className='position-relative' type={this.state.showPassword ? 'text' : 'password'} placeholder="New password" value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value, passwordStrength : zxcvbn(e.target.value)})}/>
  {this.state.showPassword ? 
  <RiEyeOffLine className='password-show' onClick={()=> this.setState(prevState => ({showPassword: !prevState.showPassword}))}/>
  :
  <RiEyeLine className='password-show' onClick={()=> this.setState(prevState => ({showPassword: !prevState.showPassword}))}/>
}
<PasswordStrengthBar password={this.state.newPassword} />

{this.state.passwordStrengthError ?
<span className='text-danger text-center mb-3'>Your password should be at least 8 characters long and contain at least one number with alphabets</span>
: ""
}
<Form.Control className='position-relative' type={this.state.showConfirmPassword ? 'text' : 'password'} placeholder="Confirm new password" value={this.state.confirmNewPassword} onChange={(e) => this.setState({confirmNewPassword: e.target.value})}/>
  {this.state.showConfirmPassword ? 
  <RiEyeOffLine className='password-show' onClick={()=> this.setState(prevState => ({showConfirmPassword: !prevState.showConfirmPassword}))}/>
  :
  <RiEyeLine className='password-show' onClick={()=> this.setState(prevState => ({showConfirmPassword: !prevState.showConfirmPassword}))}/>
}
{this.state.resetPasswordError ? <span className='text-danger'>{this.state.resetPasswordError}</span> : ''}
<button className='w-100 continue-btn' onClick={this.resetPassword} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Set Password' }
</button>
{this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
</Modal.Body>
</>
:
''}

</Modal>
      {/* verify mobile OTP modal */}
<Modal
aria-labelledby="contained-modal-title-vcenter"
centered
show={this.state.showmblOTPModal} onHide={() => this.setState({ showmblOTPModal: false})} className="signup-modal"
>
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
<center>Verify Your phone number</center>
</Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className="d-flex justify-content-center align-items-center otp-input">
<OtpInput
      value={this.state.mblOTP}
      onChange={mblOTP => this.setState({ mblOTP },this.verifyMblOTP)}
      numInputs={6}
      renderSeparator={<span>  </span>}
      renderInput={(props) => <input {...props} />}
      shouldAutoFocus={true}
    />
  </div>
{/* <p>A one time password (OTP) has been sent to your phone, please enter one time password to verify your phone number.</p> */}
{/* <Form.Control type="number" placeholder="Enter One time password" value={this.state.mblOTP} onChange={this.verifyMblOTP}/> */}
{this.state.errorVerifymblOTP ? <h6 className='text-danger mb-3'>Verification Fails! Please enter correct one time password to verify your phone number. </h6>:''}

{this.state.resendMblOTP ? <button className='resend-btn' onClick={()=>this.setState({showResendCaptcha:true},this.requestAgainforMblOTP)} disabled={this.state.isResendMbl ? true : false}>{this.state.isResendMbl ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Resend' }</button>: '' }
{this.state.showResendCaptcha ? 
<div className='d-flex justify-content-center align-items-center' id="resend-repatcha"></div>
:''}

{this.state.showMblResendMsg ? <center><h6 className='text-success'>Code is Resent to your mobile number.</h6></center> : ''}
</Modal.Body>
</Modal>
      {/* verify OTP modal */}
<Modal
aria-labelledby="contained-modal-title-vcenter"
centered
show={this.state.verifyOTP} onHide={() => this.setState({ verifyOTP: false})} className="signup-modal"
>
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
<center>Verify Your Email Address</center>
</Modal.Title>
</Modal.Header>
<Modal.Body>
{this.state.errorMsg ? <center><h6 className='text-danger'>{this.state.errorMsg}</h6></center>:''}
<p>A one time password (OTP) has been sent to your email address, please enter one time password to verify your email address.</p>
<Form.Control type="number" placeholder="Enter One time password" onChange={(e) => this.setState({otpValue: e.target.value})}/>
{this.state.errorVerify ? <h6 className='text-danger mb-3'>Verification Fails! Please enter correct one time password to verify your email address. </h6>:''}
<div className='d-flex justify-content-between'>
{this.state.resend ? <button className='resend-btn' onClick={this.resendOTP} disabled={this.state.isResend ? true : false}>{this.state.isResend ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Resend' }</button>: '' }
<button className='verify-btn' onClick={this.verify} disabled={this.state.isloading ? true : false}>
{this.state.isloading ? 
<Spinner animation="border" variant="light" size="sm"/> : 'Verify' }
</button>
</div>
{this.state.showResendMsg ? <center><h6 className='text-success'>{this.state.resendMsg}</h6></center> : ''}
</Modal.Body>
</Modal>
 {/* upgrade pro modal */}
 {/* <Modal
  aria-labelledby="contained-modal-title-vcenter"
  centered
  show={this.state.proModal} onHide={() => this.setState({ proModal: false})} className="signup-modal"
  >
  <Modal.Header closeButton>
  <Modal.Title id="contained-modal-title-vcenter">
  <center>Upgrade to PRO</center>
  </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <FormGroup>
      <Label for="exp">Experience</Label>
      <Form.Control id="exp" type="text" placeholder="Experience" onChange={(e) => this.setState({experience: e.target.value})}/>
  {this.state.experienceError ? <span className='text-danger'>{this.state.experienceError}</span> : ''}
    </FormGroup>
    <FormGroup>
      <Label for="web">Official Website</Label>
  <Form.Control id="web" type="text" placeholder="Official Website" onChange={(e) => this.isValidUrl(e)}/>
  {this.state.validWebUrl ? <span className='text-danger'>{this.state.validWebUrl}</span> : ''}
  {this.state.officialWebError ? <span className='text-danger'>{this.state.officialWebError}</span> : ''}
    </FormGroup>
    <FormGroup>
      <Label for="license">Licence</Label>
  <Form.Control id="license" type="file" placeholder="Licence" onChange={(e) => this.setState({license: e.target.files[0]})}/>
  {this.state.licenseError ? <span className='text-danger'>{this.state.licenseError}</span> : ''}
    </FormGroup>
  <button className='w-100 continue-btn' onClick={this.upgradePro} disabled={this.state.isloading ? true : false}>
  {this.state.isloading ? 
  <Spinner animation="border" variant="light" size="sm"/> :
   'Upgrade' 
  }
  </button>
  {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
</Modal.Body>
</Modal> */}
{this.state.loginAlert ? 
<Alert key='success' variant='success' className='log-alert'>
{this.state.loginMsg}
</Alert>
:
this.state.registerAlert ?
<Alert key='success' variant='success' className='log-alert'>
{this.state.registerMsg}
</Alert>
:
this.state.logoutAlert ?
<Alert key='success' variant='success' className='log-alert'>
Logout Successfully.
</Alert>
:
this.state.resetSuccess ?
<Alert key='success' variant='success' className='log-alert'>
{this.state.resetSuccessMsg}
</Alert>
:
this.state.mblVerify ?
<Alert key='success' variant='success' className='log-alert'>
Mobile number verified successfully
</Alert>
:
this.state.waitforRegister ?
<Alert key='success' variant='success' className='log-alert'>
Kindly wait while your registration is in progress
</Alert>
:
this.state.registerMblError ? 
<Alert key='danger' variant='danger' className='log-alert'>
{this.state.registerMblErrorMsg}
</Alert>
:
this.state.successPro ? 
<Alert key='success' variant='success' className='log-alert'>
{this.state.successProMsg}
</Alert>
:
''
}

</>

)
}
}
