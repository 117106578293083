import React, { Component } from 'react'
import {withRouter, Redirect} from 'react-router-dom'
import Banner from '../Components/Banner';
import Navbar from "../Components/NavbarHome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import "../Assets/Styles/style.css";
import serviceImg from '../Assets/Images/serviceImage.jpg';
import { api_url } from "../Variables";
class Category extends Component {
    constructor(props){
        super(props);
        this.state  = {
            categoryId: this.props.location.state,
            categoryResult:[],
            isloading: true,
            redirect: null,
            isCategory: '',
        }
    }
    componentDidMount= async()=>{
        console.log('id is: ', this.state.categoryId)
            let category = await fetch(api_url+"api/category/services/"+this.state.categoryId,{
                  method: "get",
                  headers: {
                  'Accept': 'application/json'
                  }
                });
                if(category){
                category = await category.json();
                  this.setState({categoryResult: category.data, isloading: false}, () => {
                     console.log('category details', this.state.categoryResult);
                   });
                 }
                 else{
                  // this.setState({ redirect: "/" });
                  this.setState({isCategory: category.Result, isloading: false}, ()=>{
  
                    console.log('is category: ', this.state.isCategory)
                  })
                 }
    }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
        <>
          <Navbar />
          <Banner/>
          <div className="content">
          <center>
            <h5>{this.state.noResult ? this.state.noResult : ''}</h5>
            {this.state.isloading ? 
              <div class="loader"></div>
              :

              this.state.isCategory ? 
              <h5 className="mb-5">{this.state.isCategory}</h5>
              :
          this.state.categoryResult ?
          <Row>
         { this.state.categoryResult.map((n)=>(
            <Col md={4} lg={4} xs={12}>
                    <div class="container_foto ">
         <div class="ver_mas text-center">
         {/* <Link to={{pathname: "/mytrips/"+ n.id+"/"+n.provider_id, state : {service_id: n.id, provider_id: n.provider_id}}}><button>Book a service</button></Link> */}
         <Link to={{pathname: "/service/"+ n.id+"/"+n.provider_id, state : {service_id: n.id, provider_id: n.provider_id}}}><button>View service</button></Link>
         </div>
         <article class="text-left">
            <h2>{n.name} 
            {/* <br/>LA IMAGEN */}
            </h2>
            <h4>{new Intl.NumberFormat().format(n.price)}/<span class="date">{n.service_type}</span></h4>
         </article>
         <img src={n.service_image ? n.service_image : serviceImg} alt=""/>
      </div>
          </Col>
          ))}
        </Row>
        :
        <h5>No Service Found</h5>
            }
          </center>
         
          </div>
          <Footer/>
        </>
    )
  }
}
export default withRouter(Category);
