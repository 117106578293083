import React, { Component } from 'react'
import {Row,Col} from "react-bootstrap";
import "../Assets/Styles/footer.css";
import { RiFacebookFill,RiTwitterLine,RiInstagramLine,RiGlobalLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import '../Assets/Styles/navbar.css';
import logo from '../Assets/Images/dinfoo logo.png'
const currentYear = new Date().getFullYear();
export default class Footer extends Component {
    constructor(props){
        super(props);
        this.state={
            token:'',
        }
    }
    componentDidMount = async()=>{
      window.addEventListener("storage",(e) => {
        console.log('storage changed')
        const token = JSON.parse(localStorage.getItem("token"));
      if(token){
      this.setState({token: token.token});
      }else{
        this.setState({token:''})
      }
       },[]);
      const token = JSON.parse(localStorage.getItem("token"));
    if(token){
    this.setState({token: token.token});
    }else{
      this.setState({token:''})
    }
    }
  render() {
    return (
      <div className='footer'>
        <div className='footer-content'>
            <div className='footer-links'>
            <Row>
            <Col md={4} lg={4} xs={12}>
              <ul>
                <li><img src={logo} alt="logo" className='footer-logo'/></li>
                <li>email@email.com</li>
                <li>01234587645</li>
              </ul>
            </Col>
            <Col md={4} lg={4} xs={12}>
                <h5>Support</h5>
                <ul>
                    {/* <li>Help Center</li> */}
                    {/* <li>Air Cover</li> */}
                    {/* <li>Safety Information</li> */}
                    <li><Link to="/support">Support</Link></li>
                    {this.state.token ? '' :
                    // <li className='cursor-pointer' onClick={()=>this.setState({showRegister:true})}>Become a guide</li>}
                    <li><Link to="/become-a-guide">Become a guide</Link></li>}
                    <li><Link to="/careers">Careers</Link></li>
                </ul>
            </Col>
            <Col md={4} lg={4} xs={12}>
            <h5>Dinfoo</h5>
                <ul>
                    {/* <li>Newsroom</li> */}
                    {/* <li><Link to="/joinUs">Join Us</Link></li> */}
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/faq">FAQ</Link></li>
                    <li><Link to="/termsConditions">Terms & Conditions</Link></li>
                    {/* <li>Learn about new features</li> */}
                    {/* <li>Letter from our founders</li> */}
                </ul>
            </Col>
            
        </Row>
            </div>
            <Row className='d-flex justify-content-between mt-3'>
                <Col md={6} lg={6} xs={12}>
                <div> © {currentYear} Dinfoo, Inc. | <Link to="/privacy">Privacy</Link> | <Link to="/termsConditions">Terms</Link> | <Link to="/sitemap">Sitemap</Link> </div>
                </Col>
                <Col md={6} lg={6} xs={12}>
                <div className="d-flex float-right">
                    <div className="d-flex">
                    <RiGlobalLine className='footer-icon'/>
                    <span>English (US)</span>
                    </div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='d-flex'>
                    <a href='https://www.facebook.com' target='_blank'>
                      <RiFacebookFill className='footer-icon'/>
                    </a>
                    <a href="https://twitter.com/" target='_blank'>
                      <RiTwitterLine className='footer-icon'/>
                    </a>
                    <a href="https://www.instagram.com/" target='_blank'>
                      <RiInstagramLine className='footer-icon'/>
                    </a>
                </div>
                </div>
                </Col>
               
               
            </Row>
        </div>
      </div>
      
    )
  }
}
