import React, { Component } from 'react'
import NavbarHome from '../Components/NavbarHome';
import moment from 'moment';
import {Row, Col,Alert,Button,Accordion,Spinner, Form, InputGroup, Card,FormGroup,Modal} from 'react-bootstrap';
import { Label } from 'reactstrap';
import Footer from '../Components/Footer';
import { RiCameraFill,RiPencilFill } from "react-icons/ri";
import {Redirect,Link} from 'react-router-dom'
import { api_url } from "../Variables";
import Select from "react-select";
import { Map, GoogleApiWrapper, Marker, CameraPosition, CameraUpdateFactory  } from 'google-maps-react';
import { RiCloseCircleFill, RiCloseLine, RiMotorbikeFill, RiWalkFill,RiTimeLine,RiDeleteBin6Line,RiAddCircleFill} from "react-icons/ri";
import {  } from "react-icons/ri";
import PlacesAutocomplete,{
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const mapStyles = {
  width: '50%',
  height: '40%',
  margin : '20px 0px 0px 0px'
};
const GOOGLE_API_KEY = process.env.REACT_APP_API_KEY
const count = 1;
class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: '',
      userprofileName:'',
      userprofileLocation:'',
      userprofileEmail:'',
      userprofileAbout:'',
      userprofileImg:[],
      userprofileSocialImg:'',
      uploadedImages:[],
      userprofileupdatedImg:[],
      userprofileLang:[],
      cities:[],
      city:'',
      language:[],
      selectedOptions:[],
      selectedLanguage:[],
      combinedLanguages:[],
      finalLang:[],
      userCurrentCity:'',
      userCurrentRegion:'',
      userCurrentCountry:'',
      completeAddress:'',
      setUserCurrentCity:false,
      userID:'',
      updateAlert:'',
      errorAlert:false,
      updateMsg:'',
      redirect: null,
      isChecked: true,
      isloading: false,
      citiesLoaded: false,
      images:[],
      btn_loader:false,
      lat:'',
      long:'',
      showMap:false,
      showingInfoWindow: false,
      profileVariables:[],
      pv_Values:[],
      val:[],
      checkboxesVal:[],
      userRole:'',
      seeker_provider: localStorage.getItem('seekerORprovider') || 'seeker',
      showAboutField:false,
      guide_type:'',
      experience:'',
      officialWeb:'',
      validWebUrl:'',
      license:'',
      hour_rate:'',
      mode:[],
      removedImages:[],
      showAvail:false,
      availabilityModal:false,
      day:'',
      dayError:'',
      time_from:'',
      timeFromError:'',
      time_to:'',
      timeToError:'',
      serviceList:[{
        day: "",
        from:"",
        to:"",
        dayError:false,
        fromError:false,
        toError:false
    }],
    success:false,
    successMsg:'',
    slotError:false,
    slotErrorMsg:'',
    error:false,
    errorMsg:'',
    providerSlots:[],
    slotID:'',
    deleteSlotModal:false
      // fbloggedIn:false, 
      // googleloggedIn:false,
    };
    this.handleLangChange = this.handleLangChange.bind(this);
  }
  handleChange = completeAddress => {
    this.setState({ completeAddress });
  };
  handleSelectLoc = userprofileCity => {
    geocodeByAddress(userprofileCity)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
         console.log('Success', latLng);
         this.setState({completeAddress:userprofileCity,lat:latLng.lat,long:latLng.lng});
   
  Map.animateCamera(CameraUpdateFactory.newCameraPosition(new CameraPosition.Builder()
  .target(latLng)
  .zoom(14)
  .bearing(0)
  .tilt(0)
  .build()));
      })
      .catch(error => console.error('Error', error));
  };
  handleSelect = (data)=> {
    this.setState({selectedOptions: data},()=>{console.log('languages:', this.state.selectedOptions)});
    
  }
  urlPatternValidation = (URL) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL);
  };
  isValidUrl = (event) =>{
    this.setState({officialWeb:event.target.value},()=>{
      if(this.urlPatternValidation(this.state.officialWeb)){
        this.setState({validWebUrl:''})
      }else{
        this.setState({validWebUrl:'Please enter valid URL'})
      }
    })
  }
  componentDidMount = async()=>{
    this.getUSerDetails();
    this.getLanguages();
    window.addEventListener("storage",(e) => {
      console.log('storage changed')
      const role = (localStorage.getItem("seekerORprovider"));
      this.setState({seeker_provider: role},()=>{
        this.getUSerDetails()
      })
    });
    const getToken = JSON.parse(localStorage.getItem("token"));
    // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
    // const googleLogIn = (JSON.parse(localStorage.getItem('googleLogIn')));
        // if(getToken || fbLogIn || googleLogIn){
        if(getToken){
          console.log('user logged in')
        }else{
          this.setState({ redirect: "/" });
        }
    //find out if a user's geolocation is available or not.
    if ("geolocation" in navigator) {
      console.log("user's geolocation is Available");
      //Get the current position of the user 
      // navigator.geolocation.watchPosition((position) =>{
      //   console.log("Latitude is :", position.coords.latitude);
      //   console.log("Longitude is :", position.coords.longitude);
      //   this.setState({lat: position.coords.latitude, long:position.coords.longitude,showMap:true},
      //     console.log('lat',this.state.lat, 'long',this.state.long))
      //     //get location name from latitude and longitude
      //      this.geocodeLatLng(geocoder,position.coords.latitude,position.coords.longitude);
      // })
      // const options = {
      //   enableHighAccuracy: true,
      //   timeout: 5000,
      //   maximumAge: 0,
      // };
      
      // function success(position) {
      //   console.log("Latitude is :", position.coords.latitude);
      //   console.log("Longitude is :", position.coords.longitude);
      //   this.setState({lat: JSON.stringify(position.coords.latitude), long:JSON.stringify(position.coords.longitude),showMap:true},
      //     console.log('lat',this.state.lat, 'long',this.state.long))
      //     //get location name from latitude and longitude
      //      this.geocodeLatLng(geocoder,position.coords.latitude,position.coords.longitude);
      // }
      
      // function error(err) {
      //   console.warn(`ERROR(${err.code}): ${err.message}`);
      // }
      // navigator.geolocation.getCurrentPosition(success, error, options);
      navigator.geolocation.getCurrentPosition((position) =>{
        this.setState({
          lat: position.coords.latitude,
          long: position.coords.longitude,
          showMap:true
        })
        // },()=>{
          console.log('latitude is',this.state.lat);
          console.log('longitude is',this.state.long);
        //   const API_endpoint =  `https://api.openweathermap.org/data/2.5/weather?`;
        //   const API_key = "8087de5f86a5889f0036853b52748131"
        // let FinalAPI = `${API_endpoint}lat=${this.state.lat}&lon=${this.state.long}&appid=${API_key}`
        // console.log(FinalAPI)
        // axios.get(FinalAPI)
        // .then((response) => {
        //   console.log(response.data)
        // })
          // fetch(FinalAPI,
        //   fetch(
        //     'https://api.weatherapi.com/v1/forecast.json?q=' +
        //       this.state.lat +
        //       ',' +
        //       this.state.long,
        //     {
        //       method: 'GET',
        //       headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //       },
        //     },
        //   )
        //     .then(response => response.json())
        //     .then(responseJson => {
        //       console.log(responseJson.data);
        //       this.setState({
        //         showMap: true,
        //       });
        //     })
        //     .catch(error => {
        //       console.error(error);
        //     });
        // });
        
          //get location name from latitude and longitude
          //  this.geocodeLatLng(geocoder,31.5204,74.3587);
          //  this.geocodeLatLng(geocoder,33.547886, 68.422562);
           this.geocodeLatLng(geocoder,position.coords.latitude,position.coords.longitude);
      },error => {
        console.log(error.code, error.message);
      },
      {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 10000,
      })
    } else {
      window.alert("Sorry!!! user's geolocation is Not Available");
    }
    const google = window.google
    const geocoder = new google.maps.Geocoder();
  }
  // componentWillUnmount = async()=>{
  //   window.addEventListener("storage",(e) => {
  //     console.log('storage changed')
  //     const role = (localStorage.getItem("seekerORprovider"));
  //     this.setState({seeker_provider: role},()=>{this.getUSerDetails()})
  //   });
  // }
  getUSerDetails = async () =>{
    const getToken = JSON.parse(localStorage.getItem("token"));
    const token = getToken.token;
    if(token){
      console.log('user logged in')
      let userDetail = await fetch(api_url+"api/dashboard/profile",{
            method: "get",
            headers: {
              "Authorization": `Bearer ${token}`,
            'Accept': 'application/json'
            }
          });
          userDetail = await userDetail.json();
          if(userDetail){
            // window.localStorage.setItem("user-info", JSON.stringify(userDetail));
            // window.dispatchEvent(new Event("storage"));
            // localStorage.setItem("user-info", JSON.stringify(userDetail));
            this.setState({userID: userDetail.profile.id,userprofileName: userDetail.profile.name, completeAddress:userDetail.profile.location,
              cities: userDetail.cities, userprofileEmail: userDetail.profile.email, guide_type:userDetail.profile.guide_type,
              mode:userDetail.profile.preferred_modes, hour_rate:userDetail.profile.hourly_rate,experience:userDetail.profile.experience,
              officialWeb:userDetail.profile.official_website,license:userDetail.profile.license, providerSlots:userDetail.provider_booking_slots,
              userprofileImg: userDetail.profile_images, uploadedImages: userDetail.profile_images, userprofileLang: userDetail.User_languages, 
              userprofileAbout: userDetail.profile.about, pv_Values:userDetail.profile_variables_values, userRole:userDetail.profile.role_id
            }, () => {
               console.log('providers slot',this.state.providerSlots );
               geocodeByAddress(this.state.completeAddress)
               .then(results => getLatLng(results[0]))
               .then(latLng => {
                  console.log('Success', latLng);
                  this.setState({lat:latLng.lat,long:latLng.lng});
            
           Map.animateCamera(CameraUpdateFactory.newCameraPosition(new CameraPosition.Builder()
           .target(latLng)
           .zoom(14)
           .bearing(0)
           .tilt(0)
           .build()));
               })
               .catch(error => console.error('Error', error));
               if(this.state.userRole == '4'){
                // const seekerORprovider = localStorage.getItem('seekerORprovider');
                // if(seekerORprovider){
                //   this.setState({seeker_provider:seekerORprovider},()=>{
                    // console.log('user is',this.state.seeker_provider);
                    if(this.state.seeker_provider == 'provider'){
                      this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
                        console.log('user is provider',this.state.profileVariables)
                      })
                    }else{
                      this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
                        console.log('user is seeker',this.state.profileVariables)
                      })
                    }
                  // })
                // }else{
                //   this.setState({seeker_provider:'seeker'},()=>{
                //     console.log('user is',this.state.seeker_provider)
                //   })
                // }
              }
              else if((this.state.userRole == 2) || ((this.state.userRole == 4) && (this.state.seeker_provider == 'provider'))){
                this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
                  console.log('user is provider',this.state.profileVariables)
                })
              }
              else if((this.state.userRole == 3) || ((this.state.userRole == 4) && (this.state.seeker_provider == 'seeker'))){
                this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
                  console.log('user is seeker',this.state.profileVariables)
                })
              } 
              // else if((this.state.userRole == 4) && (this.state.seeker_provider == 'provider')){
              //   this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
              //     console.log('user is provider',this.state.profileVariables)
              //   })
              // }
              // else if((this.state.userRole == 4) && (this.state.seeker_provider == 'seeker')){
              //   this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
              //     console.log('user is seeker',this.state.profileVariables)
              //   })
              // }
              else{
                this.setState({profileVariables:[]},()=>{
                  console.log('user is no one',this.state.profileVariables)
                })
              }
             });
           }
    }
    else{
      console.log('user is not logged in');
    }
  }
  getUSerandSaveDetails = async () =>{
    const getToken = JSON.parse(localStorage.getItem("token"));
    const token = getToken.token;
    if(token){
      console.log('user logged in')
      let userDetail = await fetch(api_url+"api/dashboard/profile",{
            method: "get",
            headers: {
              "Authorization": `Bearer ${token}`,
            'Accept': 'application/json'
            }
          });
          userDetail = await userDetail.json();
          if(userDetail){
            window.localStorage.setItem("user-info", JSON.stringify(userDetail));
            window.dispatchEvent(new Event("storage"));
            // localStorage.setItem("user-info", JSON.stringify(userDetail));
            this.setState({userID: userDetail.profile.id, userprofileName: userDetail.profile.name, completeAddress:userDetail.profile.location,
              cities: userDetail.cities, userprofileEmail: userDetail.profile.email, guide_type:userDetail.profile.guide_type,
              mode:userDetail.profile.preferred_modes, hour_rate:userDetail.profile.hourly_rate,experience:userDetail.profile.experience,
              officialWeb:userDetail.profile.official_website,license:userDetail.profile.license,
              userprofileImg: userDetail.profile_images, uploadedImages: userDetail.profile_images, userprofileLang: userDetail.User_languages, 
              userprofileAbout: userDetail.profile.about, pv_Values:userDetail.profile_variables_values, userRole:userDetail.profile.role_id
            }, () => {
               console.log('user details', userDetail );
               if(this.state.userRole == '4'){
                // const seekerORprovider = localStorage.getItem('seekerORprovider');
                // if(seekerORprovider){
                //   this.setState({seeker_provider:seekerORprovider},()=>{
                    // console.log('user is',this.state.seeker_provider);
                    if(this.state.seeker_provider == 'provider'){
                      this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
                        console.log('user is provider',this.state.profileVariables)
                      })
                    }else{
                      this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
                        console.log('user is seeker',this.state.profileVariables)
                      })
                    }
                  // })
                // }else{
                //   this.setState({seeker_provider:'seeker'},()=>{
                //     console.log('user is',this.state.seeker_provider)
                //   })
                // }
              }
              else if((this.state.userRole == 2) || ((this.state.userRole == 4) && (this.state.seeker_provider == 'provider'))){
                this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
                  console.log('user is provider',this.state.profileVariables)
                })
              }
              else if((this.state.userRole == 3) || ((this.state.userRole == 4) && (this.state.seeker_provider == 'seeker'))){
                this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
                  console.log('user is seeker',this.state.profileVariables)
                })
              } 
              // else if((this.state.userRole == 4) && (this.state.seeker_provider == 'provider')){
              //   this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
              //     console.log('user is provider',this.state.profileVariables)
              //   })
              // }
              // else if((this.state.userRole == 4) && (this.state.seeker_provider == 'seeker')){
              //   this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
              //     console.log('user is seeker',this.state.profileVariables)
              //   })
              // }
              else{
                this.setState({profileVariables:[]},()=>{
                  console.log('user is no one',this.state.profileVariables)
                })
              }
             });
           }
    }
    else{
      console.log('user is not logged in');
    }
  }
  getLanguages = async () =>{
    let fetchLanguages = await fetch(api_url+"api/all/languages",{
      method: "get",
      headers: {
      'Accept': '*/*'
      }
    });
    fetchLanguages = await fetchLanguages.json();
    if(fetchLanguages){
      this.setState({language: fetchLanguages})
    }
    console.log('languages', fetchLanguages);
  }
  getCities = async() =>{
    // this.setState({city: e.target.value}, ()=>{console.log('selected city is: ', this.state.city)});
    this.setState({isloading: true});
var formdata = new FormData();
formdata.append("city", this.state.city);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"api/search/city", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({cities: result, isloading:false, citiesLoaded:true}, ()=>{
      console.log('fetched cities', this.state.cities);
    })
  })
  .catch(error => {
    console.log('error', error)
    this.setState({isloading:false})
  });

    ////////
    // const city = this.state.city;
    //   let item = {city};
    //   console.log('city', JSON.stringify(item))
    //   let fetchCities = await fetch(api_url+"api/search/city",{
    //     method: "post",
    //     headers : {
    //       "Content-Type": "multipart/form-data;application/json",
    //          'Accept': '*/*',
    //      },
    //      body: JSON.stringify(item)
    //   });
    //   fetchCities = await fetchCities.json();
    //   if(fetchCities){
    //     this.setState({cities: fetchCities, isloading:false, citiesLoaded:true}, ()=>{
    //       console.log('fetched cities', this.state.cities);

    //     })
    //   }
  }
  handleLangChange = ()=> {
    if (this.state.userprofileLang.length <= 0){
      this.setState({})
    }
    for (var option of document.getElementById('langs').options)
    {
        if (option.selected) {
          if (this.state.userprofileLang <= 0){
            this.setState({userprofileLang: option.value})
          }else{
            this.state.userprofileLang.push(option.value);
          }
        }
    }
    console.log(this.state.userprofileLang);
    }
    dltSelectedImg = (i,e) =>{
      let x =e.target.getAttribute("data-remove");
      // let x =e.target.dataset.remove;
      const removeImg = this.state.uploadedImages.filter(item => item !== x);
      const removedImages = this.state.userprofileImg.filter(item => item == x);
      if(removedImages.length > 0){
        const [...parts] = removedImages[0].split('/');
        const fileName = parts.pop(); // get the filename
        this.setState({removedImages:this.state.removedImages.concat(fileName)},()=>{
          console.log('removed images are',this.state.removedImages)
        })
      }else{
        this.setState({removedImages:this.state.removedImages},()=>{
          console.log('removed images are',this.state.removedImages)
        })
      }
      this.setState({uploadedImages: removeImg},()=>{
        const s = this.state.userprofileupdatedImg.filter((item, index) => index !== i);
        this.setState({userprofileupdatedImg:s})
      });

    }
    dltSelectedLang = (event) =>{
      let x =event.target.getAttribute("removeLang");
      console.log(x)
      const removelang = this.state.selectedLanguage.filter(item => item !== x);
      this.setState({selectedLanguage: removelang});
    }
    dltFetchedLang = (event) =>{
      let x =event.target.getAttribute("removeLang");
      console.log(x)
      const removelang = this.state.userprofileLang.filter(item => item !== x);
      this.setState({userprofileLang: removelang});
    }
    handleMultipleImages =(evnt)=>{
      const selectedFIles =[];
      const uploadedFIles =[];
      const targetFiles =evnt.target.files;
      const targetFilesObject= [...targetFiles]
      targetFilesObject.map((file)=>{
        //  return selectedFIles.push(reader.readAsDataURL(file));
        // const url = selectedFIles.push(URL.createObjectURL(file));
        // this.setState({uploadedImages: url})
         return [selectedFIles.push(file), uploadedFIles.push(URL.createObjectURL(file))];
      })
      this.setState({userprofileupdatedImg: [...this.state.userprofileupdatedImg , ...selectedFIles], uploadedImages: [...this.state.uploadedImages , ...uploadedFIles] },()=>{console.log('this.state.uploadedImages: ', this.state.uploadedImages, 'this.state.userprofileupdatedImg: ', this.state.userprofileupdatedImg)} )
      // this.setState({userprofileupdatedImg: [...selectedFIles], uploadedImages: [ ...uploadedFIles] },()=>{console.log('uploaded images', this.state.uploadedImages, 'images to upload: ', this.state.userprofileupdatedImg)} )
      // setImages(selectedFIles);
    }
    update = async(event)=>{
     this.setState({btn_loader:true})
      const getToken = JSON.parse(localStorage.getItem("token"));
      const token = getToken.token;
      var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

var formdata = new FormData();
formdata.append("name", this.state.userprofileName);

if (this.state.userprofileLang){
this.state.userprofileLang.map((n)=>(
      formdata.append("languages[]",n )
    ))}
if (this.state.selectedOptions){
this.state.selectedOptions.map((n)=>(
      formdata.append("languages[]",n.value )
    ))}

// if(this.state.userprofileLang && this.state.selectedLanguage){
// if(this.state.userprofileLang.length > 0 && this.state.selectedLanguage.length > 0 ) //user select language
// {
//   this.state.userprofileLang.concat(this.state.selectedLanguage).map((n)=>(
//     formdata.append("languages[]",n )
//   ))
// }else{
//   this.state.userprofileLang.map((n)=>(
//     formdata.append("languages[]",n )
//   ))
// }
// }else{formdata.append("languages[]","" )}
// formdata.append("languages[]", this.state.userprofileLang ?  [...this.state.userprofileLang , ...this.state.selectedLanguage] : this.state.selectedLanguage);
//if user's role id is 4
// if(this.state.userRole == '4'){
//   let currentRole;
//   if(this.state.seeker_provider == 'provider'){
//     currentRole = 2;
//   }else if(this.state.seeker_provider == 'seeker'){
//     currentRole = 3;
//   }
//   formdata.append("current_role", currentRole)
// }
 if (this.state.userprofileAbout){ formdata.append("about", this.state.userprofileAbout);}
// formdata.append("overview", "Hi, everyone");
if (this.state.userprofileupdatedImg.length > 0){
this.state.userprofileupdatedImg.map((n)=>(
  formdata.append("profile_img[]", n)
))}
if (this.state.removedImages.length > 0){
this.state.removedImages.map((n)=>(
  formdata.append("remove[]", n)
))}
if(this.state.mode.length > 0){
  this.state.mode.map((n)=>{
      formdata.append("preferred_mode[]", n);
  })
}
formdata.append("hourly_rate", this.state.hour_rate);
formdata.append("experience", this.state.experience);
formdata.append("official_website", this.state.officialWeb);
formdata.append("license", this.state.license);
if(this.state.setUserCurrentCity){
  formdata.append("updated_location",this.state.userCurrentCity+', '+this.state.userCurrentCountry)
  formdata.append("longitude",this.state.long)
  formdata.append("latitude",this.state.lat)
}
if(this.state.completeAddress != null){
  formdata.append("updated_location",this.state.completeAddress)
  formdata.append("longitude",this.state.long)
  formdata.append("latitude",this.state.lat)
}
if(this.state.profileVariables){
  if(this.state.profileVariables.length > 0){
const p_f = this.state.profileVariables.length; //getting total profile vaiables
for (var i=0; i < p_f; i++) {
  if(this.state.val[i] != null || this.state.val[i] != undefined){
    await formdata.append(this.state.profileVariables[i] ? 'field_'+this.state.profileVariables[i].id : '' , this.state.val[i] ? this.state.val[i] : this.state.pv_Values[i] ? this.state.pv_Values[i].value : '');
    console.log('name' , this.state.profileVariables[i] ? this.state.profileVariables[i].id : '' , 'value', this.state.val[i]) // saving profile variable with its value
  }
  // await formdata.append(this.state.profileVariables[i] ? 'field_'+this.state.profileVariables[i].id : '' , this.state.val[i]);
}
  }
}
var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
};

// fetch(api_url+"api/dashboard/profile/updated", requestOptions)
fetch(api_url+"api/dashboard/profile/updated", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({updateAlert:true,btn_loader:false,selectedOptions:[],showAboutField:false,
      userprofileupdatedImg:[],removedImages:[]});
    setTimeout(() => {
      this.setState({updateAlert: false});
    }, 2000);
    this.getUSerandSaveDetails();
    
  })
  .catch(error => {
    console.log('error', error);
    this.setState({errorAlert:true,btn_loader:false})
  });
    }
    geocodeLatLng(geocoder,lati,longi) {
      const latlng = {
        lat: lati, 
        lng: longi,
      };
      geocoder.geocode({ location: latlng })
        .then((response) => {
          if (response.results[0]) {
            console.log(response.results[0])
            for (var i=0; i<response.results[0].address_components.length; i++)
            {
                if (response.results[0].address_components[i].types[0] == "locality") {
                        //this is the object you are looking for City
                        this.setState({userCurrentCity:response.results[0].address_components[i].long_name},()=>{console.log('city: ',this.state.userCurrentCity)})
                    }
                if (response.results[0].address_components[i].types[0] == "administrative_area_level_1") {
                        //this is the object you are looking for State
                        this.setState({userCurrentRegion:response.results[0].address_components[i].long_name},()=>{console.log('region: ',this.state.userCurrentRegion)})
                    }
                if (response.results[0].address_components[i].types[0] == "country") {
                        //this is the object you are looking for
                        this.setState({userCurrentCountry:response.results[0].address_components[i].long_name},()=>{console.log('country: ',this.state.userCurrentCountry)})
                    }
            }
            // this.setState({userCurrentCity:response.results[0].address_components[2].long_name + ", " + response.results[0].address_components[1].long_name + ", " + response.results[0].address_components[3].long_name},
            //   ()=>{

            //     console.log('city is', this.state.userCurrentCity)
            //   })
  //           for (var i=0; i<response.results[0].address_components.length; i++)
  //           {
  // document.getElementById(
  //             "result"
  //         ).innerHTML = `<span>${response.results[0].address_components[i].types[0] == 'locality' ? response.results[0].address_components[i].long_name : ''}</span>`;
  //         }
            // }
          //   document.getElementById(
          //     "result"
          // ).innerHTML = `<span>${response.results[0].address_components[2].long_name + ", " + response.results[0].address_components[1].long_name + ", " + response.results[0].address_components[3].long_name}</span>`;
          } 
          else {
            window.alert("No results found");
          }
        })
        .catch((e) => window.alert("Geocoder failed due to: " + e));
    }
    handleFormChange = (index, event) => {
      let data = [...this.state.val];
      data[index] = event.target.value;
      this.setState({val:data},()=>{
        console.log('1',this.state.val[0],'2',this.state.val[1], 'whole array', this.state.val)
      })
   }
  //  slot functions
  handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.serviceList];
    list[index][name] = value;
    // this.setState({serviceList: {xyz: 'new value'}});
    this.setState({serviceList:list},console.log('name',name,'value',value,'state',this.state.serviceList))
  };
  handleServiceRemove = (index) => {
    const list = [...this.state.serviceList];
    list.splice(index, 1);
    this.setState({serviceList:list})
  };
  handleServiceAdd = () => {
    this.setState({serviceList:[...this.state.serviceList, { day: "",from:"",to:"" }]})
  };
  addSlot = async()=>{
    // const checkDay = this.state.serviceList.map(obj => {
    //   if (!obj.day) {
    //     return {...obj, dayError: true};
    //   }
    //   return obj;
    // });
    // this.setState({serviceList:checkDay})
  //   this.state.serviceList.map((n, index) => {
  //   if(!n.day){
  //     this.updateItem(index, {dayError: true});
  //   }
  //   if(!n.from){
  //     this.updateItem(index, {fromError: true});
  //   }
  //   if(!n.to){
  //     this.updateItem(index, {to: true});
  //   }
  // })
  this.setState({btn_loader:true,slotError:false,slotErrorMsg:''})
  const getToken = JSON.parse(localStorage.getItem("token"));
  if(getToken){
    const token = getToken.token;
    var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  var formdata = new FormData();
  this.state.serviceList.map((n)=>{
    formdata.append("day[]", n.day);
    formdata.append("time_from[]", n.from);
    formdata.append("time_to[]", n.to);
  })
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"api/provider/store_booking_slot", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.success == true){
        this.setState({availabilityModal:false,btn_loader:false,serviceList:[],success:true,successMsg:result.message});
        setTimeout(() => {
          this.setState({success: false,successMsg:''});
        }, 2000);
        this.getUSerandSaveDetails();
      }else if(result.error){
        if(result.error.time_to){
          this.setState({slotError:true,slotErrorMsg:result.error.time_to[0],btn_loader:false})
        }
        if(result.error.time_from){
          this.setState({slotError:true,slotErrorMsg:result.error.time_from[0],btn_loader:false})
        }
      }
      else{
        this.setState({slotError:true,slotErrorMsg:result.message,btn_loader:false})
      }
    })
    .catch(error => console.log('error', error));
  }
  }
  deleteSlot = async()=>{
    console.log('slot id',this.state.slotID)
    this.setState({btn_loader:true})
    const getToken = JSON.parse(localStorage.getItem("token"));
    if(getToken){
      const token = getToken.token;
      var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var formdata = new FormData();
formdata.append("slot_id", this.state.slotID);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};
fetch(api_url+"api/provider/delete_booking_slot", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',btn_loader:false });
    }else {
      this.setState({
        btn_loader:false,
        deleteSlotModal: false,
        success: true,
        successMsg: 'Booking Slot Deleted Successfully'
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ success: false,successMsg:'' });
      }, 2000);
      this.getUSerandSaveDetails();
    }
  })
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',btn_loader:false });
  });
    }else{
      console.log('log in first')
    }
  }
  updateItem(id, itemAttributes) {
    var index = this.state.items.findIndex(x=> x.id === id);
    if (index === -1){
      // handle error
    }
    else
      this.setState({
        serviceList: [
           ...this.state.serviceList.slice(0,index),
           Object.assign({}, this.state.serviceList[index], itemAttributes),
           ...this.state.serviceList.slice(index+1)
        ]
      });
  }
  handleModeCheckboxes = (event) => {
    var updatedList = [...this.state.mode];
    if (event.target.checked) {
        updatedList = [...this.state.mode, event.target.value];
    } else {
        // this.state.mode.filter(val=> {return val!==value})
        updatedList.splice(this.state.mode.indexOf(event.target.value), 1);
    }
    this.setState({mode: updatedList})
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
     <div className='bg-gray'>

     <NavbarHome/>
     <div className='profile-page'>
     <div className='content'>
        <Row>
            <Col md={8} lg={8} xs={12}>
            <h2>Profile Settings</h2>
        <h6 className='text-secondary'>General Information</h6>
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <Form.Check type="checkbox" id="name" checked={this.state.userprofileName? this.state.isChecked : false}/> &nbsp;&nbsp; Full Name &nbsp;&nbsp;&nbsp;&nbsp; <span className='text-secondary text-capitalize'>{this.state.userprofileName }</span></Accordion.Header>
         <Accordion.Body>
        Use your full name.
        <Row>
          <Col md={6} lg={6} xs={12}>
            <Form.Control type="text" id="name" name="name" value= {this.state.userprofileName} onChange={(e) => this.setState({userprofileName: e.target.value})}/>
          </Col>
        </Row>
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> <Form.Check type="checkbox" id="location" checked={this.state.completeAddress ? this.state.isChecked : false}/> &nbsp;&nbsp; Location &nbsp;&nbsp;&nbsp;&nbsp; <span className='text-secondary text-capitalize'>{this.state.completeAddress}</span></Accordion.Header>
        <Accordion.Body>
        <div className='d-flex justify-content-between align-items-center'>
          <span> Your Current Location is <span>{this.state.userCurrentCity}, {this.state.userCurrentRegion}, {this.state.userCurrentCountry}</span></span>
          <Button onClick={()=>this.setState({setUserCurrentCity:true},this.update)} disabled={this.state.btn_loader ? true : false}>
          {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'Set your current location'} 
          </Button>
        </div>
        {this.state.showMap ? 
        <>
      <Map
          google={this.props.google}
          zoom={6}
          style={mapStyles}
          initialCenter={{
            lat: this.state.lat,
            lng: this.state.long
          }}
          center={{
            lat: this.state.lat,
            lng: this.state.long
          }}
          >
         <Marker
          position={{
            lat: this.state.lat,
            lng: this.state.long
          }}
          />
        </Map>
        </>
        :
        <div className="loader"></div>
      } 
      {/* <div id="result"></div> */}
      <div id="map"></div>
      <div className='loc-below-map'>
      Enter your hometown or where you would show people around.
      <PlacesAutocomplete
        value={this.state.completeAddress}
        onChange={this.handleChange}
        onSelect={this.handleSelectLoc}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description} className="w-100">
            <div className='d-flex position-relative'>
            <input
              {...getInputProps({
                placeholder:"Search your hometown",
                className: 'form-control location-text-field',
                autoComplete:"nope"
              })}
              
            />
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { background:'#ced4da', padding: '10px', borderBottom:'1px solid #ced4da', cursor:'pointer', color: '#000'}
                  : { background:'#fff', padding: '10px', borderBottom:'1px solid #ced4da', cursor:'pointer', color: '#000' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      </div>
        <div className='d-flex' style={{position:'relative',zIndex:'2'}}>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header> <Form.Check type="checkbox" id="photos" checked={this.state.userprofileImg.length > 0 ? this.state.isChecked : this.state.userprofileSocialImg != '' ? this.state.isChecked : false}/> &nbsp;&nbsp; Profile Photo&nbsp;&nbsp;&nbsp;&nbsp; <span className='text-secondary text-capitalize'>{this.state.uploadedImages.length > 0 ? this.state.uploadedImages.length : this.state.userprofileSocialImg != '' ? '1' : '0'} of 10 photos</span></Accordion.Header>
        <Accordion.Body>
          <Row>
          { this.state.uploadedImages.length >= 10 ?
          ''
          :
          <div className='img-uplaod'>
          <input className="fileInput" type="file" id="profile_img[]" name="profile_img[]"  multiple onChange={this.handleMultipleImages}/>
          <RiCameraFill className='camera-icon'/>
        </div>
          }
        {
        this.state.uploadedImages ? this.state.uploadedImages.map((url,index)=>(
          <div className='position-relative img-box'>
          <img src={url} className="imgPreview" alt="profile" />
          <RiCloseLine className="dlt-img" data-remove={url} onClick={(e)=>this.dltSelectedImg(index,e)} />
          </div>
          
        ))
:
<div className='position-relative img-box'>
          <img src={this.state.userprofileSocialImg} className="imgPreview" alt="profile" />
          </div>
}
          </Row>
        
      
      <div className='d-flex mt-3'>
      <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        {/* <Accordion.Header> <Form.Check type="checkbox" id="language" checked={this.state.isChecked}/> &nbsp;&nbsp; Languages &nbsp;&nbsp;&nbsp;&nbsp; <span className='text-secondary text-capitalize'>{this.state.userprofileLang}</span></Accordion.Header> */}
        <Accordion.Header> <Form.Check type="checkbox" id="language" checked={this.state.userprofileLang ? this.state.isChecked : false}/> &nbsp;&nbsp; Languages &nbsp;&nbsp;&nbsp;&nbsp; 
        <span className='text-secondary text-capitalize'>
          {this.state.userprofileLang ? 
          this.state.userprofileLang.length > 1 ?
          this.state.userprofileLang.length > 3 ? 
          this.state.userprofileLang.slice(0,4).join(', ')+'...'
          :
          this.state.userprofileLang.join(', ')
          : this.state.userprofileLang 
          : ''}
        </span>
        </Accordion.Header>
        <Accordion.Body>
        Select language you can speak.
        <Form.Group controlId="formBasicCheckbox">
          <Row>
            {/* <Form.Select aria-label="Default select example" onChange={this.handleLangChange} id="langs">
             <option>Select Language</option>
             {this.state.language.map((n)=>(
               <option value={n.name}>{n.name}</option>
               )
             )
             }
           </Form.Select> */}
            <Select
          options={this.state.language.map((n)=>(
            {value: n.name, label: n.name}
          ))}
          placeholder="Select Language"
          value={this.state.selectedOptions}
          onChange={this.handleSelect}
          isSearchable={true}
          isMulti
        />
          </Row>
      </Form.Group>
    <div className='text-secondary mt-3 mb-3'>
      {
      this.state.userprofileLang ?
      this.state.userprofileLang.map((n)=>(
        <div className='languages'><RiCloseCircleFill removeLang={n} onClick={this.dltFetchedLang} className='dlt-lang-icon'/><span className='text-capitalize'>{n}</span> </div>
      ))
      :
      ''   
    }
    </div>
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel                
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header> <Form.Check type="checkbox" id="aboutme" checked={this.state.userprofileAbout ? this.state.isChecked : false}/> &nbsp;&nbsp; About me</Accordion.Header>
        <Accordion.Body>
        {/* Important: We love all languages, but we kindly ask to fill out this section in English. Please avoid writing brands, company names and links to other sites in your description. */}
        {/* <div className='d-flex justify-content-between align-items-center'>
          <p>{this.state.userprofileAbout}</p>
          {this.state.userprofileAbout ? 
          <RiPencilFill onClick={()=>this.setState({showAboutField:true})}/>
          :
          <Button variant="primary" onClick={()=>this.setState({showAboutField:true})}>Click to Add Text</Button>
  }
        </div> */}
        {this.state.showAboutField ?
        <>
        <Form.Control as="textarea" className='mt-3' rows={3} placeholder="More about your personality, lifestyles and interests..." value={this.state.userprofileAbout} onChange={(e) => this.setState({userprofileAbout: e.target.value})} />
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </>
        :
        <div className='d-flex justify-content-between align-items-center'>
          {this.state.userprofileAbout ? 
          <p>
          {this.state.userprofileAbout.split("\n").map((item)=> (
            <>
            {item}
            <br/>
            </>
  )
)} 
          </p>
          :''}
          {this.state.userprofileAbout ? 
          <RiPencilFill onClick={()=>this.setState({showAboutField:true})}/>
          :
          <Button variant="primary" onClick={()=>this.setState({showAboutField:true})}>Click to Add Text</Button>
  }
        </div>
        }
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header> <Form.Check type="checkbox" id="email" checked={this.state.userprofileEmail ? this.state.isChecked : false}/> &nbsp;&nbsp; Email &nbsp;&nbsp;&nbsp;&nbsp; <span className='text-secondary'>{this.state.userprofileEmail}</span></Accordion.Header>
        <Accordion.Body>
        Your email address will be used to send you bookings confirmations. It won’t be displayed on your profile.
        <Form.Control type="text" value={this.state.userprofileEmail} onChange={(e) => this.setState({userprofileEmail: e.target.value})}/>
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      {this.state.userRole == "2" || this.state.userRole == 2 ?
      <Accordion.Item eventKey="6">
        <Accordion.Header> <Form.Check type="checkbox" id="email"/> &nbsp;&nbsp; Provider's Settings &nbsp;&nbsp;&nbsp;&nbsp;</Accordion.Header>
        <Accordion.Body>
          Set Your Hourly Rate
          <InputGroup className="my-3">
            <InputGroup.Text id="price">USD</InputGroup.Text>
            <Form.Control placeholder="Enter price" value={this.state.hour_rate} onChange={(e)=>this.setState({hour_rate:e.target.value})} aria-label="price" aria-describedby="price"/>
          </InputGroup>
      <hr/>
      Guide Type
      <div className='my-3 d-flex justify-content-start align-items-center'>
      <div className='square-checkbox'>
        <Form.Check type='radio' name='guide' label='Local' disabled checked={this.state.guide_type == 'local' || this.state.guide_type == 'Local' ? true : false} />
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div className='square-checkbox'>
        <Form.Check type='radio' name='guide' label='Professional' disabled checked={this.state.guide_type == 'local' || this.state.guide_type == 'Local'  ? false : true}/>
      </div>
      </div>
      <hr/>
      Preferred Mode
      <div className='my-3 d-flex justify-content-start align-items-center'>
        <div className='d-flex justify-content-start align-items-center'>
        <div className='square-checkbox'>
          <Form.Check type='checkbox' name='mode' checked={this.state.mode.includes('Walk') || this.state.mode.includes('walk') ? true : false} label='Walk' value='Walk' onChange={(e) => this.handleModeCheckboxes(e)}/>
        </div>
          <RiWalkFill title='Walk' className='p-mode-icons' />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className='d-flex justify-content-start align-items-center'>
        <div className='square-checkbox'>
          <Form.Check type='checkbox' name='mode' checked={this.state.mode.includes('Bike') || this.state.mode.includes('bike') ? true : false} label='Bike' value='Bike' onChange={(e) => this.handleModeCheckboxes(e)}/>
        </div>
          <RiMotorbikeFill title='Bike' className='p-mode-icons' /> 
        </div>
        </div>
        <hr/>
        <FormGroup className='my-3'>
        <Label for="exp">Experience</Label>
        <Form.Select onChange={(e) => this.setState({ experience: e.target.value })}>
          <option disabled selected>Select Your Experience</option>
          <option value="1">1 year</option>
          <option value="2">2 years</option>
          <option value="3">3 years</option>
          <option value="4">4 years</option>
          <option value="5">5 years</option>
          <option value="6">5+ years</option>
      </Form.Select>
      {/* <Form.Control id="exp" type="text" className='w-100' placeholder="Experience" value={this.state.experience} onChange={(e) => this.setState({experience: e.target.value})}/> */}
    </FormGroup>
    <hr/>
    <FormGroup className='my-3'>
    <Label for="web">Official Website</Label>
  <Form.Control id="web" type="text" className='w-100' placeholder="Official Website" value={this.state.officialWeb} onChange={(e) => this.isValidUrl(e)}/>
  {this.state.validWebUrl ? <span className='text-danger'>{this.state.validWebUrl}</span> : ''}
    </FormGroup>
    <hr/>
    <FormGroup className='my-3'>
      <Label for="license">Licence</Label>
  <Form.Control id="license" type="file" className='w-100' placeholder="Licence" onChange={(e) => this.setState({license: e.target.files[0]})}/>
  {this.state.licenseError ? <span className='text-danger'>{this.state.licenseError}</span> : ''}
    </FormGroup>
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      :''}
      {(this.state.userRole == "2" || this.state.userRole == 2) && 
      (this.state.providerSlots.Monday || this.state.providerSlots.Tuesday || this.state.providerSlots.Wednesday || this.state.providerSlots.Thursday || this.state.providerSlots.Friday || this.state.providerSlots.Saturday || this.state.providerSlots.Sunday) ?
      <Accordion.Item eventKey="7">
         <Accordion.Header> <Form.Check type="checkbox" id="slots"/> &nbsp;&nbsp; Available Slots &nbsp;&nbsp;&nbsp;&nbsp;</Accordion.Header>
        <Accordion.Body>
        <div style={{overflowX:'auto', overflowY:'hidden'}}>
  <div className="days">
    {this.state.providerSlots.Monday ?
  <div className="day">
    <div className="datelabel"><strong>Monday</strong></div>
    {this.state.providerSlots.Monday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
    {this.state.providerSlots.Tuesday ?
  <div className="day">
    <div className="datelabel"><strong>Tuesday</strong></div>
    {this.state.providerSlots.Tuesday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
    {this.state.providerSlots.Wednesday ?
  <div className="day">
    <div className="datelabel"><strong>Wednesday</strong></div>
    {this.state.providerSlots.Wednesday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
    {this.state.providerSlots.Thursday ?
  <div className="day">
    <div className="datelabel"><strong>Thursday</strong></div>
    {this.state.providerSlots.Thursday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
    {this.state.providerSlots.Friday ?
  <div className="day">
    <div className="datelabel"><strong>Friday</strong></div>
    {this.state.providerSlots.Friday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
    {this.state.providerSlots.Saturday ?
  <div className="day">
    <div className="datelabel"><strong>Saturday</strong></div>
    {this.state.providerSlots.Saturday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
    {this.state.providerSlots.Sunday ?
  <div className="day">
    <div className="datelabel"><strong>Sunday</strong></div>
    {this.state.providerSlots.Sunday.map((n)=>(
      <>
      <div className="timeslot">
        <div className='time'>
          {moment(n.time_from, "HH:mm").format("hh:mm A")}<br/> - <br/>{moment(n.time_to, "HH:mm").format("hh:mm A")}
        </div>
      <RiCloseLine className="dlt-slot" onClick={(e=>this.setState({slotID:e.currentTarget.id,deleteSlotModal:true}))} id={n.id} />
      </div>
     </>
    ))}
  </div>
  :''}
  </div>
</div>
        </Accordion.Body>
      </Accordion.Item>
      :''}
      {this.state.profileVariables ?
      this.state.profileVariables.length > 0 ?
      this.state.profileVariables.map((n,index)=>(
        // const indexArray = this.state.pv_Values[index];
<Accordion.Item eventKey={index} key={index}>
        <Accordion.Header> <Form.Check type="checkbox" id={n.name} checked={this.state.pv_Values[index] ? 
        this.state.pv_Values[index].value ? this.state.pv_Values[index].value == 'null' || this.state.pv_Values[index].value == 'undefined' ? false : this.state.isChecked : false : false}/> &nbsp;&nbsp; <span className='text-capitalize'>{n.name}</span> &nbsp;&nbsp;&nbsp;&nbsp; 
        {/* <span className='text-secondary'>{indexArray.value}</span> */}
        {/* <span className='text-secondary'>{this.state.pv_Values[index] ? this.state.pv_Values[index].value : ''}</span> */}
        <span className='text-secondary'>{this.state.pv_Values[index] ? 
        this.state.pv_Values[index].value ? 
        this.state.pv_Values[index].value == 'null' || this.state.pv_Values[index].value == 'undefined' ?
        ''
        :
        this.state.pv_Values[index].value
        :
        ''
        : 
        ''
      }</span>
        </Accordion.Header>
        <Accordion.Body className='text-capitalize'>
        {n.name}
        {n.type == 'text' ?
        <Form.Control type={n.type} placeholder={'Enter '+n.name} name={n.name} id={n.id}
        onChange={(event) => this.handleFormChange(index, event)}
        />
        :
        n.type == 'checkbox' ? 
        n.options.map((o)=>(
          <div className='square-checkbox'>
            <Form.Check type='checkbox' label={o} value={o} onChange={event => this.handleFormChange(index, event)}/>
          </div>
        ))
        :
        n.type == 'radiobutton' ? 
        n.options.map((o)=>(
          <div className='square-checkbox'>
          <Form.Check type='radio' name={n.name} label={o} value={o} onChange={event => this.handleFormChange(index, event)}/>
          </div>
        ))
        :
        n.type == 'dropdown' ? 
        <Form.Select aria-label="Default select example" onChange={event => this.handleFormChange(index, event)}>
<option>Select {n.name}</option>
{n.options.map((o)=>(
          <option value={o}>{o}</option>
        ))}
</Form.Select>
        :
        ''
  }
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2" onClick={this.update} disabled={this.state.btn_loader ? true : false}>
            {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm"/> : 'SAVE'} 
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
  // })
      ))
    :'':''}
    </Accordion>
    
    {/* <h6 className='text-secondary mt-3'>Local host information</h6>
    <Accordion  className='text-secondary mt-3'>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <Form.Check type="checkbox" id="moto"/> &nbsp;&nbsp; Moto</Accordion.Header>
        <Accordion.Body>
        Your motto is the first thing others will read about you. Write a catchy line that best describes you as a host.<br/>

        Important: We love all languages, but we kindly ask to fill this section out in English. Also please avoid using brand and company names or links to other websites.
        <Form.Control as="textarea" className='mt-3' rows={3} placeholder="A short text that says why the visitor should choose you..."/>
        
        <div className='d-flex'>
        <Button variant="primary" id="button-addon2">
            SAVE
        </Button>&nbsp;&nbsp;
        <Button variant="light" id="button-addon2">
            Cancel
        </Button>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> <Form.Check type="checkbox" id="show"/> &nbsp;&nbsp; I will Show you</Accordion.Header>
        <Accordion.Body>
        How would you describe the ideal tour around your city? Be specific.
<Form.Control as="textarea" className='mt-3' rows={3} placeholder="Tell us about the tour in more details..."/>

<div className='d-flex'>
<Button variant="primary" id="button-addon2">
    SAVE
</Button>&nbsp;&nbsp;
<Button variant="light" id="button-addon2">
    Cancel
</Button>
</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header> <Form.Check type="checkbox" id="activity"/> &nbsp;&nbsp; Activities</Accordion.Header>
        <Accordion.Body>   
        What activities do you do with your visitors?
        <Row>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="History & Culture" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Pick up & Driving Tours" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Food & Restaurants" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Art & Museums" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Nightlife & Bars" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Shopping" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Exploration & Sightseeing" className='mb-3'/>
            </Col>
            <Col md={4} lg={4} xs={6}>
            <Form.Check type="checkbox" label="Translation & Interpretation" className='mb-3'/>
            </Col>
        </Row>
        <div className='text-danger'>Select atleast one</div>

<div className='d-flex'>
<Button variant="primary" id="button-addon2">
    SAVE
</Button>&nbsp;&nbsp;
<Button variant="light" id="button-addon2">
    Cancel
</Button>
</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header> <Form.Check type="checkbox" id="language"/> &nbsp;&nbsp; Hourly Rate&nbsp;&nbsp;&nbsp;&nbsp; <span className='text-secondary'>Free</span></Accordion.Header>
        <Accordion.Body>
        <Form.Check type="checkbox" label="I want to show around for free" className='mb-3'/>
        <div>
        This is your hourly rate. You can change it later if you want.
        <InputGroup className="mb-3">
        <DropdownButton
          variant="outline-secondary"
          title="EUR"
          id="input-group-dropdown-1"
        >
          <Dropdown.Item href="#">Action</Dropdown.Item>
          <Dropdown.Item href="#">Another action</Dropdown.Item>
          <Dropdown.Item href="#">Something else here</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#">Separated link</Dropdown.Item>
        </DropdownButton>
        <Form.Control placeholder="5" />
      </InputGroup>
        </div>
        <div>
        Choose your minimum tour duration:
        <Form.Select aria-label="Default select example">
                      <option>1 hour</option>
                      <option value="1">Two People</option>
                      <option value="2">Three People</option>
                      <option value="3">More than three People</option>
                  </Form.Select>
        </div>
        <div className='d-flex'>
<Button variant="primary" id="button-addon2">
    SAVE
</Button>&nbsp;&nbsp;
<Button variant="light" id="button-addon2">
    Cancel
</Button>
</div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion> */}
            </Col>
            <Col md={4} lg={4} xs={12}>
                <Card className='mt-5'>
                    <Card.Header>
                    {this.state.userRole == "2" || this.state.userRole == 2 ?
                    <>
                    <button className='slot-button' onClick={()=>this.setState({availabilityModal:true,slotError:false,slotErrorMsg:''})}><RiTimeLine/><span>Set your Availability</span></button>
                    <hr/>
                    </>
                    :''}
                        <div><strong>More Info</strong></div>
                        <div className='text-primary'><Link to="/booking_advice">Booking Advice</Link></div>
                        <div className='text-primary'><Link to="/view-profile">View Profile</Link></div>
                    </Card.Header>
                    <Card.Body>
                    <div><strong>Need Assistance?</strong></div>
                    <div>Contact our <span className='text-primary'> Customer Support </span>if you need any assistance setting up or managing your account.</div>
                    </Card.Body>
                    <Card.Footer>
                    Don't want to be on Dinfoo.com anymore? <span className='text-primary'>Close your account.</span> 
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
       
     </div>
     </div>
    <Footer/>
     {/* add slot modal */}
     <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.availabilityModal} 
      onHide={() => this.setState({ availabilityModal: false})} 
      className="signup-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>Set Your Available Slots</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.serviceList.map((singleService,index)=>(
                <div key={index} className="services">
                  <div className="first-division">
                    <Row className='mb-3 d-flex justify-content-start align-items-end'>
          <Col md={4} lg={4} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className='float-left'>Day <span className='text-danger'>*</span></Form.Label>
                    <Form.Select aria-label="Default select example" className='mb-0' name="day" onChange={(e) => this.handleServiceChange(e, index)}>
                      <option selected>Select a Day </option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                  </Form.Select>
                  {singleService.dayError ? <span className='text-danger'>This Day Field is required</span> : ''}
                </Form.Group>
          </Col>
          <Col md={3} lg={3} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className='float-left'>From<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="time" className='mb-0' name="from" value={singleService.from} onChange={(e) => this.handleServiceChange(e, index)}/>
                  {singleService.fromError ? <span className='text-danger'>This From Field is required</span> : ''}
                </Form.Group>
          </Col>
          <Col md={3} lg={3} xs={12}>
          <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className='float-left'>To<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="time" className='mb-0' name="to" value={singleService.to} onChange={(e) => this.handleServiceChange(e, index)} />
                  {singleService.toError ? <span className='text-danger'>This To Field is required</span> : ''}
                </Form.Group>
          </Col>
          <Col md={1} lg={1} xs={12}>
          {this.state.serviceList.length !== 1 && (
                      <Button onClick={() => this.handleServiceRemove(index)} variant="danger" className='py-2'><RiDeleteBin6Line/></Button>
                    )}
          </Col>
          <Col md={1} lg={1} xs={12}>
                     {this.state.serviceList.length - 1 === index && this.state.serviceList.length < 4 && (
                      <Button onClick={this.handleServiceAdd} variant='success' className='py-2'><RiAddCircleFill/></Button>
                    )}
          </Col>
        </Row>     
                   
                  </div>
                  {/* <div className="second-division">
                    {this.state.serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => this.handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>Remove</span>
                      </button>
                    )}
                  </div> */}
                </div>
        ))}
        <Row>
          {this.state.slotError ? <span className='text-danger'>{this.state.slotErrorMsg}</span>:''}
        </Row>
    <button className='float-right continue-btn' onClick={this.addSlot}>
      {this.state.btn_loader ? 
       <>
       &nbsp;&nbsp;
       <Spinner animation="border" variant="light" size="sm"/> 
       &nbsp;&nbsp;
       </> 
       :
       <> &nbsp;&nbsp; <span>Add a Slot</span> &nbsp;&nbsp;</>
      }
    </button>
      </Modal.Body>
    </Modal>
    {/* delete slot modal */}
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.deleteSlotModal} 
      onHide={() => this.setState({ deleteSlotModal: false})} 
      className="signup-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>Delete Slot</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       Are you sure to delete this slot?
       {this.state.error ? <span>{this.state.errorMsg}</span>:''}
      </Modal.Body>
       <Modal.Footer className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button className='w-100' onClick={this.deleteSlot}>{this.state.btn_loader ? <Spinner type="border" color="light" size="sm"/> : 'Yes'}</Button>{' '}
      </Col>
      <Col>
      <Button className='w-100 btn-secondary' onClick={()=>this.setState({deleteSlotModal: false})}>No</Button>
      </Col>
    </Modal.Footer>
    </Modal>
    {this.state.updateAlert ? 
    <Alert key='success' variant='success' className='log-alert'>
    Profile has been updated!
  </Alert>
  :
    this.state.errorAlert ? 
    <Alert key='danger' variant='danger' className='log-alert' dismissible onClose={()=>this.setState({errorAlert: false})}>
    Something Went Wrong!
  </Alert>
  :
  this.state.success ? 
  <Alert key='success' variant='success' className='log-alert'>
  {this.state.successMsg}
</Alert>
:
  ''
    }
     </div>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: (GOOGLE_API_KEY)
})(ProfileSettings);