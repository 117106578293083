import React , {Component} from 'react';
import Navbar from "../Components/NavbarHome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../Components/Footer";
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import "../Assets/Styles/style.css";
import {Redirect} from 'react-router-dom'
//images
import user from '../Assets/Images/user.png'
//slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class ViewProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userName:'',
          userImage:[],
          userSocialImage:'',
          userLang:[],
          userRole:'',
          redirect: null,
          userCity:'',
          userCountry:'',
          userOverview:'',
          profileVariables:[],
          pv_Values:[],
          seeker_provider: localStorage.getItem('seekerORprovider') || 'seeker',
          // fbloggedIn:false, 
          // googleloggedIn:false,
        };
      }
      componentDidMount(){
        this.getUSerDetails()
        window.addEventListener("storage",(e) => {
          console.log('storage changed')
          const role = (localStorage.getItem("seekerORprovider"));
          this.setState({seeker_provider: role},()=>{
            this.getUSerDetails();
          })
         });
    
        const getToken = JSON.parse(localStorage.getItem("token"));
        // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
        // const googleLogIn = (JSON.parse(localStorage.getItem('googleLogIn')));
        // if(getToken || fbLogIn || googleLogIn){
        if(getToken){
          console.log('user logged in')
        }else{
          this.setState({ redirect: "/" });
        }
      }
      getUSerDetails =async()=>{
        const userDetail = JSON.parse(localStorage.getItem("user-info"));
        this.setState({userName: userDetail.profile.name, userImage: userDetail.profile_images ? userDetail.profile_images: '',userRole:userDetail.profile.role_id, 
        userLang: userDetail.User_languages, userCity:userDetail.profile.location,
      userOverview:userDetail.profile.about, pv_Values:userDetail.profile_variables_values   },()=>{
        if(this.state.userRole === '4' || this.state.userRole === 4){
          // const seekerORprovider = localStorage.getItem('seekerORprovider');
          // if(seekerORprovider){
          //   this.setState({seeker_provider:seekerORprovider},()=>{
              console.log('user is',this.state.seeker_provider)
              if(this.state.seeker_provider === 'provider'){
                this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
                  console.log('user is provider',this.state.profileVariables)
                })
              }else{
                this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
                  console.log('user is seeker',this.state.profileVariables)
                })
              }
            // })
          // }else{
          //   this.setState({seeker_provider:'seeker'},()=>{
          //     console.log('user is',this.state.seeker_provider)
          //   })
          // }
        }
        else if((this.state.userRole == '2') || ((this.state.userRole == '4') && (this.state.seeker_provider === 'provider'))){
          this.setState({profileVariables:userDetail.provider_profile_variables},()=>{
            console.log('user is provider',this.state.profileVariables)
          })
        }
        else if((this.state.userRole == '3') || ((this.state.userRole == '4') && (this.state.seeker_provider === 'seeker'))){
          this.setState({profileVariables:userDetail.seeker_profile_variables},()=>{
            console.log('user is seeker',this.state.profileVariables)
          })
        }else{
          this.setState({profileVariables:[]},()=>{
            console.log('user is no one',this.state.profileVariables)
          })
        }
      });
      }
render(){
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  if (this.state.redirect) {
    return <Redirect to={this.state.redirect} />
  }
    return(
        <div className='bg-gray'>
      <Navbar />
      <div className='local-banner-img'>
            <div className='local-banner-content'>
              {this.state.userSocialImage ? 
              <img src={this.state.userSocialImage} alt="user" className='guide-carousel'/>
              :
              this.state.userImage.length > 0 ?
              this.state.userImage.length > 1 ?
                <Slider {...settings} className="guide-top-carousel">
              {this.state.userImage.map((n)=>(
                <div>
                    <img src={n} alt="user" className='guide-carousel'/>
                </div>
              ))}
            </Slider>
            :
                <img src={this.state.userImage} alt="user" className='guide-carousel'/>
                :
                <img src={user} alt="user" className='guide-carousel'/>
              }
            <div className='carousel-side-content'>
            <div className="d-flex justify-content-between name-price">
                      <div>
                        <h5 className="card-title">{this.state.userName}</h5>
                        <p className="card-text d-flex location-txt">
                          {this.state.userCity? this.state.userCity:'' }  
                        </p>
                      </div>
                      {/* <div className="price">
                        <strong>$25</strong>/h
                      </div> */}
                    </div>
                    {/* <blockquote>
                      <q>lets travel and find something new and delicious</q>
                    </blockquote> */}
            </div>
            </div>
            <div className='mbl-local-banner-content'>
              {this.state.userImage.length >0 ?
              <Slider {...settings} className="guide-top-mbl-carousel">
                 {this.state.userImage.map((n)=>(
               <div className='position-relative'>
               <img src={n} alt="carousel" className='guide-carousel'/>
               <div className="mbl-name-price">
                 <div>
                   <div className='d-flex'>
                   <h2 className="card-title">{this.state.userName}</h2>
                  
                   {/* {this.state.reviews.length > 0 ?
                   this.state.provider.review[0].average_ratings ?
                    <DynamicStar rating={this.state.provider.review[0].average_ratings} outlined={true} width='25' height='25' className="justify-content-center"/>
                    :
                    <DynamicStar rating={0} outlined={true} width='25' height='25' className="justify-content-center"/>
       :
       ''}             */}
       </div>
                   <p className="card-text">{this.state.userCity? this.state.userCity:'' } </p>
                        
                 </div>
                 {/* <h1 className="price"><strong>$25</strong>/h</h1> */}
                 </div>
           </div>
              ))}
              </Slider>
              :
              <>
              <img src={user} alt="user" className='guide-carousel position-relative w-100'/> 
              <div className="mbl-name-price" style={{position:'absolute', bottom:'10px', left:'20px'}}>
                      <div>
                        <div className='d-flex'>
                        <h2 className="card-title">
                        {this.state.userName}</h2>
                        {/* {this.state.reviews.length > 0 ?
                        this.state.provider.review[0].average_ratings ?
            <DynamicStar rating={this.state.provider.review[0].average_ratings} outlined={true} width='25' height='25' className="justify-content-center"/>
            :
            <DynamicStar rating={0} outlined={true} width='25' height='25' className="justify-content-center"/>
            :
            ''}             */}
            </div>
                        <p className="card-text">{this.state.userCity? this.state.userCity:'' } </p>
                      </div>
                      {/* <h1 className="price"><strong>$25</strong>/h</h1> */}
                      </div>
                      </>
  }
            </div>
            {/* <div className='mbl-local-banner-content'>
              <div className='position-relative'>
                    <img src={guide1} alt="carousel" className='guide-carousel'/>
                    <div className="mbl-name-price">
                      <div>
                        <div className='d-flex'>
                        <h2 className="card-title">Bazil</h2>
                        <div class="rate">
                            <input type="radio" id="localstar5" name="localstar5" value="5"/>
                            <label for="localstar5" title="text">5 stars</label>
                            <input type="radio" id="localstar4" name="localstar4" value="4"/>
                            <label for="localstar4" title="text">4 stars</label>
                            <input type="radio" id="localstar3" checked name="localstar3" value="3"/>
                            <label for="localstar3" title="text">3 stars</label>
                            <input type="radio" id="localstar2" name="localstar2" value="2"/>
                            <label for="localstar2" title="text">2 stars</label>
                            <input type="radio" id="localstar1" name="localstar1" value="1"/>
                            <label for="localstar1" title="text">1 star</label>
                          </div>
                        </div>
                        <p className="card-text">Lahore, Pakistan</p>
                      </div><h1 className="price"><strong>Free</strong></h1></div>
                </div>
            </div> */}
        </div>
        <div className='mbl-local-content'>
        {/* <blockquote>
          <q>lets travel and find something new and delicious</q>
        </blockquote> */}
        <Card className='mbl-trip-card'>
      <Card.Body>
      <button className='contact-button'><Link to="/profile-settings"> Edit My Profile</Link></button>
      </Card.Body>
    </Card>
        </div>
      <Row className='content'>
        <Col md={8} lg={8} xs={12}>
        <div className='local-content'>
          {this.state.userLang ?
          this.state.userLang.length > 0 ?
        <Row  className='border-bottom mt-3'>
            <Col md={4} lg={4} xs={12}>
                <div>
                  <strong>Languages</strong>
                </div>
            </Col>
            <Col md={8} lg={8} xs={12}>
                <div>{this.state.userLang.join( ' , ')}</div>
            </Col>
        </Row>
        :
        ''
        :
        ''
          }
        <br/>
        {this.state.userOverview ? 
        <Row  className='border-bottom mt-3'>
            <Col md={4} lg={4} xs={12}>
                <div>
                  <strong>About me</strong>
                </div>
            </Col>
            <Col md={8} lg={8} xs={12}>
                {/* <div>{this.state.userOverview}</div> */}
                {this.state.userOverview ? 
          <p>
          {this.state.userOverview.split("\n").map((item)=> (
            <>
            {item}
            <br/>
            </>
  )
)} 
          </p>
          :''}
            </Col>
        </Row>
        :
        ''}
        <br/>
        {this.state.profileVariables ? 
        this.state.profileVariables.length > 0 ?
        <>
        {this.state.profileVariables.map((n,index)=>(
            this.state.pv_Values[index] ? 
            this.state.pv_Values[index].value ?
            this.state.pv_Values[index].value === 'null' || this.state.pv_Values[index].value === 'undefined' ?
            ''
            :
          <Row  className='border-bottom mt-3' key={index}>
           <Col md={4} lg={4} xs={12}>
               <div>
                 <strong className='text-capitalize'>{n.name}</strong>
               </div>
           </Col>
           <Col md={8} lg={8} xs={12}>
               <span className='text-capitalize'> </span> 
               <span className='text-capitalize'> {this.state.pv_Values[index] ? this.state.pv_Values[index].value : ''} </span> 
           </Col>
       </Row>
            : 
            ''
           :
           ''
        ))}
        {/* <Row  className='border-bottom mt-3'>
        <Col md={4} lg={4} xs={12}>
            <div>
              <strong>Profile Variables</strong>
            </div>
        </Col>
        <Col md={8} lg={8} xs={12}>
        <div>
          {this.state.profileVariables.map((n)=>(
            <>
            <span className='text-capitalize'>{n.name}</span><br/>
            </>
          ))}
          </div>
        </Col>
    </Row> */}
        </>
    :
    ''
  :
  ''}
      </div>
        </Col>
        <Col md={4} lg={4} xs={12}>
        <div className='review-card'>
      <Card>
        {/* <Card.Header className='d-flex flex-column'>
            <center>
            <div class="rate">
                            <input type="radio" id="guidestar5" name="guidestar5" value="5"/>
                            <label for="guidestar5" title="text">5 stars</label>
                            <input type="radio" id="guidestar4" name="guidestar4" value="4"/>
                            <label for="guidestar4" title="text">4 stars</label>
                            <input type="radio" id="guidestar3" name="guidestar3" value="3"/>
                            <label for="guidestar3" title="text">3 stars</label>
                            <input type="radio" id="guidestar2" name="guidestar2" value="2"/>
                            <label for="guidestar2" title="text">2 stars</label>
                            <input type="radio" id="guidestar1" name="guidestar1" value="1"/>
                            <label for="guidestar1" title="text">1 star</label>
                          </div>
            </center>
                          <div>No reviews yet</div>
            
            </Card.Header> */}
      <Card.Body>
      <button className='contact-button'><Link to="/profile-settings"> Edit My Profile</Link></button>
      </Card.Body>
    </Card>
      </div>
        <Card className="more-info-card">
      <Card.Body>
        <strong>More Info</strong>
        <div className='text-primary'><Link to="/about">About Dinfoo</Link></div>
        <div className='text-primary'><Link to="/booking_advice">Booking Advice</Link></div>
        <hr/>
        <strong>Need Assistance?</strong>
        <div> Contact our Customer Support if you need any assistance managing your bookings.</div>
       
      </Card.Body>
    </Card>
        </Col>
      </Row>
      {/* <div className='bg-white'>
        <div className='local-review'>
            <h2>0 Reviews</h2>
        </div>
    </div> */}
      <Footer/>
        </div>
    )
}
}