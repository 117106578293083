import React, {Component} from "react";
import Banner from "../Components/Banner";
import Navbar from "../Components/NavbarHome";
import {Row,Col,Button,Badge,Card, OverlayTrigger,Tooltip } from "react-bootstrap";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Cookies from "../Components/Cookies";
import "../Assets/Styles/style.css";
import { DynamicStar } from 'react-dynamic-star';
import { api_url } from "../Variables";
import { RiBikeFill, RiMotorbikeFill, RiWalkFill} from "react-icons/ri";
//icons
// import {GoSettings} from "react-icons/go";
// import {GoFold} from "react-icons/go";
// import {GoFileBinary} from "react-icons/go";
// import {RiShip2Fill} from "react-icons/ri";
// import {RiCommunityLine} from "react-icons/ri";
//slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//images
import booking_bg from '../Assets/Images/booking-bg.jpg';
import booking1 from '../Assets/Images/booking1.jpg';
import booking2 from '../Assets/Images/booking2.jpg';
import user from '../Assets/Images/user.png'
export default class Home extends Component {
  constructor(props){
    super(props);
    this.state={
      categories: [''],
      providers: [''],
      provider_category:[],
      booking:[''],
      category_id:'',
      isloading: true,
      isLoggedIn: false,
      proLoader:false
    }
  }
  componentDidMount= async()=>{
    // this.getCategories();
    this.getProviders();
    // this.getBookings();
    const getToken = JSON.parse(localStorage.getItem("token"));
    if(getToken){
      this.setState({isLoggedIn:true});
    }
    else{
      this.setState({isLoggedIn: false})
    }
  }
  getCategories = async()=>{
    let fetchCategories = await fetch(api_url+"api/categories",{
            method: "get",
            headers: {
            'Accept': '*/*'
            }
          });
          fetchCategories = await fetchCategories.json();
          if(fetchCategories){
            this.setState({categories: fetchCategories, isloading: false})
          }
          console.log('categories', fetchCategories);
  }
  getProviders = async()=>{
    this.setState({proLoader:true})
    let fetchProviders = await fetch(api_url+"api/Providers",{
            method: "get",
            headers: {
            'Accept': '*/*'
            }
          });
          fetchProviders = await fetchProviders.json();
          if(fetchProviders){
            this.setState({providers: fetchProviders.data,proLoader:false})
          }
          console.log('providers', fetchProviders);
  }
  getBookings = async()=>{
    let fetchBookings = await fetch(api_url+"api/bookings",{
            method: "get",
            headers: {
            'Accept': '*/*'
            }
          });
          fetchBookings = await fetchBookings.json();
          if(fetchBookings){
            this.setState({booking: fetchBookings})
          }
          console.log('bookings', fetchBookings);
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ],
    };
    const renderTooltip = (text) => (
      <Tooltip id="button-tooltip">
        {text}
      </Tooltip>
    );
    return (
      <>
        <Navbar />
        <Banner />
        <div className="body-bg">
        <div className="content">
          {/* <div className="tour-carousel">
            <center>
            {this.state.isloading ? 
              <div class="loader"></div>
              :
              <Slider {...settings}>
              { this.state.categories ?
              this.state.categories.map((n)=>(
                <center> 
                  <Link to={{pathname: "/category/"+ n.id, state : n.id}}>
                  <img src={n.category_image} alt="" className="carousel-icon"/>
                <p>{n.name}</p>
                  </Link>
                </center>               
              )
              )
              :
              ''
            }
              </Slider>
            }
            </center>
           
          </div> */}
          {this.state.providers ?
          <>
           <center>
            <h5>Find your local tour guide with Dinfoo</h5>
          </center>
          <Row className="mt-4">

            {this.state.proLoader ? 
            <center>
              <div class="lds-facebook"><div></div><div></div><div></div></div>
            </center>
            :
            this.state.providers.map((n)=>(
              <Col md={6} lg={6} xs={12}>
                 <Link to={{pathname: "/provider/"+ n.id, state : n.id}}>
               <div className="card">
                 <div className="card-horizontal">
                   <div className="img-square-wrapper">
                     <img className="" src={n.profile_image != null ? n.profile_image : user} alt="property" />
                     {n.seller_type ?
                     <span className="seller-type">{n.seller_type}</span>
                     :''}
                   </div>
                   <div className="card-body">
                     <div className="d-flex justify-content-between name-price">
                       <div>
                        <div className="d-flex justify-content-start align-items-center">
                          <h5 className="card-title text-capitalize mr-3">{n.name}</h5>
                          {n.preferred_modes ?
                          n.preferred_modes.length > 0 ?
                          n.preferred_modes.map((n)=>(
                            <>
                            <> 
                            {n == 'walk' || n == 'Walk' ?
                            <RiWalkFill className="provider-mode"/>
                            :''}
                            </>
                            <>
                            {n == 'bike' || n == 'Bike' ?
                            <RiBikeFill className="provider-mode"/>
                            :''}
                            </>
                            </>
                          ))
                        :'':''}
                        </div>
                         <p className="card-text d-flex location-txt">
                           {n.address ? n.address :''}
                         </p>
                       </div>
                       {n.hourly_rate ? 
                        <div className="price">
                          <strong >${n.hourly_rate}</strong>/h
                       </div> 
                       :''}
                     </div>
                     {n.about ? 
                     <blockquote>
                      <q>{n.about}</q>
                     </blockquote>
                     :
                     ''
                    }
                      {n.review ?
                      n.review[0] ?
                     <div className="d-flex justify-content-between ratings">
                       <div className="d-flex flex-column justify-content-between review ">
                         <div><center>Reviews</center></div>
                         <h6  className="mb-0">
                           <center  className="mt-3">
                             <strong>{n.review[0] ? n.review[0].total_no_of_reviews : ''}</strong>
                           </center>
                         </h6>
                       </div>
                       <div className="d-flex flex-column justify-content-between review">
                         <div>
                           <center>Ratings</center>
                         </div>
                         <center>
                         <DynamicStar rating={n.review[0] ? n.review[0].average_ratings : ''} outlined={true} width='15' height='15'/>
                         </center>
                       </div>
                     </div>
                     :
                     ''
                      :
                      ''}
                   </div>
                 </div>
               </div>
               </Link>
             </Col>
            ))
            }
            </Row>
          </>
          :
          ''}           
          {/* <div className="trip-section">
            <h4>SAVE TIME: BOOK A SERVICE & GET OFFERS FROM LOCALS</h4>
            <div className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="number">1</div>
                <div className="txt">Book a service</div>
              </div>
              <div className="d-flex">
                <div className="number2">2</div>
                <div className="txt">Get offers from locals </div>
              </div>
              <div className="d-flex">
                <div className="number3">3</div>
                <div className="txt">Pick the one you like</div>
              </div>
            </div>
            <Link to="/search"><Button className='primary-btn mt-5'>BOOK A SERVICE</Button></Link>
          </div>
          <center>
            <h2 className="mt-5 mb-5">Recent Bookings</h2>
          </center>
          <Row>
            {this.state.booking.map((n)=>(
 <Col md={4} lg={4} xs={12}>
 <Card className="booking-card">
<Card.Img variant="top" src={booking_bg} />
<Card.ImgOverlay className="overlay-txt">
<img src={n.booking ? n.booking.profile_image != null ? n.booking.profile_image : user : user} alt="booking" className="booking-card-top-img"/>
<div className="price-tag">
<strong>{new Intl.NumberFormat().format(n.price)}</strong>
</div>
<center>
<strong className="text-capitalize">{n.seeker_name}</strong> booked a tour with <strong className="text-capitalize"> {n.provider_name ? n.provider_name[0].first_name || n.provider_name[0].last_name ? n.provider_name[0].first_name + " " + n.provider_name[0].last_name : n.provider_name[0].name : 'Provider'}</strong> in <strong className="text-capitalize"> {n.place}</strong>
</center>
</Card.ImgOverlay>
<Card.Body>
<Card.Text className="text-secondary">
  <center>
  Tour booked from <em>{n.date_from}</em> to <em>{n.date_to}</em>. <br/>
  Tour Status: <Badge pill bg={n.status_text === 'Pending' ? "primary" : n.status_text === 'Cancelled By Seeker' || n.status_text === 'Cancelled By Provider' ? "danger" : n.status_text === 'Completed By Provider' ||  n.status_text === 'Completed By Seeker' ? "success" : "warning"} className="text-capitalize">  {n.status_text} </Badge>
  </center>
</Card.Text>
</Card.Body>
</Card>
 </Col>
            ))}
           
          </Row> */}
          {/* <center>
            <button className="show-more-btn">show more</button>
          </center> */}
        </div>
        </div>
       <Footer/>
       <Cookies/>
      </>
    );
  }
}