import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Navbar from "../Components/NavbarHome";
import {Row,Col,Card,Spinner,Alert,Modal,Form} from "react-bootstrap";
import Footer from "../Components/Footer";
import { Link } from 'react-router-dom';
import { RiBookmarkLine } from "react-icons/ri";
import "../Assets/Styles/style.css";
//images
import user from '../Assets/Images/user.png'
import { api_url } from "../Variables";
class Service extends Component {
    constructor(props){
        super(props);
        this.state = {
            serviceID: this.props.location.state.service_id,
            providerID: this.props.location.state.provider_id,
            serviceResult: [''],
            serviceNAme:'',
            servicePrice:'',
            serviceImg:'',
            serviceProvider:'',
            serviceType:'',
            serviceDesc:'',
            token:'',
            show : false,
            loginAlert: false,
            registerAlert: false,
            markasFav:false,
            markFavLoader:false,
            success:false,
            successMsg:'',
            allFavs:[],
            itemID:'',
            noteModal:false,
            noteText:''
            // fbloggedIn:false,
            // googleloggedIn:false,
        }
    }
    componentDidMount = async()=>{
      const getToken = JSON.parse(localStorage.getItem("token"));
      // const fbLogIn = (JSON.parse(localStorage.getItem('fbLogIn')));
      // const googleLogIn = (JSON.parse(localStorage.getItem('googleLogIn')));
      if(getToken){
        const token = getToken.token;
        console.log('user logged in')
        this.setState({token: token})
        this.getFavoriteList()
      }
      // else if(fbLogIn){
      //   this.setState({fbloggedIn:true},()=>{
      //     console.log('user logged in from fb')
      //   })
      // }
      // else if(googleLogIn){
      //   this.setState({googleloggedIn:true},()=>{
      //     console.log('user logged in from google')
      //   })
      // }
      else{
        this.setState({token:''})
      }
      this.getServiceDetail()
      
    }
    getServiceDetail = async() =>{
      console.log('service id is: ', this.state.serviceID, ' provider id is: ', this.state.providerID);
      let service = await fetch(api_url+"api/service/detail/"+this.state.serviceID,{
        method: "get",
        headers: {
        'Accept': 'application/json'
        }
      });
      service = await service.json();
      if(service){
        console.log('service details', service);
        this.setState({serviceResult: service, serviceNAme: service.name, servicePrice: service.price, 
          serviceType: service.service_type, serviceImg: service.service_image, 
          serviceProvider : service.provider_name}, () => {
         });
       }
       else{
        console.log('Error is: ',  service.Result)
       }
    }
    markFavorite = async()=>{
      this.setState({markFavLoader:true})
      const getToken = JSON.parse(localStorage.getItem("token"));
      if(getToken){
        const token = getToken.token;
        var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  var formdata = new FormData();
  formdata.append("service_id", this.state.serviceID);
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"api/seeker/save_favourite_service", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(result.error){
        this.setState({markFavLoader:false})
      }else{
        this.setState({markFavLoader:false,markasFav:true,success:true,successMsg:result.Result})
      setTimeout(() => {
        this.setState({success:false,successMsg:''})
      }, 3000);
      }
    })
    .catch(error => {
      console.log('error', error)
      this.setState({markFavLoader:false})
    });
      }
      }
      getFavoriteList = async()=>{
        var myHeaders = new Headers();
        const gettoken = JSON.parse(localStorage.getItem("token"));
        const userToken = gettoken.token;
        myHeaders.append("Authorization", `Bearer ${userToken}`);
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(api_url+"api/seeker/show_all_favourites", requestOptions)
          .then(response => response.json())
          .then(result => {
            result.map((n)=>{
              if(n.service_id){ // from favorites list of providers and services, fetch only services
                this.setState(prevState => ({
                  allFavs: [...prevState.allFavs, n] 
                }),()=>{
                  console.log('fav services are', this.state.allFavs)
                  const isFound = this.state.allFavs.some(element => { 
                    if(element.service_id == this.state.serviceID){
                      this.setState({itemID:element.id},()=>{
                        console.log(this.state.itemID)})
                      return true;
                    }
                    else{
                      return false
                    }
                  });
                  if(isFound){
                    console.log('matcheddddd')
                    this.setState({markasFav:true})
                  }
                })
                // this.setState({allFavs:...this.state.allFavs,n})
              }
            })
          })
          .catch(error => console.log('error', error));
      }
      makeFavNote = async()=>{
        this.setState({isloading:true,errorMsgTxt:''});
        if(this.state.noteText){
          var myHeaders = new Headers();
          const gettoken = JSON.parse(localStorage.getItem("token"));
          const userToken = gettoken.token;
          myHeaders.append("Authorization", `Bearer ${userToken}`);
    
    var formdata = new FormData();
    formdata.append("item_id", this.state.itemID);
    formdata.append("note", this.state.noteText);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"api/seeker/make_note_on_favourite", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if(result.error){
          this.setState({errorAlert:true, errorMsg:result.error,isloading:false})
        }else{
          this.setState({success:true, successMsg:result.Result, noteModal:false, isloading:false});
          setTimeout(() => {
            this.setState({success:false, successMsg:''});
          }, 3000);
        }
      })
      .catch(error => {
        console.log('error', error)
        this.setState({isloading:false})
      });
        }else{
          this.setState({errorMsgTxt:'The Note field is required',isloading:false})
        }
         
      }
  render() {
    return (
        <div className='bg-gray'>
        <Navbar />
        <div className='local-banner-img'>
              <div className='local-banner-content'>
                  <img src={this.state.serviceImg ? this.state.serviceImg: user} alt="user" className='guide-carousel'/>
              <div className='carousel-side-content'>
              <div className="d-flex justify-content-between name-price">
                        <div>
                          <h5 className="card-title">{this.state.serviceNAme}</h5>
                          {/* <p className="card-text d-flex location-txt">
                            Lahore, Pakistan
                          </p> */}
                        </div>
                        {this.state.servicePrice ? 
                        <div className="price">
                          <strong>{new Intl.NumberFormat().format(this.state.servicePrice)}/</strong>{this.state.serviceType}
                        </div>
                        :
                        ''
                      }
                      </div>
                      {this.state.serviceDesc ? 
                      <blockquote>
                        <q>{this.state.serviceDesc}</q>
                      </blockquote>
                      :
                      ''
  }
              </div>
              </div>
              <div className='mbl-local-banner-content'>
                <div className='position-relative'>
                      <img src={this.state.serviceImg ? this.state.serviceImg: user} alt="carousel" className='guide-carousel position-relative w-100' style={{height:'350px'}}/>
                      <div className="mbl-name-price">
                        <div>
                          <div className='d-flex'>
                          <h2 className="card-title">{this.state.serviceNAme}</h2>
                          {/* <div class="rate">
                              <input type="radio" id="localstar5" name="localstar5" value="5"/>
                              <label for="localstar5" title="text">5 stars</label>
                              <input type="radio" id="localstar4" name="localstar4" value="4"/>
                              <label for="localstar4" title="text">4 stars</label>
                              <input type="radio" id="localstar3" checked name="localstar3" value="3"/>
                              <label for="localstar3" title="text">3 stars</label>
                              <input type="radio" id="localstar2" name="localstar2" value="2"/>
                              <label for="localstar2" title="text">2 stars</label>
                              <input type="radio" id="localstar1" name="localstar1" value="1"/>
                              <label for="localstar1" title="text">1 star</label>
                            </div>*/}
                          </div> 
                          {/* <p className="card-text">Lahore, Pakistan</p> */}
                        </div><h1 className="price">
                          {this.state.servicePrice ? 
                        <div className="price">
                          <strong>{new Intl.NumberFormat().format(this.state.servicePrice)}/</strong>{this.state.serviceType}
                        </div>
                        :
                        ''
                      }</h1></div>
                  </div>
              </div>
          </div>
          <div className='mbl-local-content'>
          <blockquote>
            <q>lets travel and find something new and delicious</q>
          </blockquote>
          <Card className='mbl-trip-card'>
        <Card.Body>Book a Service and get offers from providers
        {
            //  this.state.token || this.state.fbloggedIn || this.state.googleloggedIn ?
             this.state.token ?
             <>
            <Link to={{pathname: "/mytrips/"+ this.state.serviceID+"/"+this.state.providerID+"/"+this.state.servicePrice, state : {service_id: this.state.serviceID, provider_id: this.state.providerID,price:this.state.servicePrice}}}>
            <button className='trip-button mb-3'>book this service</button>
            </Link>
            {this.state.markasFav ? 
      <button className='contact-button' onClick={()=>this.setState({noteModal:true})}>Make a Note</button>
      :
      <button className='contact-button' onClick={this.markFavorite} disabled={this.state.markasFav ? true : false}>{this.state.markFavLoader ? <Spinner animation="border" variant="primary" size="sm"/> : <> <RiBookmarkLine/>Mark as Favourite</>}</button>
      }
      </>
            :
            <strong className='text-black'>
            Login First to Book a Service
            </strong>
          }
        {/* <span className='left-right-line'>or</span>
        Conatct <strong>{this.state.serviceProvider}</strong> directly
        <button className='contact-button'>contact</button> */}
        </Card.Body>
      </Card>
          </div>
        <Row className='content'>
          <Col md={8} lg={8} xs={12}>
          <div className='local-content'>
          <Row  className='border-bottom mt-3'>
              <Col md={4} lg={4} xs={12}>
                  <div>
                    <strong>Provider Name</strong>
                  </div>
              </Col>
              <Col md={8} lg={8} xs={12}>
                  <div>{this.state.serviceProvider}</div>
              </Col>
          </Row>
          <br/>
        </div>
          </Col>
          <Col md={4} lg={4} xs={12}>
          <div className='review-card'>
        <Card>
          {/* <Card.Header className='d-flex flex-column'>
              <center>
              <div class="rate">
                              <input type="radio" id="guidestar5" name="guidestar5" value="5"/>
                              <label for="guidestar5" title="text">5 stars</label>
                              <input type="radio" id="guidestar4" name="guidestar4" value="4"/>
                              <label for="guidestar4" title="text">4 stars</label>
                              <input type="radio" id="guidestar3" name="guidestar3" value="3"/>
                              <label for="guidestar3" title="text">3 stars</label>
                              <input type="radio" id="guidestar2" name="guidestar2" value="2"/>
                              <label for="guidestar2" title="text">2 stars</label>
                              <input type="radio" id="guidestar1" name="guidestar1" value="1"/>
                              <label for="guidestar1" title="text">1 star</label>
                            </div>
              </center>
                            <div>No reviews yet</div>
              
              </Card.Header> */}
        <Card.Body>
          Book a service and get offers from providers <br/>
          {
            //  this.state.token || this.state.fbloggedIn || this.state.googleloggedIn ?
             this.state.token ?
             <>
            <Link to={{pathname: "/mytrips/"+ this.state.serviceID+"/"+this.state.providerID+"/"+this.state.servicePrice, state : {service_id: this.state.serviceID, provider_id: this.state.providerID,price:this.state.servicePrice}}}>
            <button className='trip-button mb-3'>book this service</button>
            </Link>
            {this.state.markasFav ? 
      <button className='contact-button' onClick={()=>this.setState({noteModal:true})}>Make a Note</button>
      :
      <button className='contact-button' onClick={this.markFavorite} disabled={this.state.markasFav ? true : false}>{this.state.markFavLoader ? <Spinner animation="border" variant="primary" size="sm"/> : <> <RiBookmarkLine/>Mark as Favourite</>}</button>
      }
       </>
            :
            <strong className='text-black'>
            Login First to Book a Service
            </strong>
          }
         
        </Card.Body>
      </Card>
        </div>
          <Card className="more-info-card">
        <Card.Body>
          <strong>More Info</strong>
          <div className='text-primary'><Link to="/about">About Dinfoo</Link></div>
          <div className='text-primary'><Link to="/booking_advice">Booking Advice</Link></div>
          <hr/>
          <strong>Need Assistance?</strong>
          <div> Contact our Customer Support if you need any assistance managing your bookings.</div>
         
        </Card.Body>
      </Card>
          </Col>
        </Row>
        {/* <div className='bg-white'>
          <div className='local-review'>
              <h2>0 Reviews</h2>
          </div>
      </div> */}
        <Footer/>
           {/* make a note modal */}
           <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.noteModal} onHide={() => this.setState({ noteModal: false})} className="signup-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <center>Make a note</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    {/* <Form.Control type="text" placeholder="Your Message" onChange={(e) => this.setState({contactText: e.target.value})}/> */}
    <Form.Control type="text" placeholder="Type your note" onChange={(e) => this.setState({noteText:e.target.value})}/>
    {this.state.errorMsgTxt ? <span className='text-danger'>{this.state.errorMsgTxt }</span> : ''}
    <button className={this.state.isloading ? 'float-right continue-btn w-100 opacity-half' : 'float-right w-100 continue-btn'} onClick={this.makeFavNote} disabled={this.state.isloading ? true : false}>
      {this.state.isloading ? 
       <>
       &nbsp;&nbsp;
       <Spinner animation="border" variant="light" size="sm"/> 
       &nbsp;&nbsp;
       </> 
       :
        <>Save Note</>
      }
    </button>
    {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center>: '' }
      </Modal.Body>
    </Modal>
        {
  this.state.success ? 
  <Alert key='success' variant='success' className='log-alert'>
{this.state.successMsg}
</Alert>
:
  ''
    }
          </div>
    )
  }
}
export default withRouter(Service);
