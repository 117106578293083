import React, { Component } from 'react';
import NavbarHome from '../Components/NavbarHome'
import { Form, Button, Card, Alert, Modal, Spinner, Row, Col, Tooltip, InputGroup, FormGroup, OverlayTrigger } from 'react-bootstrap';
import Footer from '../Components/Footer';
import { api_url } from "../Variables";
import { Link } from 'react-router-dom';
import { authentication } from '../firebase-config';
import OtpInput from 'react-otp-input';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import '../Assets/Styles/navbar.css';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';
import { RiEyeLine, RiEyeOffLine, RiMotorbikeFill, RiWalkFill, RiQuestionFill } from "react-icons/ri";
import 'react-phone-number-input/style.css'
import team1 from '../Assets/Images/team1.png';
import team2 from '../Assets/Images/team2.png';
import team3 from '../Assets/Images/team3.png';
import team4 from '../Assets/Images/team4.png';
import team5 from '../Assets/Images/team5.png';
import team6 from '../Assets/Images/team6.png';
import team7 from '../Assets/Images/team7.png';
import team8 from '../Assets/Images/team8.png';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
const currentYear = new Date().getFullYear();
class BecomeGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRegister: false,
            showLogin: false,
            verifyOTP: false,
            resend: false,
            resendMsg: '',
            showResendMsg: false,
            otpValue: '',
            enterOTPMsg: '',
            registerMsg: '',
            loginMsg: '',
            fbloggedin: false,
            fbName: '',
            fbPic: '',
            fbEmail: '',
            googleloggedin: false,
            isloggedIn: false,
            googleName: '',
            googlePic: '',
            googleEmail: '',
            loginAlert: false,
            registerAlert: false,
            errorAlert: false,
            logoutAlert: false,
            errorMsg: '',
            userName: '',
            userNameError: '',
            userEmail: '',
            userPassword: '',
            userTypeError: '',
            profileName: '',
            profileImg: [],
            // userType: [],
            userType: 'provider',
            userRole: '',
            loginContent: false,
            registerContent: true,
            loginPassword: '',
            token: '',
            notValidEmail: false,
            errorVerifyMsg: '',
            errorVerify: false,
            passwordStrength: '',
            passwordStrengthError: false,
            isloading: false,
            isResend: false,
            showPassword: false,
            forgetModal: false,
            forgetEmailText: true,
            forgetCodeText: false,
            forgetEmail: '',
            forgetEmailError: '',
            forgetEmailCode: '',
            resetPasswordText: false,
            ForgotVerify: false,
            forgotVerifyMsg: '',
            newPassword: '',
            confirmNewPassword: '',
            showConfirmPassword: false,
            resetPasswordError: '',
            resetSuccess: false,
            resetSuccessMsg: '',
            passwordError: '',
            seeker_provider: localStorage.getItem('seekerORprovider') || 'seeker',
            seller_type: 'local',
            phone_no: 0,
            showmblOTPModal: false,
            mblOTP: '',
            showCaptcha: false,
            showResendCaptcha: false,
            errorVerifymblOTP: false,
            mblVerify: false,
            resendMblOTP: false,
            isResendMbl: false,
            showMblResendMsg: false,
            waitforRegister: false,
            showEmailInput: true,
            showMblInput: false,
            mblToken: '',
            registerMblError: false,
            registerMblErrorMsg: '',
            fbToken: '',
            googleToken: '',
            fbID: '',
            googleID: '',
            googleLoader: false,
            fbLoader: false,
            experience: '',
            experienceError: '',
            officialWeb: '',
            officialWebError: '',
            validWebUrl: '',
            license: '',
            licenseError: '',
            hour_rate: '',
            mode: [],
        }
    }
    componentDidMount = async () => {
        window.addEventListener("storage", (e) => {
            console.log('storage changed')
            const token = JSON.parse(localStorage.getItem("token"));
            if (token) {
                this.setState({ token: token.token });
            } else {
                this.setState({ token: '' })
            }
        }, []);
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            this.setState({ token: token.token });
        } else {
            this.setState({ token: '' })
        }
    }
    register = async () => {
        //    this.state.seller_type,this.state.experience,this.state.officialWeb,this.state.license)
        this.setState({ isloading: true })
        // const name = this.state.userName;
        // const email = this.state.userEmail;
        // const password = this.state.userPassword;
        // const user_type = this.state.userType;
        // const seller_type = this.state.seller_type;
        // const experience = this.state.experience;
        // const official_website = this.state.officialWeb;
        // const license = this.state.license;
        // const preferred_mode = this.state.mode;
        // const hourly_rate = this.state.hour_rate;
        // let item = {name,email,password,user_type,seller_type,experience,official_website,license,preferred_mode,hourly_rate}
        // console.log(item)
        this.setState({ errorVerify: false, errorVerifyMsg: '', errorAlert: false, errorMsg: '', passwordStrengthError: false });
        var validRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!this.state.userEmail || validRegex.test(this.state.userEmail) === false) {
            this.setState({ notValidEmail: true });
        } else {
            this.setState({ notValidEmail: false });
        }
        if (this.state.passwordStrength.score <= 1) {
            this.setState({ passwordStrengthError: true });
            console.log('password is weak')
        } else {
            this.setState({ passwordStrengthError: false });
            console.log('password is ok')
        }
        if (this.state.userName) {
            this.setState({ userNameError: '' })
        } else {
            this.setState({ userNameError: 'Name field is required' })
        }
        if(this.state.seller_type === 'Pro'){
            if (this.state.license) {
                this.setState({ licenseError: '' })
            } else {
                this.setState({ licenseError: 'License field is required' })
            }
        }else{
            this.setState({ licenseError: '' })
        }
        if (this.state.notValidEmail === false || this.state.passwordStrength.score > 1 || !this.state.userNameError || this.state.licenseError) {
            ////
            var myHeaders = new Headers();
            myHeaders.append("Accept", "*/*");
            var formdata = new FormData();
            formdata.append("name", this.state.userName);
            formdata.append("email", this.state.userEmail);
            formdata.append("password", this.state.userPassword);
            formdata.append("seller_type", this.state.seller_type);
            formdata.append("user_type[]", this.state.userType);
            if(this.state.mode.length > 0){
                this.state.mode.map((n)=>{
                    formdata.append("preferred_mode[]", n);
                })
            }
            formdata.append("hourly_rate", this.state.hour_rate);
            formdata.append("experience", this.state.experience);
            formdata.append("official_website", this.state.officialWeb);
            formdata.append("license", this.state.license);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata
            };

            fetch(api_url + "api/register", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.error || result.status === "Error") {
                        this.setState({ errorAlert: true, errorMsg: result.message, isloading: false });
                    }
                    else {
                        this.setState({ showRegister: false, verifyOTP: true, enterOTPMsg: result.message, isloading: false });
                        setTimeout(() => {
                            this.setState({ resend: true });
                        }, 5000);
                    }
                    this.setState({ isloading: false, waitforRegister: false })
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({ isloading: false })
                });
        } else {
            this.setState({ isloading: false })
        }
    }
    registerWithMbl = async () => {
        this.setState({ isloading: true })
        const name = this.state.userName;
        const phone = this.state.phone_no;
        const password = this.state.userPassword;
        const user_type = this.state.userType;
        let item = { name, phone, password, user_type }
        console.log(item)
        this.setState({ errorVerify: false, errorVerifyMsg: '', errorAlert: false, errorMsg: '', passwordStrengthError: false });
        if (this.state.passwordStrength.score <= 1) {
            this.setState({ passwordStrengthError: true });
            console.log('password is weak')
        } else {
            this.setState({ passwordStrengthError: false });
            console.log('password is ok')
        }
        // if(this.state.passwordStrengthError === false && this.state.userName && this.state.userType && this.state.phone_no){
        if (this.state.passwordStrength.score > 1 && this.state.userName && this.state.phone_no) {
            ////
            var myHeaders = new Headers();
            myHeaders.append("Accept", "*/*");
            var formdata = new FormData();
            formdata.append("name", this.state.userName);
            formdata.append("user_contact_number", this.state.phone_no);
            formdata.append("firebase_token", this.state.mblToken);
            formdata.append("password", this.state.userPassword);
            formdata.append("seller_type", this.state.seller_type);
            formdata.append("user_type[]", this.state.userType);
            if(this.state.mode.length > 0){
                this.state.mode.map((n)=>{
                    formdata.append("preferred_mode[]", n);
                })
            }
            formdata.append("hourly_rate", this.state.hour_rate);
            formdata.append("experience", this.state.experience);
            formdata.append("official_website", this.state.officialWeb);
            formdata.append("license", this.state.license);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata
            };

            fetch(api_url + "api/register", requestOptions)
                .then(response => response.json())
                .then(async (result) => {
                    console.log(result)
                    if (result.error || result.status === "Error") {
                        this.setState({ registerMblError: true, registerMblErrorMsg: result.message, isloading: false });
                        setTimeout(() => {
                            this.setState({ registerMblError: false, registerMblErrorMsg: '' });
                        }, 2000);
                    }
                    else {
                        if (result.token) {
                            localStorage.setItem("token", JSON.stringify(result));
                            setTimeout(() => {
                                this.logout();
                            }, 3600000)
                            this.setState({ isloggedIn: true, showRegister: false, registerAlert: true, registerMsg: result.message, isloading: false });
                            setTimeout(() => {
                                this.setState({ registerAlert: false });
                            }, 2000);
                            const token = result.token;
                            let userDetails = await fetch(api_url + "api/dashboard/profile", {
                                method: "get",
                                headers: {
                                    "Authorization": `Bearer ${token}`,
                                    'Accept': 'application/json'
                                }
                            });
                            userDetails = await userDetails.json();
                            console.log('user details', userDetails);
                            if (userDetails) {
                                window.localStorage.setItem("user-info", JSON.stringify(userDetails));
                                window.dispatchEvent(new Event("storage"));
                                this.setState({ profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0] });
                            }
                        }
                    }
                    this.setState({ isloading: false, waitforRegister: false })
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({ isloading: false })
                });
        }
    }
    verify = async () => {
        this.setState({ errorVerify: false, errorVerifyMsg: '', errorAlert: false, errorMsg: '', isloading: true });
        console.log(this.state.otpValue)
        var formdata = new FormData();
        formdata.append("email", this.state.userEmail);
        formdata.append("otp", this.state.otpValue);

        var requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(api_url + "api/verify", requestOptions)
            .then(response => response.json())
            .then(async (result) => {
                console.log(result)
                if (result.status === 'Success') {
                    if (result.token) {
                        localStorage.setItem("token", JSON.stringify(result));
                        setTimeout(() => {
                            this.logout();
                        }, 3600000)
                        this.setState({ isloggedIn: true, verifyOTP: false, registerAlert: true, registerMsg: result.message, isloading: false });
                        setTimeout(() => {
                            this.setState({ registerAlert: false });
                        }, 2000);
                        const token = result.token;
                        let userDetails = await fetch(api_url + "api/dashboard/profile", {
                            method: "get",
                            headers: {
                                "Authorization": `Bearer ${token}`,
                                'Accept': 'application/json'
                            }
                        });
                        userDetails = await userDetails.json();
                        console.log('user details', userDetails);
                        if (userDetails) {
                            window.localStorage.setItem("user-info", JSON.stringify(userDetails));
                            window.dispatchEvent(new Event("storage"));
                            this.setState({ profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0] });
                        }
                    }
                    // this.setState({verifyOTP:false, registerAlert: true,registerMsg:result.message,showLogin:true, isloading: false});
                    // setTimeout(() => {
                    //   this.setState({registerAlert: false});
                    // }, 2000);
                } else {
                    this.setState({ errorVerify: true, errorVerifyMsg: result.error, isloading: false });
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ isloading: false })
            });
    }
    resendOTP = async () => {
        this.setState({ isResend: true });
        /*const email = this.state.userEmail;
        let item ={email};
        console.log(item);
        let result = await fetch(api_url+"api/resend/otp", {
          method: "post",
          headers : { 
            "Content-Type": "multipart/form-data; application/json",
            'Accept': 'application/json'
          },
          body:JSON.stringify(item)
        });
        result = await result.json();
        console.warn( result);
        if(result.status === 'Success'){
          this.setState({showResendMsg: true,resendMsg:result.message, isResend: false});
          setTimeout(() => {
            this.setState({showResendMsg: false});
          }, 5000);
        }
        else{
          this.setState({errorVerify: true, errorVerifyMsg: result.error, verifyOTP:false, isResend: false});
        }*/

        var formdata = new FormData();
        formdata.append("email", this.state.userEmail);

        var requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(api_url + "api/resend/otp", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status === 'Success') {
                    this.setState({ showResendMsg: true, resendMsg: result.message, isResend: false });
                    setTimeout(() => {
                        this.setState({ showResendMsg: false });
                    }, 5000);
                } else {
                    this.setState({ errorVerify: true, errorVerifyMsg: result.error, verifyOTP: false, isResend: false });
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ isloading: false })
            });
    }
    forgetApi = async () => {
        this.setState({ isloading: true })
        var formdata = new FormData();
        formdata.append("email", this.state.forgetEmail);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "api/forgetApi", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ isloading: false });
                if (result.status === true) {
                    this.setState({ forgetEmailText: false, forgetCodeText: true })
                }
                if (result.email) {
                    this.setState({ forgetEmailError: result.email })
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ isloading: false });
            });
    }
    verifyNewOTP = async () => {
        this.setState({ isloading: true })
        var formdata = new FormData();
        formdata.append("email", this.state.forgetEmail);
        formdata.append("otp", this.state.forgetEmailCode);

        var requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(api_url + "api/verify", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ isloading: false })
                if (result.status === 'Success') {
                    this.setState({ forgetCodeText: false, resetPasswordText: true });
                } else {
                    this.setState({ ForgotVerify: true, forgotVerifyMsg: result.error, isloading: false });
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ isloading: false })
            });
    }
    resetPassword = async () => {
        this.setState({ isloading: true })
        if (this.state.passwordStrength.score <= 1) {
            this.setState({ passwordStrengthError: true });
            console.log('password is weak')
        } else {
            this.setState({ passwordStrengthError: false });
            console.log('password is ok')
        }
        if (this.state.passwordStrength.score > 1) {
            var formdata = new FormData();
            formdata.append("email", this.state.forgetEmail);
            formdata.append("password", this.state.newPassword);
            formdata.append("password_confirmation", this.state.confirmNewPassword);

            var requestOptions = {
                method: 'POST',
                body: formdata,
            };

            fetch(api_url + "api/password/reset", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    this.setState({ isloading: false })
                    if (result.error) {
                        if (result.error.password) {
                            this.setState({ passwordStrengthError: true })
                        } else {
                            this.setState({ resetPasswordError: result.error })
                        }
                    } else {
                        this.setState({ resetPasswordText: false, forgetModal: false, resetSuccess: true, resetSuccessMsg: result.message })
                        setTimeout(() => {
                            this.setState({ showLogin: true, resetSuccess: false, resetSuccessMsg: '' });
                        }, 2000);
                    }
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({ isloading: false })
                });
        }
    }
    generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            // 'size': 'invisible',
            'callback': (response) => {
                this.setState({ showRegister: false, showmblOTPModal: true, isloading: false })
                setTimeout(() => {
                    this.setState({ resendMblOTP: true })
                }, 5000);
            }
        }, authentication);
    }
    generateResendRecaptcha = () => {
        this.setState({ isResendMbl: true })
        window.recaptchaVerifierResend = new RecaptchaVerifier('resend-repatcha', {
            // 'size': 'invisible',
            'callback': (response) => {
                this.setState({ isResendMbl: false, showMblResendMsg: true, showResendCaptcha: false });
                setTimeout(() => {
                    this.setState({ showMblResendMsg: false });
                }, 2000);
            }
        }, authentication);
    }
    requestMblOTP = (e) => {
        this.setState({ isloading: true })
        if (this.state.passwordStrength.score <= 1) {
            this.setState({ passwordStrengthError: true });
            console.log('password is weak')
        } else {
            this.setState({ passwordStrengthError: false });
            console.log('password is ok')
        }
        if (this.state.userName) {
            this.setState({ userNameError: '' })
        } else {
            this.setState({ userNameError: 'Name field is required' })
        }
        // if(this.state.userType.length > 0){
        //   this.setState({userTypeError:''})
        // }else{
        //   this.setState({userTypeError: 'Please select one'})
        // }
        // if(this.state.phone_no && isValidPhoneNumber(this.state.phone_no) && this.state.passwordStrengthError === false && !this.state.userNameError &&  !this.state.userTypeError){
        if (this.state.phone_no && isValidPhoneNumber(this.state.phone_no) && this.state.passwordStrength.score > 1 && !this.state.userNameError) {
            ////
            this.generateRecaptcha();
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(authentication, this.state.phone_no, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    this.setState({ mblToken: confirmationResult.verificationId })
                    console.log(this.state.mblToken);
                    // ...
                }).catch((error) => {
                    // Error; SMS not sent
                    console.log(error)
                    this.setState({ showRegister: true, errorAlert: true, errorMsg: "Error!!! Please Try again", showmblOTPModal: false })
                    setTimeout(() => {
                        this.setState({ errorAlert: false, errorMsg: '' })
                    }, 3000);

                });
        }
        else {
            console.log('all fields are required')
        }
    }
    requestAgainforMblOTP = () => {
        this.generateResendRecaptcha()
        let appVerifierResend = window.recaptchaVerifierResend;
        signInWithPhoneNumber(authentication, this.state.phone_no, appVerifierResend)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                this.setState({ mblToken: confirmationResult.verificationId })
                console.log(this.state.mblToken);

                // ...
            }).catch((error) => {
                // Error; SMS not sent
                console.log(error)
            });
    }
    verifyMblOTP = (e) => {
        console.log(this.state.mblOTP)
        if (this.state.mblOTP.length === 6) {
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(this.state.mblOTP).then((result) => {
                // mbl no verified successfully
                this.setState({ mblVerify: true, showmblOTPModal: false });
                setTimeout(() => {
                    this.setState({ mblVerify: false, waitforRegister: true });
                    this.registerWithMbl()
                }, 2000);
                // ...
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                this.setState({ errorVerifymblOTP: true })
                console.log('Error', error)
            });
        }
    }
    urlPatternValidation = (URL) => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        return regex.test(URL);
    };
    isValidUrl = (event) => {
        this.setState({ officialWeb: event.target.value }, () => {
            if (this.urlPatternValidation(this.state.officialWeb)) {
                this.setState({ validWebUrl: '' })
            } else {
                this.setState({ validWebUrl: 'Please enter valid URL' })
            }
        })
    }
    getTokenForFB = async () => {
        var formdata = new FormData();
        formdata.append("name", this.state.fbName);
        formdata.append("facebook_id", this.state.fbID);
        formdata.append("access_token", this.state.fbToken);
        formdata.append("email", this.state.fbEmail);
        formdata.append("profile_image_url", this.state.fbPic);
        formdata.append("userRole", 'provider');

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "api/facebook_login", requestOptions)
            .then(response => response.json())
            .then(async (result) => {
                console.log(result)
                if (result.error) {

                } else if (result.token) {
                    localStorage.setItem("token", JSON.stringify(result));
                    // window.localStorage.setItem("seekerORprovider", 'provider');
                    window.dispatchEvent(new Event("storage"));
                    // this.setState({fbloggedin:true, showRegister: false,showLogin:false, loginAlert: true,loginMsg:result.message,fbLoader:false,fbName:this.state.fbResponse.name,fbPic: this.state.fbResponse.picture.data.url, fbEmail:this.state.fbResponse.email, });
                    this.setState({ isloggedIn: true, showRegister: false, showLogin: false, loginAlert: true, loginMsg: result.message, fbLoader: false });
                    setTimeout(() => {
                        this.setState({ loginAlert: false, loginMsg: '' });
                    }, 3000);
                    const token = result.token;
                    let userDetails = await fetch(api_url + "api/dashboard/profile", {
                        method: "get",
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            'Accept': 'application/json'
                        }
                    });
                    userDetails = await userDetails.json();
                    console.log('user details', userDetails);
                    if (userDetails) {
                        localStorage.setItem("user-info", JSON.stringify(userDetails));
                        window.dispatchEvent(new Event("storage"));
                        this.setState({ profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0] });
                    }
                } else {

                }
            })
            .catch(error => console.log('error', error));
    }
    validateFbToken = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/me?access_token=" + this.state.fbToken, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.error) {
                    this.setState({ errorAlert: true, errorMsg: result.error.message })
                } else {
                    this.getTokenForFB();
                }
            })
            .catch(error => console.log('error', error));
    }
    getTokenForGoogle = async () => {
        var formdata = new FormData();
        formdata.append("name", this.state.googleName);
        formdata.append("google_id", this.state.googleID);
        formdata.append("access_token", this.state.googleToken);
        formdata.append("email", this.state.googleEmail);
        formdata.append("imageurl", this.state.googlePic);
        formdata.append("userRole", 'provider');

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "api/google_login", requestOptions)
            .then(response => response.json())
            .then(async (result) => {
                console.log(result)
                if (result.error) {

                } else if (result.token) {
                    localStorage.setItem("token", JSON.stringify(result));
                    // window.localStorage.setItem("seekerORprovider", 'provider');
                    window.dispatchEvent(new Event("storage"));
                    this.setState({ isloggedIn: true, showRegister: false, showLogin: false, loginAlert: true, loginMsg: result.message, googleLoader: false });
                    setTimeout(() => {
                        this.setState({ loginAlert: false, loginMsg: '' });
                    }, 3000);
                    const token = result.token;
                    let userDetails = await fetch(api_url + "api/dashboard/profile", {
                        method: "get",
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            'Accept': 'application/json'
                        }
                    });
                    userDetails = await userDetails.json();
                    console.log('user details', userDetails);
                    if (userDetails) {
                        localStorage.setItem("user-info", JSON.stringify(userDetails));
                        window.dispatchEvent(new Event("storage"));
                        this.setState({ profileName: userDetails.profile.name, profileImg: userDetails.profile_images[0] });
                    }
                } else {

                }
            })
            .catch(error => console.log('error', error));
    }
    validateGoogleToken = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=" + this.state.googleToken, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.error) {
                    this.setState({ errorAlert: true, errorMsg: result.error })
                } else {
                    this.getTokenForGoogle();
                }
            })
            .catch(error => console.log('error', error));
    }
    handleModeCheckboxes = (event) => {
        var updatedList = [...this.state.mode];
        if (event.target.checked) {
            updatedList = [...this.state.mode, event.target.value];
        } else {
            // this.state.mode.filter(val=> {return val!==value})
            updatedList.splice(this.state.mode.indexOf(event.target.value), 1);
        }
        this.setState({mode: updatedList})
      };
    render() {
        const responseFacebook = (response) => {
            this.setState({ fbLoader: true });
            if (response.accessToken) {
                this.setState({ fbToken: response.accessToken, fbID: response.userID, fbName: response.name, fbPic: response.picture.data.url, fbEmail: response.email }, () => {
                    this.getTokenForFB()
                })
            }
            else {
                this.setState({ isloggedIn: false, fbName: '', fbPic: '' })
            }
            console.log(response);
            setTimeout(() => {
                this.setState({ loginAlert: false, loginMsg: '' });
            }, 3000);
        }
        const responseGoogleSuccess = (response) => {
            this.setState({ googleLoader: true });
            if (response.accessToken) {
                this.setState({ googleToken: response.accessToken, googleID: response.googleID, googleName: response.profileObj.name, googlePic: response.profileObj.imageUrl, googleEmail: response.profileObj.email }, () => {
                    this.getTokenForGoogle()
                })
            }
            else {
                this.setState({ isloggedIn: false, googleName: '', googlePic: '' })
            }
            console.log("login succes", response);
            setTimeout(() => {
                this.setState({ loginAlert: false, loginMsg: '', });
            }, 3000);
        }
        const responseGoogleFailure = (response) => {
            console.log("Login failed", response);
            this.setState({ googleLoader: false })
        }
        const renderTooltip = (text) => (
            <Tooltip id="button-tooltip">
                {text}
            </Tooltip>
        );
        return (
            <div className='body-bg'>
                <NavbarHome />
                <div className='guide-banner'>
                    <div className='guide-content'>
                        <h2>Become a guide</h2>
                        <h5>Become a guide & make extra money</h5>
                        <button className='btn become-guide-btn' onClick={() => this.setState({ showRegister: true })}>sign up as provider</button>
                    </div>
                </div>
                <div className='become-guide-content'>
                    <div className='process-content'>
                        <h4 className='text-uppercase'><strong>Amazing Facts</strong></h4>
                        <p className='mx-5'>There’s no reason to be just a mere tourist anymore, not when locals can show you an edgier, more beautiful and more authentic version of their city.</p>
                        <h4 className='text-uppercase'><strong>Signup Process</strong></h4>
                        <p className='mx-5'>There’s no reason to be just a mere tourist anymore, not when locals can show you an edgier, more beautiful and more authentic version of their city.</p>
                    </div>
             <center className='happy-faces'>
            <h3 className='text-uppercase'><strong>Some Happy Faces</strong></h3>
            {/* <h5 className='text-gray'>Hello. It’s nice to finally meet you.</h5> */}
            <Row className='mt-3'>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team3} />
      <Card.Body>
        <Card.Title >Provider 1</Card.Title>
        <Card.Text>Location</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team4} />
      <Card.Body>
      <Card.Title >Provider 2</Card.Title>
        <Card.Text>Location</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team8} />
      <Card.Body>
      <Card.Title >Provider 3</Card.Title>
        <Card.Text>Location</Card.Text>
      </Card.Body>
    </Card>
                </Col>
            </Row>
            </center>
        </div>
                <Footer />
                {/* signup modal */}
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showRegister} onHide={() => this.setState({ showRegister: false })} className="prov-signup-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <center>Signup</center>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center><h5>Welcome to Dinfoo</h5></center>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Full Name" onChange={(e) => this.setState({ userName: e.target.value })} />
                            {this.state.userNameError ? <span className='text-danger'>{this.state.userNameError}</span> : ''}
                        </Form.Group>
                        {this.state.showEmailInput ?
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control type="email" placeholder="Email or Phone number" autoComplete='off'
                                        onChange={(e) => this.setState({ userEmail: e.target.value },
                                            () => {
                                                console.log(!isNaN(+this.state.userEmail));
                                                if (!isNaN(+this.state.userEmail) && this.state.userEmail) {
                                                    this.setState({ showMblInput: true, showEmailInput: false })
                                                } else {
                                                    this.setState({ showMblInput: false, showEmailInput: true })
                                                }
                                            })} />
                                    {this.state.notValidEmail ? <span className='text-danger'>Please enter valid email address.</span> : ''}
                                </Form.Group>
                            </>
                            :
                            this.state.showMblInput ?
                                <>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Phone No <span className='text-danger'>*</span></Form.Label>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            // defaultCountry="PK"
                                            defaultCountry="US"
                                            international={true}
                                            value={this.state.phone_no}
                                            onChange={phone_no => this.setState({ phone_no }, () => {
                                                console.log(!isNaN(+this.state.phone_no))
                                                if (!isNaN(+this.state.phone_no) && this.state.phone_no) {
                                                    this.setState({ showMblInput: true, showEmailInput: false })
                                                } else {
                                                    this.setState({ showMblInput: false, showEmailInput: true })
                                                }
                                            })}
                                            className='phone-no-imput'
                                            error={this.state.phone_no ? (isValidPhoneNumber(this.state.phone_no) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                        {this.state.phone_no && isValidPhoneNumber(this.state.phone_no) ? '' : <span className='text-danger'>Please enter valid phone number.</span>}
                                    </Form.Group>
                                </>
                                :
                                ''
                        }
                        <Form.Group className='mb-3'>
                            <Form.Label>Password <span className='text-danger'>*</span></Form.Label>
                            <Form.Control className='position-relative' type={this.state.showPassword ? 'text' : 'password'} placeholder="Password" onChange={(e) => this.setState({ userPassword: e.target.value, passwordStrength: zxcvbn(e.target.value) })} />
                            {this.state.showPassword ?
                                <RiEyeOffLine className='password-show' onClick={() => this.setState(prevState => ({ showPassword: !prevState.showPassword }))} />
                                :
                                <RiEyeLine className='password-show' onClick={() => this.setState(prevState => ({ showPassword: !prevState.showPassword }))} />
                            }
                            <PasswordStrengthBar password={this.state.userPassword} />
                            {this.state.passwordStrengthError ?
                                <span className='text-danger text-center mb-3'>Your password should be at least 8 characters long and contain at least one number with alphabets</span>
                                : ""
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Hourly Rate</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="price">USD</InputGroup.Text>
                                <Form.Control placeholder="Enter price" value={this.state.hour_rate} onChange={(e) => this.setState({ hour_rate: e.target.value })} aria-label="price" aria-describedby="price" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={4}>Preferred Mode</Form.Label>
                            <Col sm={8} className='d-flex justify-content-start align-items-center'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div className='square-checkbox'>
                                        <Form.Check type='checkbox' name='mode' label='Walk' value="Walk" onChange={(e) => this.handleModeCheckboxes(e)} />
                                    </div>
                                    <RiWalkFill title='Walk' className='mx-1' />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div className='square-checkbox'>
                                        <Form.Check type='checkbox' name='mode' label='Bike' value="Bike" onChange={(e) => this.handleModeCheckboxes(e)} />
                                    </div>
                                    <RiMotorbikeFill title='Bike' className='mx-1' />
                                </div>
                            </Col>
                        </Form.Group>
                        {this.state.seller_type === 'Pro' ?
                            <>
                                <FormGroup className='mb-3'>
                                    <Form.Label>Experience</Form.Label>
                                    <Form.Select onChange={(e) => this.setState({ experience: e.target.value })}>
                                        <option disabled selected>Select Your Experience</option>
                                            <option value="1">1 year</option>
                                            <option value="2">2 years</option>
                                            <option value="3">3 years</option>
                                            <option value="4">4 years</option>
                                            <option value="5">5 years</option>
                                            <option value="6">5+ years</option>
                                    </Form.Select>
                                    {/* <Form.Control id="exp" type="text" placeholder="Experience" onChange={(e) => this.setState({ experience: e.target.value })} /> */}
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Form.Label>Website URL</Form.Label>
                                    <Form.Control id="web" type="text" placeholder="Official Website" onChange={(e) => this.isValidUrl(e)} />
                                    {this.state.validWebUrl ? <span className='text-danger'>{this.state.validWebUrl}</span> : ''}
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Form.Label>Licence <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control id="license" type="file" placeholder="Licence" onChange={(e) => this.setState({ license: e.target.files[0] })} />
                                    {this.state.licenseError ? <span className='text-danger'>{this.state.licenseError}</span> : ''}
                                </FormGroup>
                            </>
                            :
                            ''
                        }
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={4}>Guide Type <span className='text-danger'>*</span></Form.Label>
                            <Col sm={8} className='d-flex justify-content-start align-items-center'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <Form.Check type='radio' id="pro" value="Pro" checked={this.state.seller_type === 'Pro' ? true : false} label='Professional' onChange={(e) => this.setState({ seller_type: e.target.value })} />
                                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Professional')}>
                                        <label for="pro" className="mr-15 guide-help"><RiQuestionFill /></label>
                                    </OverlayTrigger>
                                </div>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <Form.Check type='radio' id="local" value="Local" checked={this.state.seller_type === 'Local' ? true : false} label='Local' onChange={(e) => this.setState({ seller_type: e.target.value })} />
                                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Local')}>
                                        <label for="local" className="mr-15 guide-help"><RiQuestionFill /></label>
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        </Form.Group>
                        <div class="d-flex">
                        </div>
                        {this.state.showCaptcha ?
                            <div id="recaptcha-container" className='d-flex justify-content-center align-items-center mb-4'></div>
                            : ''}
                        {this.state.showMblInput ?
                            <button className='w-100 continue-btn' onClick={() => this.setState({ showCaptcha: true }, this.requestMblOTP)} disabled={this.state.isloading ? true : false}>
                                {this.state.isloading ?
                                    <Spinner animation="border" variant="light" size="sm" /> : 'continue with phone'}
                            </button>
                            :
                            this.state.showEmailInput ?
                                <button className='w-100 continue-btn' onClick={this.register} disabled={this.state.isloading ? true : false}>
                                    {this.state.isloading ?
                                        <Spinner animation="border" variant="light" size="sm" /> : 'continue with email'}
                                </button>
                                :
                                ''
                        }
                        {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center> : ''}
                        <center><span className='left-right-line'>or</span></center>
                        <FacebookLogin
                            appId={process.env.REACT_APP_FB_KEY}
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            icon={this.state.fbLoader ? "fa-spinner" : "fa-facebook"}
                        />

                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_KEY}
                            buttonText={this.state.googleLoader ? <Spinner animation="border" variant="primary" size="sm" /> : "LOGIN WITH GOOGLE"}
                            onSuccess={responseGoogleSuccess}
                            onFailure={responseGoogleFailure}
                            className='continue-with-btn'
                        />
                    </Modal.Body>
                </Modal>
                {/* forget Password modal */}
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.forgetModal} onHide={() => this.setState({ forgetModal: false })} className="signup-modal"
                >
                    {/* step 1 */}
                    {this.state.forgetEmailText ?
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <center>Forgotten your password?</center>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Don't worry, we'll send you a code to help you reset your password.</p>
                                <Form.Control type="email" placeholder="Email" onChange={(e) => this.setState({ forgetEmail: e.target.value })} />
                                {this.state.forgetEmailError ? <span className='text-danger'>{this.state.forgetEmailError}</span> : ''}
                                <button className='w-100 continue-btn' onClick={this.forgetApi} disabled={this.state.isloading ? true : false}>
                                    {this.state.isloading ?
                                        <Spinner animation="border" variant="light" size="sm" /> : 'Continue'}
                                </button>
                                {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center> : ''}
                            </Modal.Body>
                        </>
                        :
                        // step 2
                        this.state.forgetCodeText ?
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <center>Let us know it's you</center>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Last step! To secure your account, enter the code we just sent to<br /> {this.state.forgetEmail}.</p>
                                    <Form.Control type="number" placeholder="EnterCode" onChange={(e) => this.setState({ forgetEmailCode: e.target.value })} />
                                    {this.state.ForgotVerify ? <span className='text-danger'>{this.state.forgotVerifyMsg}</span> : ''}
                                    <button className='w-100 continue-btn' onClick={this.verifyNewOTP} disabled={this.state.isloading ? true : false}>
                                        {this.state.isloading ?
                                            <Spinner animation="border" variant="light" size="sm" /> : 'Continue'}
                                    </button>
                                    {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center> : ''}
                                </Modal.Body>
                            </>
                            :
                            // step 3
                            this.state.resetPasswordText ?
                                <>
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <center>Reset your password</center>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Please set a new password for your account.</p>
                                        <Form.Control className='position-relative' type={this.state.showPassword ? 'text' : 'password'} placeholder="New password" onChange={(e) => this.setState({ newPassword: e.target.value, passwordStrength: zxcvbn(e.target.value) })} />
                                        {this.state.showPassword ?
                                            <RiEyeOffLine className='password-show' onClick={() => this.setState(prevState => ({ showPassword: !prevState.showPassword }))} />
                                            :
                                            <RiEyeLine className='password-show' onClick={() => this.setState(prevState => ({ showPassword: !prevState.showPassword }))} />
                                        }
                                        <PasswordStrengthBar password={this.state.newPassword} />

                                        {this.state.passwordStrengthError ?
                                            <span className='text-danger mb-3 text-center'>Your password should be at least 8 characters long and contain at least one number with alphabets</span>
                                            : ""
                                        }
                                        <Form.Control className='position-relative' type={this.state.showConfirmPassword ? 'text' : 'password'} placeholder="Confirm new password" onChange={(e) => this.setState({ confirmNewPassword: e.target.value })} />
                                        {this.state.showConfirmPassword ?
                                            <RiEyeOffLine className='password-show' onClick={() => this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }))} />
                                            :
                                            <RiEyeLine className='password-show' onClick={() => this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }))} />
                                        }
                                        {this.state.resetPasswordError ? <span className='text-danger'>{this.state.resetPasswordError}</span> : ''}
                                        <button className='w-100 continue-btn' onClick={this.resetPassword} disabled={this.state.isloading ? true : false}>
                                            {this.state.isloading ?
                                                <Spinner animation="border" variant="light" size="sm" /> : 'Set Password'}
                                        </button>
                                        {this.state.errorAlert ? <center><p className='mt-3 text-danger'>{this.state.errorMsg}</p></center> : ''}
                                    </Modal.Body>
                                </>
                                :
                                ''}

                </Modal>
                {/* verify mobile OTP modal */}
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showmblOTPModal} onHide={() => this.setState({ showmblOTPModal: false })} className="signup-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <center>Verify Your phone number</center>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-center align-items-center otp-input">
                            <OtpInput
                                value={this.state.mblOTP}
                                onChange={mblOTP => this.setState({ mblOTP }, this.verifyMblOTP)}
                                numInputs={6}
                                renderSeparator={<span>  </span>}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus={true}
                            />
                        </div>
                        {/* <p>A one time password (OTP) has been sent to your phone, please enter one time password to verify your phone number.</p> */}
                        {/* <Form.Control type="number" placeholder="Enter One time password" value={this.state.mblOTP} onChange={this.verifyMblOTP}/> */}
                        {this.state.errorVerifymblOTP ? <h6 className='text-danger mb-3'>Verification Fails! Please enter correct one time password to verify your phone number. </h6> : ''}

                        {this.state.resendMblOTP ? <button className='resend-btn' onClick={() => this.setState({ showResendCaptcha: true }, this.requestAgainforMblOTP)} disabled={this.state.isResendMbl ? true : false}>{this.state.isResendMbl ?
                            <Spinner animation="border" variant="light" size="sm" /> : 'Resend'}</button> : ''}
                        {this.state.showResendCaptcha ?
                            <div className='d-flex justify-content-center align-items-center' id="resend-repatcha"></div>
                            : ''}

                        {this.state.showMblResendMsg ? <center><h6 className='text-success'>Code is Resent to your mobile number.</h6></center> : ''}
                    </Modal.Body>
                </Modal>
                {/* verify OTP modal */}
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.verifyOTP} onHide={() => this.setState({ verifyOTP: false })} className="signup-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <center>Verify Your Email Address</center>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMsg ? <center><h6 className='text-danger'>{this.state.errorMsg}</h6></center> : ''}
                        <p>A one time password (OTP) has been sent to your email address, please enter one time password to verify your email address.</p>
                        <Form.Control type="number" placeholder="Enter One time password" onChange={(e) => this.setState({ otpValue: e.target.value })} />
                        {this.state.errorVerify ? <h6 className='text-danger mb-3'>Verification Fails! Please enter correct one time password to verify your email address. </h6> : ''}
                        <div className='d-flex justify-content-between'>
                            {this.state.resend ? <button className='resend-btn' onClick={this.resendOTP} disabled={this.state.isResend ? true : false}>{this.state.isResend ?
                                <Spinner animation="border" variant="light" size="sm" /> : 'Resend'}</button> : ''}
                            <button className='verify-btn' onClick={this.verify} disabled={this.state.isloading ? true : false}>
                                {this.state.isloading ?
                                    <Spinner animation="border" variant="light" size="sm" /> : 'Verify'}
                            </button>
                        </div>
                        {this.state.showResendMsg ? <center><h6 className='text-success'>{this.state.resendMsg}</h6></center> : ''}
                    </Modal.Body>
                </Modal>
                {this.state.loginAlert ?
                    <Alert key='success' variant='success' className='log-alert'>
                        {this.state.loginMsg}
                    </Alert>
                    :
                    this.state.registerAlert ?
                        <Alert key='success' variant='success' className='log-alert'>
                            {this.state.registerMsg}
                        </Alert>
                        :
                        this.state.logoutAlert ?
                            <Alert key='success' variant='success' className='log-alert'>
                                Logout Successfully.
                            </Alert>
                            :
                            this.state.resetSuccess ?
                                <Alert key='success' variant='success' className='log-alert'>
                                    {this.state.resetSuccessMsg}
                                </Alert>
                                :
                                this.state.mblVerify ?
                                    <Alert key='success' variant='success' className='log-alert'>
                                        Mobile number verified successfully
                                    </Alert>
                                    :
                                    this.state.waitforRegister ?
                                        <Alert key='success' variant='success' className='log-alert'>
                                            Kindly wait while your registration is in progress
                                        </Alert>
                                        :
                                        this.state.registerMblError ?
                                            <Alert key='danger' variant='danger' className='log-alert'>
                                                {this.state.registerMblErrorMsg}
                                            </Alert>
                                            :
                                            ''
                }
            </div>
        );
    }
}

export default BecomeGuide;