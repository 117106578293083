import React, { Component } from 'react'

export default class Cookies extends Component {
  constructor(props){
    super(props);
    this.state={
      setCookie: false,
    };
  }
  render() {
    return (
      <>
       {this.state.setCookie ? 
      <div className='cookies'>
      <span>
        We and our partners use cookies to understand how you use our site, improve your experience 
        and serve you personalized content. Read about how we use cookies in our cookie policy and how 
        you can control them by clicking "Manage Settings". By continuing to use this site, you accept 
        these cookies.
        </span>
        <div className='d-flex justify-content-between mt-3'>
            <button className='manage-btn'>manage settings</button>
            <button className='accept-btn' onClick={()=>this.setState({setCookie: false})}>accept</button>
        </div>
      </div>
      : 
      ''
    }
      </>
     
    )
  }
}
