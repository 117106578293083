import React, { Component } from 'react'
import NavbarHome from '../Components/NavbarHome'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Footer from '../Components/Footer';
//images
import team1 from '../Assets/Images/team1.png';
import team2 from '../Assets/Images/team2.png';
import team3 from '../Assets/Images/team3.png';
import team4 from '../Assets/Images/team4.png';
import team5 from '../Assets/Images/team5.png';
import team6 from '../Assets/Images/team6.png';
import team7 from '../Assets/Images/team7.png';
import team8 from '../Assets/Images/team8.png';
import press1 from '../Assets/Images/press1.png';
import press2 from '../Assets/Images/press2.png';
import press3 from '../Assets/Images/press3.png';
import press4 from '../Assets/Images/press4.png';
import press5 from '../Assets/Images/press5.png';

export default class About extends Component {
  render() {
    return (
        <>
        
        <div className="body-bg">
        <NavbarHome/>
        <div className='about-banner'>
            <div className='about-banner-content'>
                <center>
                <h4>About Us</h4>
                <div className='about-txt'>WE ASPIRE TO CHANGE THE CONCEPT OF TRAVELLING BY MAKING IT MORE AUTHENTIC, MORE LOCAL.</div>
                </center>
            </div>
        </div>
        <div className='story-box'>
            <h3>our story</h3>
            <p className='story-para1'>There’s no reason to be just a mere tourist anymore, not when locals can show you an edgier, more beautiful and more authentic version of their city.</p>
            <p className='story-para2'>Dinfoo is all about the activities that happen when you’re not lounging around your hotel room, and they can start from the moment you arrive at your destination. From pick-up to departure, a Dinfoo local can be at your side – it’s like having a knowledgeable friend in every city you visit.</p>
        <Row className='w-100 mt-3'>
            <Col md={4} lg={4} xs={12} className="total">
                <h5><strong>Total countries</strong></h5>
                <div className='total-number'>224</div>
            </Col>
            <Col md={4} lg={4} xs={12} className="total">
                <h5><strong>Total cities</strong></h5>
                <div className='total-number'>13473</div>
            </Col>
            <Col md={4} lg={4} xs={12} className="total">
                <h5><strong>Total locals</strong></h5>
                <div className='total-number'>154031</div>
            </Col>
        </Row>
        </div>
       <div className='about-content'>
            {/*  <center>
            <h3><strong>OUR TEAM</strong></h3>
            <h5 className='text-gray'>Hello. It’s nice to finally meet you.</h5>
            <Row className='mt-3'>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team1} />
      <Card.Body>
        <Card.Title >Linas Sablovskis</Card.Title>
        <Card.Text>FOUNDER</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team2} />
      <Card.Body>
        <Card.Title >Martynas Balaisis</Card.Title>
        <Card.Text>coo</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team3} />
      <Card.Body>
        <Card.Title >Augustas Girdvainis</Card.Title>
        <Card.Text>PRODUCT MANAGER</Card.Text>
      </Card.Body>
    </Card>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team4} />
      <Card.Body>
        <Card.Title >Darius Burbulis</Card.Title>
        <Card.Text>BACK-END DEVELOPER</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team5} />
      <Card.Body>
        <Card.Title >Justas Kisunas</Card.Title>
        <Card.Text>MARKETING & ADVERTISING</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team6} />
      <Card.Body>
        <Card.Title >Vytautas Vasiliauskas</Card.Title>
        <Card.Text>IOS DEVELOPER</Card.Text>
      </Card.Body>
    </Card>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team7} />
      <Card.Body>
        <Card.Title >Tomas Burbulis</Card.Title>
        <Card.Text>BACK-END DEVELOPER</Card.Text>
      </Card.Body>
    </Card>
                </Col>
                <Col md={4} lg={4} xs={12}>
                <Card className='team-card'>
      <Card.Img variant="top" src={team8} />
      <Card.Body>
        <Card.Title >Vaiva Banyte</Card.Title>
        <Card.Text>COMMUNITY MANAGER</Card.Text>
      </Card.Body>
    </Card>
                </Col>
            </Row>
            </center> */}
<div className='bg-white mt-3'>
            <div className='press'>
                <h3>PRESS HIGHLIGHTS</h3>
                <Row>
                    <Col md={1} lg={1} xs={0}>
                    </Col>
                    <Col md={2} lg={2} xs={6}>
                        <img src={press1} alt="press" className='press-img'/>
                    </Col>
                    <Col md={2} lg={2} xs={6}>
                        <img src={press2} alt="press" className='press-img'/>
                    </Col>
                    <Col md={2} lg={2} xs={6}>
                        <img src={press3} alt="press" className='press-img'/>
                    </Col>
                    <Col md={2} lg={2} xs={6}>
                        <img src={press4} alt="press" className='press-img'/>
                    </Col>
                    <Col md={2} lg={2} xs={6}>
                        <img src={press5} alt="press" className='press-img'/>
                    </Col>
                    <Col md={1} lg={1} xs={0}>
                    </Col>
                </Row>
            </div>
        </div>
        </div>
        <Footer/>
        </div>
        
        </>
    )
  }
}
