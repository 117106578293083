import React, { Component } from 'react'
import Navbar from "../Components/NavbarHome";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import "../Assets/Styles/style.css";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
//images
import user from '../Assets/Images/user.png'
import { DynamicStar } from 'react-dynamic-star';
import { RiFilter2Line, RiWalkFill, RiMotorbikeFill, RiBikeFill } from "react-icons/ri";
import { MdMyLocation } from "react-icons/md"
import { Redirect } from 'react-router-dom'
import { GoogleApiWrapper } from 'google-maps-react';
// //slider
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import '../Assets/Styles/style.css';
// //images
// import slider1 from '../Assets/Images/development.png';
// import slider2 from '../Assets/Images/speech-bubble.png';
// import slider3 from '../Assets/Images/problem-solving.png';
import { api_url } from '../Variables';
const GOOGLE_API_KEY = process.env.REACT_APP_API_KEY
class SearchProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchProvider: [''],
      noResult: '',
      isloading: true,
      name: '',
      price: '',
      category: '',
      range: 0,
      redirect: null,
      categories: [''],
      filters: false,
      searchResult: [],
      btn_loader: false,
      pro_local: '',
      walk_bike: [],
      location_id: '',
      showLocations: false,
      lat: '',
      long: '',
      userCurrentCity: '',
      userCurrentRegion: '',
      userCurrentCountry: '',
      address: '',
      distance:'',
      showCustomDistance:false,
    }
  }
  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
    this.setState({ address })
  };
  componentDidMount() {
    this.getCategories();
    const getSearchProvider = JSON.parse(localStorage.getItem("search-provider"));
    if (getSearchProvider) {
      this.setState({ searchProvider: getSearchProvider, isloading: false }, () => {
        console.log(this.state.noResult);
      });
    } else {
      this.setState({ redirect: '/' })
    }
    const getSearchItems = JSON.parse(localStorage.getItem("search-items"));
    console.log('search items are', getSearchItems)
    if (getSearchItems) {
      this.setState({ address: getSearchItems.search, price: getSearchItems.price, 
        pro_local: getSearchItems.seller_type, walk_bike: getSearchItems.activity_type,
      distance: getSearchItems.distance })
      if (getSearchItems.price || getSearchItems.seller_type || getSearchItems.activity_type ) {
        this.setState({ filters: true })
      } else {
        this.setState({ filters: false })
      }
    }
    const google = window.google
    const geocoder = new google.maps.Geocoder();
    //find out if a user's geolocation is available or not.
    if ("geolocation" in navigator) {
      console.log("user's geolocation is Available");
      //Get the current position of the user 
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          lat: position.coords.latitude,
          long: position.coords.longitude,
          showMap: true
        })
        console.log('latitude is', this.state.lat);
        console.log('longitude is', this.state.long);
        this.geocodeLatLng(geocoder, position.coords.latitude, position.coords.longitude);
      }, error => {
        console.log(error.code, error.message);
      },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 10000,
        })
    } else {
      window.alert("Sorry!!! user's geolocation is Not Available");
    }
  }
  geocodeLatLng(geocoder, lati, longi) {
    const latlng = {
      lat: lati,
      lng: longi,
    };
    geocoder.geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          console.log(response.results[0])
          for (var i = 0; i < response.results[0].address_components.length; i++) {
            if (response.results[0].address_components[i].types[0] == "locality") {
              //this is the object for City
              this.setState({ userCurrentCity: response.results[0].address_components[i].long_name }, () => { console.log('city: ', this.state.userCurrentCity) })
            }
            if (response.results[0].address_components[i].types[0] == "administrative_area_level_1") {
              //this is the object for State
              this.setState({ userCurrentRegion: response.results[0].address_components[i].long_name }, () => { console.log('region: ', this.state.userCurrentRegion) })
            }
            if (response.results[0].address_components[i].types[0] == "country") {
              //this is the object for country
              this.setState({ userCurrentCountry: response.results[0].address_components[i].long_name }, () => { console.log('country: ', this.state.userCurrentCountry) })
            }
          }
        }
        else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }
  getSearch = async (e) => {
    this.setState({ name: e.target.value }, () => {
      var formdata = new FormData();
      formdata.append("search", this.state.name);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(api_url + "api/search/suggestion", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.setState({ searchResult: result, showLocations: true }, () => {
            console.log('fetched providers', this.state.searchResult);
          })
        })
        .catch(error => console.log('error', error));
    })
    ////////////
    // const city = this.state.name;
    //   let item = {city};
    //   console.log('city', JSON.stringify(item))
    //   let fetchSearch = await fetch(api_url+"api/search/city",{
    //     method: "post",
    //     headers : {
    //       "Content-Type": "multipart/form-data;application/json",
    //          'Accept': '*/*',
    //      },
    //      body: JSON.stringify(item)
    //   });
    //   fetchSearch = await fetchSearch.json();
    //   if(fetchSearch){
    //     this.setState({searchResult: fetchSearch}, ()=>{
    //       console.log('fetched result', this.state.searchResult);

    //     })
    //   }



  }
  search = async () => {
    console.log('search', this.state.name, this.state.category, this.state.price);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var formdata = new FormData();
    formdata.append("name", this.state.name);
    formdata.append("category_id", this.state.category);
    formdata.append("max_price", this.state.price);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url + "api/search_service", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        localStorage.setItem('search-result', JSON.stringify(result));
        const queryString = window.location.href; //get url
        const search = queryString.split('/')[3];
        // const onSearchPage = queryString.includes(search); // check if user is on search page
        if (search === 'search') { // if yes
          this.setState({ redirect: null });  // no redirect 
        } else {
          this.setState({ redirect: "/search" }); //redirect
        }
      })
      .catch(error => console.log('error', error));
    //////////////
    // const name= this.state.name;
    // const max_price= this.state.price;
    // const category_id = this.state.category;
    // let item ={name, max_price,  category_id};
    //   let result =  await fetch(api_url+"api/search_service", {
    //     method: "post",
    //     headers : {
    //          'Accept': 'application/json',
    //      },
    //      body: JSON.stringify(item)
    // });
    //   result = await result.json();
    //   console.warn('result is', result);
    //   localStorage.setItem('search-result', JSON.stringify(result));
    //   const queryString = window.location.href; //get url
    //   const search = queryString.split('/')[3];
    //   // const onSearchPage = queryString.includes(search); // check if user is on search page
    //   if(search === 'search'){ // if yes
    //     this.setState({ redirect: null });  // no redirect 
    //   }else{
    //     this.setState({ redirect: "/search" }); //redirect
    //   }

  }
  searchProvider = async () => {
    this.setState({ btn_loader: true })
    console.log('search', this.state.address, this.state.price, this.state.walk_bike,this.state.pro_local);
    const search = this.state.address;
    const price = this.state.price;
    const seller_type = this.state.pro_local;
    const activity_type = this.state.walk_bike; 
    const distance = this.state.distance;
    if(this.state.filters){
      let item = { search, price, seller_type, activity_type, distance }
      localStorage.setItem('search-items', JSON.stringify(item));
    }else{
      let item = { search }
      localStorage.setItem('search-items', JSON.stringify(item));
    }
    var formdata = new FormData();
    formdata.append("search", this.state.address);
    // formdata.append("category_id", this.state.category);
    if (this.state.filters) {
      formdata.append("price", this.state.price);
      formdata.append("seller_type", this.state.pro_local);
      if(this.state.walk_bike.length > 0){
        this.state.walk_bike.map((n)=>{
            formdata.append("activity_type[]", n);
          })
        }
      formdata.append("distance", this.state.distance);
    }
    if (this.state.location_id) {
      formdata.append("service_location_id", this.state.location_id);
    }
    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    fetch(api_url + "api/search_providers", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.data) {
          localStorage.setItem('search-provider', JSON.stringify(result.data));
          this.componentDidMount();
          this.setState({ btn_loader: false })
        }
        else {
          localStorage.setItem('search-provider', JSON.stringify(result.Result));
          this.componentDidMount();
          this.setState({ btn_loader: false })
        }
      })
      .catch(error => {
        console.log('error', error)
        this.setState({ btn_loader: false })
      });
    //////////
    // const search= this.state.name;
    // const max_price= this.state.price;
    // const category_id = this.state.category;
    // let item ={search,  category_id, max_price};
    // console.log(item)
    //   let result =  await fetch(api_url+"api/search_providers", {
    //     method: "post",
    //     headers : {
    //       "Content-Type": "multipart/form-data;application/json",
    //       'Accept': 'application/json',
    //      },
    //      body: JSON.stringify(item)
    // });
    //   result = await result.json();
    //   console.warn('result is', result);
    //   if(result.data){
    //     localStorage.setItem('search-provider', JSON.stringify(result.data));
    //   }
    //   else{
    //     localStorage.setItem('search-provider', JSON.stringify(result.Result));
    //   }
    //   this.componentDidMount();
    // const queryString = window.location.href; //get url
    // const searchp = '/searchProvider'
    // const onSearchPage = queryString.includes(searchp); // check if user is on search page
    // if(onSearchPage){ // if yes
    //   this.setState({ redirect: null });  // no redirect 
    // }else{
    //   this.setState({ redirect: "/searchProvider" }); //redirect
    // }

  }
  handleModeCheckboxes = (event) => {
    var updatedList = [...this.state.walk_bike];
    if (event.target.checked) {
        updatedList = [...this.state.walk_bike, event.target.value];
    } else {
        updatedList.splice(this.state.walk_bike.indexOf(event.target.value), 1);
    }
    this.setState({walk_bike: updatedList})
  };
  getCategories = async () => {
    let fetchCategories = await fetch(api_url + "api/categories", {
      method: "get",
      headers: {
        'Accept': '*/*'
      }
    });
    fetchCategories = await fetchCategories.json();
    if (fetchCategories) {
      this.setState({ categories: fetchCategories, isloading: false })
    }
    console.log('categories', fetchCategories);
  }
  onSelect = (option) => {
    console.log('Your selected id ', option.value)
    console.log('You selected category ', option.label)
    this.setState({ category: option.value })
  }
  getEventTarget(e) {
    e = e || window.event;
    return e.target || e.srcElement;
  }
  getClicked = (event) => {
    var target = this.getEventTarget(event);
    this.setState({ name: target.innerHTML });
  }
  showSliderValue = (e) => {
    const slider_input = document.getElementById('slider_input'),
      slider_thumb = document.getElementById('slider_thumb'),
      slider_thumb_value = document.getElementById('slider_thumb_value'),
      slider_line = document.getElementById('slider_line');
    slider_thumb_value.innerHTML = slider_input.value + '$';
    const bulletPosition = (slider_input.value / slider_input.max),
      space = slider_input.offsetWidth - slider_thumb.offsetWidth;

    slider_thumb.style.left = (bulletPosition * space) + 'px';
    slider_thumb_value.style.left = '-' + (bulletPosition * space) + 'px';
    slider_line.style.width = (slider_input.value / 10) + '%';
    this.setState({ price: e.target.value })
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <>
        <Navbar />
        {/* <Banner/> */}
        <>
          <div className='banner-img'>
            <div className='banner-content'>
              <Row>
                <Col lg={9} md={9} xs={12}>
                  <h2>connecting</h2>
                  <h5>the world</h5>
                  <div className='search-section'>
                    <div className='d-flex position-relative'>
                      {/* <Form.Control type="text" placeholder="Where are you going ?" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/> */}
                      {/* <input list="browsers" className='form-control' autoComplete='off' value={this.state.name} placeholder="Where are you going ?" name="browser" id="browser" onChange={(e) => this.setState({name:e.target.value ,})}/> */}
                      {/* <input list="browsers" className='form-control' autoComplete='off' value={this.state.name} placeholder="Where are you going ?" name="browser" id="browser" onChange={(e) => this.setState({ name: e.target.value, }, this.getSearch)} />
                    <datalist id="browsers" onChange={(e) => this.setState({ name: e.target.value })}>
                      {this.state.searchResult.length > 0 ?
                        this.state.searchResult.map((n) => (
                          <option value={n.id}>{n.place}</option>
                        ))
                        :
                        ''
                      }
                    </datalist> */}
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div key={suggestions.description} className="w-100">
                            <div className='d-flex position-relative'>
                              <input
                                {...getInputProps({
                                  placeholder: "Where are you going ?",
                                  className: 'form-control location-text-field'
                                })}
                              />
                              <MdMyLocation className='current-location-icon' title='Current Location' onClick={() => this.setState({ address: this.state.userCurrentCity + ', ' + this.state.userCurrentCountry })} />
                              &nbsp;&nbsp;
                              <Button className='primary-btn' id="button-addon2" onClick={this.searchProvider} disabled={this.state.isloading ? true : false}>
                                {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm" /> : 'SEARCH'}
                              </Button>
                            </div>
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { background: '#ced4da', padding: '10px', borderBottom: '1px solid #ced4da', cursor: 'pointer', color: '#000' }
                                  : { background: '#fff', padding: '10px', borderBottom: '1px solid #ced4da', cursor: 'pointer', color: '#000' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {/* <input className="form-control location-text-field" type="text" value={this.state.name} placeholder="Where are you going ?" onChange={(e) =>this.getSearch(e)} />
                    <MdMyLocation className='current-location-icon' title='Current Location' onClick={()=>this.setState({name:this.state.userCurrentCity+', '+this.state.userCurrentRegion+', '+this.state.userCurrentCountry})}/>
                     &nbsp;&nbsp;
                    <Button className='primary-btn' id="button-addon2" onClick={this.searchProvider} disabled={this.state.isloading ? true : false}>
                      {this.state.btn_loader ? <Spinner animation="border" variant="light" size="sm" /> : 'SEARCH'}
                    </Button> */}
                    </div>
                    {/* {this.state.name ? 
              <ul className='search-dropdown' onClick={this.getClicked}>
                <li>São Paulo, State of São Paulo, Brazil</li>
                <li>São Paulo, State of São Paulo</li>
                <li>São Paulo, State of São</li>
              </ul>
            :
            ''
              } */}
                    {this.state.showLocations ?
                      <ul className='search-result-list'>
                        {this.state.searchResult ?
                          this.state.searchResult.length > 0 ?
                            this.state.searchResult.map((n) => (
                              <li id={n.id} onClick={(e) => this.setState({ location_id: e.target.id, name: n.place, showLocations: false })}>{n.place}</li>
                            ))
                            :
                            this.state.filters ? '' :
                              <strong className='d-flex justify-content-end cursor-pointer position-relative zIndex-0' onClick={() => this.setState(prevState => ({ filters: !prevState.filters }))}>
                                <RiFilter2Line className='mt-1' /><span>Advanced Filters</span>
                              </strong>
                          :
                          ''
                        }
                      </ul>
                      :
                      this.state.filters ? '' :
                        <strong className='d-flex justify-content-end cursor-pointer position-relative zIndex-0' onClick={() => this.setState(prevState => ({ filters: !prevState.filters }))}>
                          <RiFilter2Line className='mt-1' /><span>Advanced Filters</span>
                        </strong>
                    }
                  </div>
                  {this.state.filters ?
                    <Row className='advanced-filters'>
                      <strong className='d-flex justify-content-end cursor-pointer position-relative zIndex-0' onClick={() => this.setState(prevState => ({ filters: !prevState.filters }))}>
                        <RiFilter2Line className='mt-1' /><span>Advanced Filters</span>
                      </strong>
                      <Col md={6} sm={12} className='mb-2'>
                        <h6>Select Price:</h6>
                        <div className='d-flex'>
                          <p className='mb-0 mr-15'>
                            <input type="radio" id="p1" name="radio-price" checked={this.state.price === 10} onChange={() => this.setState({ price: 10 })} />
                            <label for="p1">0-10</label>
                          </p>
                          <p className='mb-0 mr-15'>
                            <input type="radio" id="p2" name="radio-price" checked={this.state.price === 20} onChange={() => this.setState({ price: 20 })} />
                            <label for="p2">10-20</label>
                          </p>
                          <p className='mb-0 mr-15'>
                            <input type="radio" id="p3" name="radio-price" checked={this.state.price === 50} onChange={() => this.setState({ price: 50 })} />
                            <label for="p3">20-50</label>
                          </p>
                          <p className='mb-0 mr-15'>
                            <input type="radio" id="p4" name="radio-price" checked={this.state.price === 1000} onChange={() => this.setState({ price: 1000 })} />
                            <label for="p4">50+</label>
                          </p>
                        </div>
                        {/* <div class="range-slider">
                      <h6>Select Price: <span id="slider_thumb_value">{this.state.price}$</span></h6>
                      <div id="slider_thumb"  class="range-slider_thumb"></div>
                      <div class="range-slider_line">
                        <div id="slider_line" class="range-slider_line-fill"></div>
                      </div>
                      <input id="slider_input" class="range-slider_input" type="range" value={this.state.price} min="0" max="1000" onChange={this.showSliderValue} />
                    </div>  */}
                      </Col>
                      <Col md={3} sm={12} className='mb-2'>
                        <h6>Guide Type</h6>
                        <div className='d-flex'>
                          <p className='mb-0 mr-15'>
                            <input type="radio" id="test1" value="Pro" title='Professional' name="radio-group" checked={this.state.pro_local === "Pro"} onChange={(e) => this.setState({ pro_local: e.target.value })} />
                            <label for="test1">Professional</label>
                          </p>
                          <p className='mb-0'>
                            <input type="radio" id="test2" value="Local" title="Local" name="radio-group" checked={this.state.pro_local === "Local"} onChange={(e) => this.setState({ pro_local: e.target.value })} />
                            <label for="test2">Local</label>
                          </p>
                        </div>
                      </Col>
                      <Col md={3} sm={12} className='mb-2'>
                    <h6 className='mode-heading'>Preferred Mode</h6>
                    <div className='d-flex mode-radios'>
        <div className='d-flex justify-content-start align-items-center'>
        <div className='square-checkbox'>
          <Form.Check type='checkbox' name='mode' checked={this.state.walk_bike.includes('Walk') || this.state.walk_bike.includes('walk') ? true : false} value='Walk' onChange={(e) => this.handleModeCheckboxes(e)}/>
        </div>
          <RiWalkFill title='Walk' className='p-mode-icons' />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className='d-flex justify-content-start align-items-center'>
        <div className='square-checkbox'>
          <Form.Check type='checkbox' name='mode' checked={this.state.walk_bike.includes('Bike') || this.state.walk_bike.includes('bike') ? true : false} value='Bike' onChange={(e) => this.handleModeCheckboxes(e)}/>
        </div>
          <RiMotorbikeFill title='Bike' className='p-mode-icons' /> 
        </div>
        </div>
                  {/* <div className='d-flex mode-radios'>
                      <p className='mb-0 mr-15'>
                      <div className='square-checkbox'>
                        <input type="checkbox" id="opt1" value="Walk" onChange={(e) => this.setState({ walk_bike: e.target.value })} />
                      </div>
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Walk')}>
                          <label for="opt1"><RiWalkFill className='mode-icons' /></label>
                        </OverlayTrigger>
                      </p>
                      <p className='mb-0'>
                        <input type="checkbox" id="opt2" value="Bike" onChange={(e) => this.setState({ walk_bike: e.target.value })} />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Bike')}>
                        <label for="opt2"><RiMotorbikeFill className='mode-icons' /></label>
                        </OverlayTrigger>
                      </p>
                    </div> */}
                  </Col>
                      {this.state.address ? 
                  <>
                  <Col md={7} sm={12} className='mb-2'>
                    <h6>Select Distance:</h6>
                    <div className='d-flex'>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d1" name="radio-distance" checked={this.state.distance === 1} onChange={() => this.setState({ distance: 1, showCustomDistance:false })} />
                        <label for="d1">1 mile</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d2" name="radio-distance" checked={this.state.distance === 2} onChange={() => this.setState({ distance: 2, showCustomDistance:false })} />
                        <label for="d2">2 miles</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d3" name="radio-distance" checked={this.state.distance === 3} onChange={() => this.setState({ distance: 3, showCustomDistance:false })} />
                        <label for="d3">3 miles</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d4" name="radio-distance" onChange={() => this.setState({ showCustomDistance:true })} />
                        <label for="d4">custom</label>
                      </p>
                    </div>
                  </Col>
                  <Col md={5} sm={12} className='mb-2'>
                  {this.state.showCustomDistance ?
                  <>
                  <h6 className='mb-0'>Enter Distance:</h6>
                    <Form.Control type="number" className='custom-distance-field' placeholder="Enter Custom Distance" onChange={(e) => this.setState({distance: e.target.value})}/>
                  </>
                    :''}
                    </Col>
                  </>
                  :''}
                    </Row>
                    :
                    ''
                  }
                  {/* <Tabs variant='pills' defaultActiveKey="tab-1" clasName="mb-1 p-0">
                <Tab eventKey="tab-1" title="Services" className='search-tabs'>
                //  services content 
                <InputGroup className="mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Name</Form.Label>
                    <div className='d-flex'>
                    <RiBuilding2Line className='search-tabs-icons'/>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})}/>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Categories</Form.Label>
                    <div className='d-flex'>
                    <RiLayoutMasonryFill className='search-tabs-icons'/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
        />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                    <Form.Label>Rate</Form.Label>
                    <div className='d-flex'>
                    <RiRouteFill className='search-tabs-icons'/>
                    <div className='d-flex'>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                    //  <Form.Range onChange={(e) => this.setState({price: e.target.value})}/>
                    </div>
                  </div>
                </Form.Group>
                <Button variant="primary" id="button-addon2" onClick={this.search}>
                    SEARCH
                </Button>
                </InputGroup>
                //  mobile form
                <div className='mobile-form'>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})} className="mt-2"/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
          className="mt-2"
        />
                    <div className='d-flex justify-content-between mt-2'>
                      <span className='text-white'>Rate: </span>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                <Button variant="primary" id="button-addon2" onClick={this.search} className="mt-2">
                    SEARCH
                </Button>
                </div>
                  // services content end 
                </Tab>
                <Tab eventKey="tab-2" title="Providers" className='search-tabs'>
                //  providers content 
                  <InputGroup className="mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Name</Form.Label>
                    <div className='d-flex'>
                    <RiBuilding2Line className='search-tabs-icons'/>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})}/>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Categories</Form.Label>
                    <div className='d-flex'>
                    <RiLayoutMasonryFill className='search-tabs-icons'/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
          className="mt-2"
        />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                    <Form.Label>Rate</Form.Label>
                    <div className='d-flex'>
                    <RiRouteFill className='search-tabs-icons'/>
                    <div className='d-flex'>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                    </div>
                  </div>
                </Form.Group>
                <Button variant="primary" id="button-addon2" onClick={this.searchProvider}>
                    SEARCH
                </Button>
                </InputGroup>
                //  mobile form 
                 <div className='mobile-form'>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})} className="mt-2"/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
          className="mt-2"
        />
                    <div className='d-flex justify-content-between mt-2'>
                      <span className='text-white'>Rate: </span>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                <Button variant="primary" id="button-addon2" onClick={this.searchProvider} className="mt-2">
                    SEARCH
                </Button>
                </div>
                  // providers content end
                </Tab>
            </Tabs> */}
                  <div className='backdrop'>

                  </div>
                </Col>
                {/* <Col lg={3} md={3} xs={12} className='d-flex flex-column how-it-works'>
                  <span className='how-txt'>How It Works</span>
                <Slider {...settings} className="banner-top-carousel">
                <div>
                    <img src={slider1} alt="carousel" className='banner-carousel-img'/>
                    <div className='img-contnt'>
                      <h6><strong>Browse Locals</strong></h6>
                    </div>
                </div>
                <div>
                    <img src={slider2} alt="carousel" className='banner-carousel-img'/>
                    <div className='img-contnt'>
                      <h6><strong>Plan & Discuss</strong></h6>
                    </div>
                </div>
                <div>
                    <img src={slider3} alt="carousel" className='banner-carousel-img'/>
                    <div className='img-contnt'>
                      <h6><strong>Experience</strong></h6>
                    </div>
                </div>
            </Slider>
                </Col> */}
              </Row>

            </div>
            <div className='banner-blur'></div>
          </div>
        </>
        <div className="content">
          {/* fetched data */}
          <Row>
            {this.state.isloading ?
              <center><div class="loader"></div></center>
              :
              Array.isArray(this.state.searchProvider) ?
                this.state.searchProvider.map((n) => (
                  <>
                    {n.status === 'delete' ?
                      <></>
                      :
                      <Col md={6} lg={6} xs={12}>
                        <Link to={{ pathname: "/provider/" + n.id, state: n.id }}>
                          <div className="card">
                            <div className="card-horizontal">
                              <div className="img-square-wrapper">
                                <img className="" src={n.profile_image != null ? n.profile_image : user} alt="property" />
                                {n.seller_type ?
                                  <span className="seller-type">{n.seller_type}</span>
                                  : ''}
                              </div>
                              <div className="card-body">
                                <div className="d-flex justify-content-between name-price">
                                  <div>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <h5 className="card-title text-capitalize mr-3">{n.name}</h5>
                                      {n.preferred_modes ?
                          n.preferred_modes.length > 0 ?
                          n.preferred_modes.map((n)=>(
                            <>
                            <> 
                            {n == 'walk' || n == 'Walk' ?
                            <RiWalkFill className="provider-mode"/>
                            :''}
                            </>
                            <>
                            {n == 'bike' || n == 'Bike' ?
                            <RiBikeFill className="provider-mode"/>
                            :''}
                            </>
                            </>
                          ))
                        :'':''}

                                    </div>
                                    <p className="card-text d-flex location-txt">
                                      {n.address ? n.address : ''}
                                    </p>
                                  </div>
                                  {n.hourly_rate ?
                                    <div className="price">
                                      <strong >${n.hourly_rate}</strong>/h
                                    </div>
                                    : ''}
                                </div>
                                {n.about ?
                                  <blockquote>
                                    <q>{n.about}</q>
                                  </blockquote>
                                  :
                                  ''
                                }
                                {n.review.length > 0 ?
                                  <div className="d-flex justify-content-between ratings">
                                    <div className="d-flex flex-column justify-content-between review ">
                                      <div><center>Reviews</center></div>
                                      <h6 className="mb-0">
                                        <center className="mt-3">
                                          <strong>{n.review[0].total_no_of_reviews}</strong>
                                        </center>
                                      </h6>
                                    </div>
                                    <div className="d-flex flex-column justify-content-between review">
                                      <div>
                                        <center>Ratings</center>
                                      </div>
                                      <center>
                                        <DynamicStar rating={n.review[0].average_ratings} outlined={true} width='15' height='15' />
                                      </center>
                                    </div>
                                  </div>
                                  :
                                  ''}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      //             <Col md={4} lg={4} xs={12}>
                      //             <Link to={{pathname: "/service/"+ n.id+"/"+n.provider_id, state : {service_id: n.id, provider_id: n.provider_id}}}>
                      //             <main class="main">
                      //   <div class="left">
                      //     {/* <div class="date">
                      //       Thursday, 8 December 2022
                      //     </div> */}
                      //     <div class="city">
                      //     {n.name}
                      //     </div>
                      //     <div class="tempreture">
                      //       <img src={payment} alt="icon" class="left-img" />
                      //       <div>{new Intl.NumberFormat().format(n.price)}<span class="date">/{n.service_type}</span></div>
                      //     </div>
                      //   </div>

                      //   <div class="right">
                      //     <div class="city-img">
                      //       <img src={n.service_image ? n.service_image : user} alt="category" class="right-img" />
                      //     </div>
                      //   </div>
                      // </main>
                      //             {/* <div className="card">
                      //               <div className="card-horizontal">
                      //                 <div className="img-square-wrapper">
                      //                   <img className="" src={n.service_img ? 'https://api.dinfoo.codiro.tech/'+n.service_img : user} alt="property" />
                      //                 </div>
                      //                 <div className="card-body">
                      //                   <div className="d-flex justify-content-between name-price">
                      //                     <div>
                      //                       <h6 className="card-title">{n.name}</h6>
                      //                        <p className="card-text d-flex location-txt">
                      //                         Lahore, Pakistan
                      //                       </p> 
                      //                     </div>
                      //                     <div className="price text-primary">
                      //                       <p><strong>{new Intl.NumberFormat().format(n.price)}/</strong>{n.service_type}</p>
                      //                     </div>
                      //                   </div>
                      //                   <blockquote>
                      //                     <q>{n.description}</q>
                      //                   </blockquote>
                      //                   <div className="d-flex justify-content-around">
                      //                     <div className="d-flex flex-column justify-content-center review">
                      //                       <div>Reviews</div>
                      //                       <h6>
                      //                         <center>
                      //                           <strong>1</strong>
                      //                         </center>
                      //                       </h6>
                      //                     </div>
                      //                     <div className="d-flex flex-column justify-content-center review">
                      //                       <div>
                      //                         <center>Ratings</center>
                      //                       </div>
                      //                       <h6>
                      //                       <div class="rate">
                      //                           <input type="radio" id="guide1star5" checked name="guide1star5" value="5"/>
                      //                           <label for="guide1star5" title="text">5 stars</label>
                      //                           <input type="radio" id="guide1star4" name="guide1star4" value="4"/>
                      //                           <label for="guide1star4" title="text">4 stars</label>
                      //                           <input type="radio" id="guide6star3" name="guide1star3" value="3"/>
                      //                           <label for="guide1star3" title="text">3 stars</label>
                      //                           <input type="radio" id="guide6star2" name="guide1star2" value="2"/>
                      //                           <label for="guide1star2" title="text">2 stars</label>
                      //                           <input type="radio" id="guide6star1" name="guide1star1" value="1"/>
                      //                           <label for="guide1star1" title="text">1 star</label>
                      //                         </div>
                      //                       </h6>
                      //                     </div>
                      //                   </div>
                      //                 </div>
                      //               </div>
                      //             </div> */}
                      //             </Link>
                      //           </Col>
                    }
                  </>
                ))
                :
                <center>No Data not Found</center>
            }

          </Row>
        </div>
        <Footer />
      </>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: (GOOGLE_API_KEY)
})(SearchProvider);