import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyDEdPezACaPsnIiJgP5m_ez6xWXISGnuu8",
    authDomain: "phone-auth-f38bb.firebaseapp.com",
    projectId: "phone-auth-f38bb",
    storageBucket: "phone-auth-f38bb.appspot.com",
    messagingSenderId: "923080661421",
    appId: "1:923080661421:web:96b34aba6744c23b38e924"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);