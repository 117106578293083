import React, { Component } from 'react';
import NavbarHome from '../Components/NavbarHome';
import Footer from '../Components/Footer';

class TermsConditions extends Component {
    render() {
        return (
            <div>
                <NavbarHome/>
                <Footer/>
            </div>
        );
    }
}

export default TermsConditions;