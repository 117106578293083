import React, { Component } from 'react';
import NavbarHome from "../Components/NavbarHome";
import { Row, Col, Card, Spinner, Modal, Form, Alert, Accordion, Container,OverlayTrigger,Tooltip } from "react-bootstrap";
import Footer from "../Components/Footer";
import "../Assets/Styles/style.css";
import { withRouter } from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom'
import { api_url } from '../Variables';
import { RiEyeLine, RiEyeOffLine,RiBikeFill, RiWalkFill } from 'react-icons/ri'
import Banner from '../Components/Banner';
import { DynamicStar } from 'react-dynamic-star';
import user from '../Assets/Images/user.png'
class Favorites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            allFavProviders: [],
            allFavServices: [],
            viewNote: false,
            isloader: false,
            note:'',
            item_id:''
        }
    }
    componentDidMount = async () => {
        const getToken = JSON.parse(localStorage.getItem("token"));
        if (getToken) {
            const token = getToken.token;
            console.log('user logged in')
            this.setState({ token: token })
            this.getFavoriteList()
        }
        else {
            this.setState({ token: '' })
        }

    }
    getFavoriteList = async () => {
        this.setState({ isloader: true })
        var myHeaders = new Headers();
        const gettoken = JSON.parse(localStorage.getItem("token"));
        const userToken = gettoken.token;
        myHeaders.append("Authorization", `Bearer ${userToken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(api_url + "api/seeker/show_all_favourites", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if(result.length > 0){
                    result.map((n) => {
                        // if (n.service_id) { // from favorites list of providers and services, fetch only services
                        //     this.setState(prevState => ({
                        //         allFavServices: [...prevState.allFavServices, n]
                        //     }), () => {
                        //         console.log('fav services', this.state.allFavServices)
                        //         this.setState({ isloader: false })
                        //     })
                        // }
                        if (n.provider_id) {
                            this.setState(prevState => ({
                                allFavProviders: [...prevState.allFavProviders, n]
                            }), () => {
                                console.log('fav provider', this.state.allFavProviders)
                                this.setState({ isloader: false })
                            })
                        }
                    })
                }else{
                    this.setState({isloader: false})
                }
            })
            .catch(error => console.log('error', error));
    }
    getNote = async(event)=>{
        this.setState({viewNote:true, item_id:event.target.id},()=>{
            console.log(this.state.item_id)
                    var myHeaders = new Headers();
                    const gettoken = JSON.parse(localStorage.getItem("token"));
                    const userToken = gettoken.token;
                    myHeaders.append("Authorization", `Bearer ${userToken}`);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
            fetch(api_url+"api/seeker/show_favourite_item_detail/"+this.state.item_id, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
            })
              .catch(error => {
                console.log('error', error)
            });
        })
    }
    render() {
      const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
          {text}
        </Tooltip>
      );
        return (
            <div>
                <NavbarHome />
                <Banner/>
                <div className="body-bg">
                    <div class="content">
                        <Container className='py-3'>
                          {this.state.isloader ?
                                <center>
                                <div class="lds-facebook"><div></div><div></div><div></div></div>
                              </center>
                                :
                          this.state.allFavProviders ? 
                          this.state.allFavProviders.length > 0 ?
                          <>
                           <center>
                                <h3>My Favorites</h3>
                            </center>
                            {this.state.isloader ?
                                <center>
                                <div class="lds-facebook"><div></div><div></div><div></div></div>
                              </center>
                                :
                                <Row>
                                   {this.state.allFavProviders.map((n)=>(
                                      <Col md={6} lg={6} xs={12}>
                                         <Link to={{pathname: "/provider/"+ n.provider_id, state : n.provider_id}}>
                                       <div className="card">
                                         <div className="card-horizontal">
                                           <div className="img-square-wrapper">
                         <img className="" src={n.profile_img != null ? n.profile_img : user} alt="property" />
                         {n.provider_guide_type ?
                     <span className="seller-type">{n.provider_guide_type}</span>
                     :''}
                                           </div>
                                           <div className="card-body">
                         <div className="d-flex justify-content-between name-price">
                           <div>
                             <div className="d-flex justify-content-start align-items-center">
                             <h5 className="card-title text-capitalize">{n.provider_name}</h5>
                             {n.preferred_modes ?
                          n.preferred_modes.length > 0 ?
                          n.preferred_modes.map((n)=>(
                            <>
                            <> 
                            {n == 'walk' || n == 'Walk' ?
                            <RiWalkFill className="provider-mode"/>
                            :''}
                            </>
                            <>
                            {n == 'bike' || n == 'Bike' ?
                            <RiBikeFill className="provider-mode"/>
                            :''}
                            </>
                            </>
                          ))
                        :'':''}
                          {/* {n.provider_preferred_mode == 'walk' || n.provider_preferred_mode == 'Walk' ?
                          <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Walk')}>
                            <RiWalkFill className="provider-mode"/>
                          </OverlayTrigger>
                          :
                          n.provider_preferred_mode == 'bike' || n.provider_preferred_mode == 'Bike' ? 
                          <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Bike')}>
                            <RiBikeFill className="provider-mode"/>
                          </OverlayTrigger>
                          :''} */}
                          
                        </div>
                             <p className="card-text d-flex location-txt">
                               {n.provider_address ? n.provider_address : ''}
                             </p>
                           </div>
                           {n.provider_hourly_rate ? 
                        <div className="price">
                          <strong >${n.provider_hourly_rate}</strong>/h
                       </div> 
                       :''}
                         </div>
                         {n.about ?
                         <blockquote>
                          <q>{n.about}</q>
                         </blockquote>
                         :
                         ''
                        }
                        <p className="card-text text-center location-txt bold my-2">
                               {n.note}
                             </p>
                          {n.review ?
                          n.review[0] ?
                         <div className="d-flex justify-content-between ratings">
                           <div className="d-flex flex-column justify-content-between review ">
                             <div><center>Reviews</center></div>
                             <h6  className="mb-0">
                               <center  className="mt-3">
                                 <strong>{n.review[0] ? n.review[0].total_no_of_reviews : ''}</strong>
                               </center>
                             </h6>
                           </div>
                           <div className="d-flex flex-column justify-content-between review">
                             <div>
                               <center>Ratings</center>
                             </div>
                             <center>
                             <DynamicStar rating={n.review[0] ? n.review[0].average_ratings : ''} outlined={true} width='15' height='15'/>
                             </center>
                           </div>
                         </div>
                         :
                         ''
                          :
                          ''}
                                           </div>
                                         </div>
                                       </div>
                                       </Link>
                                     </Col>
                                    ))}
                                </Row>
                            }
                          </>
                          :
                          <center>
                                <h3>No Favorites items yet!!</h3>
                            </center>
                          :
                          ''
                          }
                            {/* <center>
                                <h3>My Favorites</h3>
                            </center>
                            {this.state.isloader ?
                                <center>
                                <div class="lds-facebook"><div></div><div></div><div></div></div>
                              </center>
                                :
                                <Row>
                                   {this.state.allFavProviders.map((n)=>(
                                      <Col md={6} lg={6} xs={12}>
                                         <Link to={{pathname: "/provider/"+ n.provider_id, state : n.provider_id}}>
                                       <div className="card">
                                         <div className="card-horizontal">
                                           <div className="img-square-wrapper">
                         <img className="" src={n.profile_img != null ? n.profile_img : user} alt="property" />
                                           </div>
                                           <div className="card-body">
                         <div className="d-flex justify-content-between name-price">
                           <div>
                             <h5 className="card-title text-capitalize">{n.provider_first_name ? n.provider_first_name + " " + n.provider_last_name : n.provider_name}</h5>
                             <p className="card-text d-flex location-txt">
                               {n.provider_city ? n.provider_city : '' +', '+ n.provider_country ? n.provider_country : ''}
                             </p>
                           </div>
                         </div>
                         {n.about ?
                         <blockquote>
                          <q>{n.about}</q>
                         </blockquote>
                         :
                         ''
                        }
                          {n.review ?
                          n.review[0] ?
                         <div className="d-flex justify-content-between ratings">
                           <div className="d-flex flex-column justify-content-between review ">
                             <div><center>Reviews</center></div>
                             <h6  className="mb-0">
                               <center  className="mt-3">
                                 <strong>{n.review[0] ? n.review[0].total_no_of_reviews : ''}</strong>
                               </center>
                             </h6>
                           </div>
                           <div className="d-flex flex-column justify-content-between review">
                             <div>
                               <center>Ratings</center>
                             </div>
                             <center>
                             <DynamicStar rating={n.review[0] ? n.review[0].average_ratings : ''} outlined={true} width='15' height='15'/>
                             </center>
                           </div>
                         </div>
                         :
                         ''
                          :
                          ''}
                                           </div>
                                         </div>
                                       </div>
                                       </Link>
                                     </Col>
                                    ))}
                                </Row>
                            } */}
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Favorites;