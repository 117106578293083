import React, { Component } from 'react'
import { Row, Col, Spinner,OverlayTrigger,Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { RiFilter2Line, RiMotorbikeFill, RiWalkFill } from "react-icons/ri";
import {MdMyLocation} from "react-icons/md"
import '../Assets/Styles/style.css';
import { Redirect } from 'react-router-dom'
import {GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete,{
  geocodeByAddress,
} from 'react-places-autocomplete';
// //slider
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import '../Assets/Styles/style.css';
// //images
// import slider1 from '../Assets/Images/development.png';
// import slider2 from '../Assets/Images/speech-bubble.png';
// import slider3 from '../Assets/Images/problem-solving.png';
import { api_url } from '../Variables';
const GOOGLE_API_KEY = process.env.REACT_APP_API_KEY
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      price: '',
      category: '',
      range: 0,
      redirect: null,
      categories: [''],
      filters: false,
      selectedName: '',
      searchResult: [],
      isloading: false,
      pro_local: '',
      walk_bike: [],
      location_id:'',
      showLocations:false,
      lat:'',
      long:'',
      userCurrentCity:'',
      userCurrentRegion:'',
      userCurrentCountry:'',
      address: '',
      distance:'',
      showCustomDistance:false,
      
    }
  }
  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
    this.setState({address})
  };

  componentDidMount = async () => {
    // this.getCategories();
    const google = window.google
    const geocoder = new google.maps.Geocoder();
     //find out if a user's geolocation is available or not.
     if ("geolocation" in navigator) {
      console.log("user's geolocation is Available");
      //Get the current position of the user 
      navigator.geolocation.getCurrentPosition((position) =>{
        this.setState({
          lat: position.coords.latitude,
          long: position.coords.longitude,
          showMap:true
        })
           this.geocodeLatLng(geocoder,position.coords.latitude,position.coords.longitude);
      },error => {
        console.log(error.code, error.message);
      },
      {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 10000,
      })
    } else {
      window.alert("Sorry!!! user's geolocation is Not Available");
    }
  }
  geocodeLatLng(geocoder,lati,longi) {
    const latlng = {
      lat: lati, 
      lng: longi,
    };
    geocoder.geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          for (var i=0; i<response.results[0].address_components.length; i++)
          {
              if (response.results[0].address_components[i].types[0] == "locality") {
                      //this is the object for City
                      this.setState({userCurrentCity:response.results[0].address_components[i].long_name})
                  }
              if (response.results[0].address_components[i].types[0] == "administrative_area_level_1") {
                      //this is the object for State
                      this.setState({userCurrentRegion:response.results[0].address_components[i].long_name})
                  }
              if (response.results[0].address_components[i].types[0] == "country") {
                      //this is the object for country
                      this.setState({userCurrentCountry:response.results[0].address_components[i].long_name})
                  }
          }
        } 
        else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }
  search = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var formdata = new FormData();
    formdata.append("name", this.state.name);
    formdata.append("category_id", this.state.category);
    formdata.append("max_price", this.state.price);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url + "api/search_service", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        localStorage.setItem('search-result', JSON.stringify(result));
        const queryString = window.location.href; //get url
        const search = '/search'
        const onSearchPage = queryString.includes(search); // check if user is on search page
        if (onSearchPage) { // if yes
          this.setState({ redirect: null });  // no redirect 
        } else {
          this.setState({ redirect: "/search" }); //redirect
        }
      })
      .catch(error => console.log('error', error));
    // const name= this.state.name;
    // const max_price= this.state.price;
    // const category_id = this.state.category;
    // let item ={name, max_price,  category_id};
    //   let result =  await fetch(api_url+"api/search_service", {
    //     method: "post",
    //     headers : {
    //          'Accept': 'application/json',
    //      },
    //      body: JSON.stringify(item)
    // });
    //   result = await result.json();
    //   console.warn('result is', result);
    //   localStorage.setItem('search-result', JSON.stringify(result));
    //   const queryString = window.location.href; //get url
    //   const search = '/search'
    //   const onSearchPage = queryString.includes(search); // check if user is on search page
    //   if(onSearchPage){ // if yes
    //     this.setState({ redirect: null });  // no redirect 
    //   }else{
    //     this.setState({ redirect: "/search" }); //redirect
    //   }

  }
  searchProvider = async () => {
    //////
    this.setState({ isloading: true });
    const search = this.state.address;
    const price = this.state.price;
    const seller_type = this.state.pro_local;
    const activity_type = this.state.walk_bike;
    const distance = this.state.distance;
    if(this.state.filters){
      let item = { search, price, seller_type, activity_type, distance }
      localStorage.setItem('search-items', JSON.stringify(item));
    }else{
      let item = { search }
      localStorage.setItem('search-items', JSON.stringify(item));
    }

    var formdata = new FormData();
    formdata.append("search", this.state.address);
    if(this.state.filters){
      formdata.append("price", this.state.price);
      formdata.append("seller_type", this.state.pro_local);
      if(this.state.walk_bike.length > 0){
        this.state.walk_bike.map((n)=>{
            formdata.append("activity_type[]", n);
          })
        }
      formdata.append("distance", this.state.distance);
    
    }
    if(this.state.location_id){
      formdata.append("service_location_id", this.state.location_id);
    }
    // formdata.append("category_id", this.state.category);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    fetch(api_url + "api/search_providers", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.data) {
          localStorage.setItem('search-provider', JSON.stringify(result.data));
        }
        else {
          localStorage.setItem('search-provider', JSON.stringify(result.Result));
        }
        this.setState({ isloading: false })
        const queryString = window.location.href; //get url
        const searchp = '/searchProvider'
        const onSearchPage = queryString.includes(searchp); // check if user is on search page
        if (onSearchPage) { // if yes
          this.setState({ redirect: null });  // no redirect 
        } else {
          this.setState({ redirect: "/searchProvider" }); //redirect
        }
      })
      .catch(error => console.log('error', error));

  } 
  handleModeCheckboxes = (event) => {
    var updatedList = [...this.state.walk_bike];
    if (event.target.checked) {
        updatedList = [...this.state.walk_bike, event.target.value];
    } else {
        updatedList.splice(this.state.walk_bike.indexOf(event.target.value), 1);
    }
    this.setState({walk_bike: updatedList})
  };
  // getCategories = async () => {
  //   let fetchCategories = await fetch(api_url + "api/categories", {
  //     method: "get",
  //     headers: {
  //       'Accept': '*/*'
  //     }
  //   });
  //   fetchCategories = await fetchCategories.json();
  //   if (fetchCategories) {
  //     this.setState({ categories: fetchCategories, isloading: false })
  //   }
  //   console.log('categories', fetchCategories);
  // }
  onSelect = (option) => {
    console.log('Your selected id ', option.value)
    console.log('You selected category ', option.label)
    this.setState({ category: option.value })
  }
  getSearch = async (e) => {
    this.setState({name:e.target.value},async()=>{
      var formdata = new FormData();
      formdata.append("search", this.state.name);
  
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
  
      fetch(api_url + "api/search/suggestion", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.setState({ searchResult: result ,showLocations:true})
        })
        .catch(error => console.log('error', error));
    })

    // const city = this.state.name;
    //   let item = {city};
    //   console.log('city', JSON.stringify(item))
    //   let fetchSearch = await fetch(api_url+"api/search/city",{
    //     method: "post",
    //     headers : {
    //       "Content-Type": "multipart/form-data;application/json",
    //          'Accept': '*/*',
    //      },
    //      body: JSON.stringify(item)
    //   });
    //   fetchSearch = await fetchSearch.json();
    //   if(fetchSearch){
    //     this.setState({searchResult: fetchSearch}, ()=>{
    //       console.log('fetched cities', this.state.searchResult);

    //     })
    //   }
  }
  showSliderValue = (e) => {
    const slider_input = document.getElementById('slider_input'),
      slider_thumb = document.getElementById('slider_thumb'),
      slider_thumb_value = document.getElementById('slider_thumb_value'),
      slider_line = document.getElementById('slider_line');
    slider_thumb_value.innerHTML = slider_input.value + '$';
    const bulletPosition = (slider_input.value / slider_input.max),
      space = slider_input.offsetWidth - slider_thumb.offsetWidth;

    slider_thumb.style.left = (bulletPosition * space) + 'px';
    slider_thumb_value.style.left = '-'+(bulletPosition * space) + 'px';
    slider_line.style.width = (slider_input.value / 10) + '%';
    this.setState({ price: e.target.value })
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const renderTooltip = (text) => (
      <Tooltip id="button-tooltip">
        {text}
      </Tooltip>
    );
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <>
        <div className='banner-img'>
          <div className='banner-content'>
            <Row>
              <Col lg={9} md={9} xs={12}>
                <h2>connecting</h2>
                <h5>the world</h5>
                <div className='search-section'>
                  <div className='d-flex position-relative'>
                    {/* <Form.Control type="text" placeholder="Where are you going ?" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/> */}
                    {/* <input list="browsers" className='form-control' autoComplete='off' value={this.state.name} placeholder="Where are you going ?" name="browser" id="browser" onChange={(e) => this.setState({name:e.target.value ,})}/> */}
                    {/* <input list="browsers" className='form-control' autoComplete='off' value={this.state.name} placeholder="Where are you going ?" name="browser" onChange={(e) => this.setState({ name: e.target.value,location_id:e.target.id }, this.getSearch)} />
                    <datalist id="browsers">
                      {this.state.searchResult.length > 0 ?
                        this.state.searchResult.map((n) => (
                          <option id={n.id} value={n.place}>{n.place}</option>
                        ))
                        :
                        ''
                      }
                    </datalist> */}
                     <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description} className="w-100">
            <div className='d-flex position-relative'>
            <input
              {...getInputProps({
                placeholder:"Where are you going ?",
                className: 'form-control location-text-field'
              })}
            />
              <MdMyLocation className='current-location-icon' title='Current Location' onClick={()=>this.setState({address:this.state.userCurrentCity+', '+this.state.userCurrentCountry})}/>
                    &nbsp;&nbsp;
                    <Button className='primary-btn' id="button-addon2" onClick={this.searchProvider} disabled={this.state.isloading ? true : false}>
                      {this.state.isloading ? <Spinner animation="border" variant="light" size="sm" /> : 'SEARCH'} 
                    </Button>
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { background:'#ced4da', padding: '10px', borderBottom:'1px solid #ced4da', cursor:'pointer', color: '#000'}
                  : { background:'#fff', padding: '10px', borderBottom:'1px solid #ced4da', cursor:'pointer', color: '#000' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                    {/* <input className="form-control location-text-field" type="text" value={this.state.name} placeholder="Where are you going ?" onChange={(e) =>this.getSearch(e)} /> */}
                    {/* <MdMyLocation className='current-location-icon' title='Current Location' onClick={()=>this.setState({name:this.state.userCurrentCity+', '+this.state.userCurrentRegion+', '+this.state.userCurrentCountry})}/>
                    &nbsp;&nbsp;
                    <Button className='primary-btn' id="button-addon2" onClick={this.searchProvider} disabled={this.state.isloading ? true : false}>
                      {this.state.isloading ? <Spinner animation="border" variant="light" size="sm" /> : 'SEARCH'} 
                    </Button> */}
                  </div>
                    {this.state.showLocations ? 
                  <ul className='search-result-list'>
                    {this.state.searchResult ? 
                    this.state.searchResult.length > 0 ? 
                    this.state.searchResult.map((n)=>(
                        <li id ={n.id} onClick={(e)=>this.setState({location_id:e.target.id, name:n.place, showLocations:false})}>{n.place}</li>
                    ))
                    :
                    this.state.filters ? '' :
                    <strong className='d-flex justify-content-end cursor-pointer position-relative zIndex-0' onClick={() => this.setState(prevState => ({ filters: !prevState.filters }))}>
                    <RiFilter2Line className='mt-1' /><span>Advanced Filters</span>
                  </strong>
                    :
                    ''
                    }
                    </ul>
                    :
                    this.state.filters ? '' :
                    <strong className='d-flex justify-content-end cursor-pointer position-relative zIndex-0' onClick={() => this.setState(prevState => ({ filters: !prevState.filters }))}>
                    <RiFilter2Line className='mt-1' /><span>Advanced Filters</span>
                  </strong>
  }
                  {/* {this.state.name ? 
              <ul className='search-dropdown' onClick={this.getClicked}>
                <li>São Paulo, State of São Paulo, Brazil</li>
                <li>São Paulo, State of São Paulo</li>
                <li>São Paulo, State of São</li>
              </ul>
            :
            ''
              } */}
                
                </div>
                {this.state.filters ?
                <Row className='advanced-filters'>
                  <strong className='d-flex justify-content-end cursor-pointer position-relative zIndex-0' onClick={() => this.setState(prevState => ({ filters: !prevState.filters }))}>
                  <RiFilter2Line className='mt-1' /><span>Advanced Filters</span>
                </strong>
                  <Col md={6} sm={12} className='mb-2'>
                    <h6>Select Price:</h6>
                    <div className='d-flex'>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="p1" name="radio-price" checked={this.state.price === 10} onChange={() => this.setState({ price: 10 })} />
                        <label for="p1">0-10</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="p2" name="radio-price" checked={this.state.price === 20} onChange={() => this.setState({ price: 20 })} />
                        <label for="p2">10-20</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="p3" name="radio-price" checked={this.state.price === 50} onChange={() => this.setState({ price: 50 })} />
                        <label for="p3">20-50</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="p4" name="radio-price" checked={this.state.price === 1000} onChange={() => this.setState({ price: 1000 })} />
                        <label for="p4">50+</label>
                      </p>
                    </div>
                  {/* <div class="range-slider">
                      <h6>Select Price: <span id="slider_thumb_value">200$</span></h6>
                      <div id="slider_thumb"  class="range-slider_thumb"></div>
                      <div class="range-slider_line">
                        <div id="slider_line" class="range-slider_line-fill"></div>
                      </div>
                      <input id="slider_input" class="range-slider_input" type="range" value={this.state.price} min="0" max="1000" onChange={this.showSliderValue} />
                    </div>  */}
                  </Col>
                  <Col md={3} sm={12} className='mb-2'>
                    <h6>Guide Type</h6>
                  <div className='d-flex'>
                      <p className='mb-0 mr-15'>
                        <input type="radio" id="test1" title='Professional' value="Pro" name="radio-group" checked={this.state.pro_local === "Pro"} onChange={(e) => this.setState({ pro_local: e.target.value })} />
                        <label for="test1">Professional</label>
                      </p>
                      <p className='mb-0'>
                        <input type="radio" id="test2" title='Local' value="Local" name="radio-group" checked={this.state.pro_local === "Local"} onChange={(e) => this.setState({ pro_local: e.target.value })} />
                        <label for="test2">Local</label>
                      </p>
                    </div>
                  </Col>
                  <Col md={3} sm={12} className='mb-2'>
                    <h6 className='mode-heading'>Preferred Mode</h6>
                    <div className='d-flex mode-radios'>
        <div className='d-flex justify-content-start align-items-center'>
        <div className='square-checkbox'>
          <Form.Check type='checkbox' name='mode' value='Walk' onChange={(e) => this.handleModeCheckboxes(e)}/>
        </div>
          <RiWalkFill title='Walk' className='p-mode-icons' />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className='d-flex justify-content-start align-items-center'>
        <div className='square-checkbox'>
          <Form.Check type='checkbox' name='mode' value='Bike' onChange={(e) => this.handleModeCheckboxes(e)}/>
        </div>
          <RiMotorbikeFill title='Bike' className='p-mode-icons' /> 
        </div>
        </div>
                  {/* <div className='d-flex mode-radios'>
                      <p className='mb-0 mr-15'>
                      <div className='square-checkbox'>
                        <input type="checkbox" id="opt1" value="Walk" onChange={(e) => this.setState({ walk_bike: e.target.value })} />
                      </div>
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Walk')}>
                          <label for="opt1"><RiWalkFill className='mode-icons' /></label>
                        </OverlayTrigger>
                      </p>
                      <p className='mb-0'>
                        <input type="checkbox" id="opt2" value="Bike" onChange={(e) => this.setState({ walk_bike: e.target.value })} />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Bike')}>
                        <label for="opt2"><RiMotorbikeFill className='mode-icons' /></label>
                        </OverlayTrigger>
                      </p>
                    </div> */}
                  </Col>
                  {this.state.address ? 
                  <>
                  <Col md={7} sm={12} className='mb-2'>
                    <h6>Select Distance:</h6>
                    <div className='d-flex'>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d1" name="radio-distance" checked={this.state.distance === 1} onChange={() => this.setState({ distance: 1, showCustomDistance:false })} />
                        <label for="d1">1 mile</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d2" name="radio-distance" checked={this.state.distance === 2} onChange={() => this.setState({ distance: 2, showCustomDistance:false })} />
                        <label for="d2">2 miles</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d3" name="radio-distance" checked={this.state.distance === 3} onChange={() => this.setState({ distance: 3, showCustomDistance:false })} />
                        <label for="d3">3 miles</label>
                      </p>
                    <p className='mb-0 mr-15'>
                        <input type="radio" id="d4" name="radio-distance" onChange={() => this.setState({ showCustomDistance:true })} />
                        <label for="d4">custom</label>
                      </p>
                    </div>
                  </Col>
                  <Col md={5} sm={12} className='mb-2'>
                  {this.state.showCustomDistance ?
                  <>
                  <h6 className='mb-0'>Enter Distance:</h6>
                    <Form.Control type="number" className='custom-distance-field' placeholder="Enter Custom Distance" onChange={(e) => this.setState({distance: e.target.value})}/>
                  </>
                    :''}
                    </Col>
                  </>
                  :''}
                </Row>
                  :
                  ''
                }
                {/* <Tabs variant='pills' defaultActiveKey="tab-1" clasName="mb-1 p-0">
                <Tab eventKey="tab-1" title="Services" className='search-tabs'>
                //  services content 
                <InputGroup className="mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Name</Form.Label>
                    <div className='d-flex'>
                    <RiBuilding2Line className='search-tabs-icons'/>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})}/>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Categories</Form.Label>
                    <div className='d-flex'>
                    <RiLayoutMasonryFill className='search-tabs-icons'/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
        />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                    <Form.Label>Rate</Form.Label>
                    <div className='d-flex'>
                    <RiRouteFill className='search-tabs-icons'/>
                    <div className='d-flex'>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                    //  <Form.Range onChange={(e) => this.setState({price: e.target.value})}/>
                    </div>
                  </div>
                </Form.Group>
                <Button variant="primary" id="button-addon2" onClick={this.search}>
                    SEARCH
                </Button>
                </InputGroup>
                //  mobile form
                <div className='mobile-form'>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})} className="mt-2"/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
          className="mt-2"
        />
                    <div className='d-flex justify-content-between mt-2'>
                      <span className='text-white'>Rate: </span>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                <Button variant="primary" id="button-addon2" onClick={this.search} className="mt-2">
                    SEARCH
                </Button>
                </div>
                  // services content end 
                </Tab>
                <Tab eventKey="tab-2" title="Providers" className='search-tabs'>
                //  providers content 
                  <InputGroup className="mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Name</Form.Label>
                    <div className='d-flex'>
                    <RiBuilding2Line className='search-tabs-icons'/>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})}/>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                  <Form.Label>Categories</Form.Label>
                    <div className='d-flex'>
                    <RiLayoutMasonryFill className='search-tabs-icons'/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
          className="mt-2"
        />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                <div className='d-flex flex-column border-right banners-controls'>
                    <Form.Label>Rate</Form.Label>
                    <div className='d-flex'>
                    <RiRouteFill className='search-tabs-icons'/>
                    <div className='d-flex'>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                    </div>
                  </div>
                </Form.Group>
                <Button variant="primary" id="button-addon2" onClick={this.searchProvider}>
                    SEARCH
                </Button>
                </InputGroup>
                //  mobile form 
                 <div className='mobile-form'>
                  <Form.Control type="text" placeholder="Enter Keyword" onChange={(e) => this.setState({name: e.target.value})} className="mt-2"/>
                    <Dropdown
          options={this.state.categories.map((n)=>(
            {value: n.id, label: n.name}
          ))}
          onChange={(o)=> this.onSelect(o)}
          value={this.state.categories[this.state.categories.length]}
          placeholder="Select Category"
          className="mt-2"
        />
                    <div className='d-flex justify-content-between mt-2'>
                      <span className='text-white'>Rate: </span>
                    <Form.Range id="range" value={this.state.range} min="100" max="6000" step="100" title={this.state.range}
                      onChange={(e) => this.setState({price: e.target.value, range: e.target.value})}/>
                    <span id="output">{this.state.range}</span>
                    </div>
                <Button variant="primary" id="button-addon2" onClick={this.searchProvider} className="mt-2">
                    SEARCH
                </Button>
                </div>
                  // providers content end
                </Tab>
            </Tabs> */}
                <div className='backdrop'>

                </div>
              </Col>
              {/* <Col lg={3} md={3} xs={12} className='how-it-works'>
                  <center><span className='how-txt'>How It Works</span></center>
                <Slider {...settings} className="banner-top-carousel">
                <div>
                    <img src={slider1} alt="carousel" className='banner-carousel-img'/>
                    <div className='img-contnt'>
                      <h6><strong>Browse Locals</strong></h6>
                    </div>
                </div>
                <div>
                    <img src={slider2} alt="carousel" className='banner-carousel-img'/>
                    <div className='img-contnt'>
                      <h6><strong>Plan & Discuss</strong></h6>
                    </div>
                </div>
                <div>
                    <img src={slider3} alt="carousel" className='banner-carousel-img'/>
                    <div className='img-contnt'>
                      <h6><strong>Experience</strong></h6>
                    </div>
                </div>
            </Slider>
                </Col> */}
            </Row>

          </div>
          <div className='banner-blur'></div>
        </div>
      </>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: (GOOGLE_API_KEY)
})(Banner);
