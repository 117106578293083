import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/Styles/style.css';
import Local from './Provider';
import Search from './Search';
import SearchProvider from './SearchProvider';
import MyTrips from './MyTrips';
import ProfileSettings from './ProfileSettings';
import About from './About';
import ViewProfile from './ViewProfile';
import Category from './Category';
import Service from './Service';
import Support from './Support';
import Careers from './Careers'
import JoinUs from './JoinUs'
import Faq from './Faq'
import Terms from './TermsConditions'
import Privacy from './Privacy'
import SiteMap from './SiteMap'
import BookingAdvice from './BookingAdvice';
import Favorites from './Favorites';
import Guide from './BecomeGuide'
export default class Index extends Component {
  render() {
    return (
        <BrowserRouter>
        <Switch>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route path="/search">
                <Search/>
            </Route>
            <Route path="/searchProvider">
                <SearchProvider/>
            </Route>
            <Route path="/provider/:id">
                <Local/>
            </Route>
            <Route path="/mytrips/:P_id">
                <MyTrips/>
            </Route>
            <Route path="/profile-settings">
                <ProfileSettings/>
            </Route>
            <Route path="/view-profile">
                <ViewProfile/>
            </Route>
            <Route path="/about">
                <About/>
            </Route>
            <Route path="/category/:id">
                <Category/>
            </Route>
            <Route path="/service/:S_id/:P_id">
                <Service/>
            </Route>
            <Route path="/support">
                <Support/>
            </Route>
            <Route path="/careers">
                <Careers/>
            </Route>
            <Route path="/joinUs">
                <JoinUs/>
            </Route>
            <Route path="/faq">
                <Faq/>
            </Route>
            <Route path="/termsConditions">
                <Terms/>
            </Route>
            <Route path="/privacy">
                <Privacy/>
            </Route>
            <Route path="/sitemap">
                <SiteMap/>
            </Route>
            <Route path="/booking_advice">
                <BookingAdvice/>
            </Route>
            <Route path="/favorites">
                <Favorites/>
            </Route>
            <Route path="/become-a-guide">
                <Guide/>
            </Route>
        </Switch>
        </BrowserRouter>
    )
  }
}
